import React from 'react';

function CardThree({name, amount, date, notCurrency, color}) {
    return (
        <div className="w-full h-28 rounded hover:bg-gray-50 border border-gray-100 shadow hover:shadow-md flex flex-col justify-center px-6">
            <h3 className={`${color ? color : 'text-black'} text-center flex justify-center text-3xl font-semibold my-1`}>{notCurrency ? '' : '₦'}{amount || amount === 0 ? amount : <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</h3>
            <p className={`${color ? color : 'text-black'} text-center font-medium text-xs`}>{name}</p>
        </div>
    )
}

export default CardThree
