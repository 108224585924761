import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import { Card, Table } from './index.js';
import {createTheme, ThemeProvider} from "@mui/material";
import Header from "./ActivityLog/Header";
import DetailsBox from "./ActivityLog/DetailsBox";
import { getActivityLog } from "../services/requests/activitylog.js";


const myTheme = createTheme({
  components: {
    //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&.Mui-selected": {
            backgroundColor: "#EEE",
            color: "#222222",
            "&:hover": {
              backgroundColor: "#EEE"
            }
          }
        }
      }
    }
  }
});

function ActivityLogInner() {
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [activityLists, setActivityLists] = useState([]);
    const [allActivityLogs, setAllActivityLogs] = useState([]);

    useEffect(()=>{
        const response = [
            // { id: 1, duration: '3 seconds ago', activity: "Richard created a new payment link - Sunday added a customer", name: "Ladejobi Blessing", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "11 Mar, 2022 - 15:30 pm"},
            // { id: 2, duration: '1 minute ago', activity: "Richard created a new payment link - Sunday added a customer", name: "Jega Money", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "12 Mar, 2022 - 15:35 pm"},
            // { id: 3, duration: '3 minutes ago', activity: "Richard created a new payment link - Sunday added a customer", name: "Blessing Ladejobi", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "12 Mar, 2022 - 15:40 pm"},
            // { id: 4, duration: 'an hour ago', activity: "Richard created a new payment link - Sunday added a customer", name: "Solomon Lange", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "12 Mar, 2022 - 15:45 pm"},
            // { id: 5, duration: '2 hours ago', activity: "Richard created a new payment link - Sunday added a customer", name: "Abdulahi Abodunrin", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "13 Mar, 2022 - 15:50 pm"},
            // { id: 6, duration: '4 hour ago', activity: "Richard created a new payment link - Sunday added a customer", name: "Oladotun Alaji-agba", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "15 Mar, 2022 - 15:55 pm"},
            // { id: 7, duration: '5 hour ago', activity: "Richard created a new payment link - Sunday added a customer", name: "James Fedrick", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "16 Mar, 2022 - 15:60 pm"},
            // { id: 8, duration: '6 hour ago', activity: "Richard created a new payment link - Sunday added a customer", name: "Samuel Mouto", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "18 Mar, 2022 - 15:65 pm"},
            // { id: 9, duration: '2 weeks ago', activity: "Richard created a new payment link - Sunday added a customer", name: "Richard Sunday", email: "ladejobiblessing@gmail.com", role: "Admin", ipAddress: "102.89.35.52", date: "20 Mar, 2022 - 15:70 pm"}
        ]
        setActivityLists(response);
        // selectRowData(1);
        fetchActivityLog();
    }, []);

    

    const fetchActivityLog = async () => {
        const userId = JSON.parse(window.sessionStorage.getItem("userData")).id;
        const response =  await getActivityLog(userId);
        // alert(JSON.stringify(response.data.notifications));
        if(response.status){
            const log = [];
            response?.data?.content?.forEach(element => {
                log.push({
                    id: element.id,
                    duration: element.createdAt,
                    activity: element.content,
                    // date: Date(element.dateSent).split("GMT")[0],
                    // status: element.read
                });
                // isMessageMovedToViewport(element.notificationId);
            });
            setAllActivityLogs(response?.data?.content)
            setActivityLists(log);
        }
    }

    const selectRowData = (id) =>{
        const matchedActivity = allActivityLogs.filter(activityList=> activityList.id === id)[0];
        setSelectedActivity(matchedActivity);
    }
    const tableColumnStructure = [
        { field: 'duration', headerName: 'Duration', width: 220 },
        { field: 'activity', headerName: 'Activity', flex: 1, minWidth: 150},
        { field: 'action', 
            headerName: 'Action', 
            width: 120, 
            minWidth: 150,
            renderCell: (param)=>{
                const onClick = (e) => {
                    // e.stopPropagation();
                    selectRowData(param.row.id);
                    // alert(param.row.id)
                }
                return(
                    <button onClick={onClick} className="text-sm flex items-center gap-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_9357_70631)">
                            <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="#F98435" fill-opacity="0.5"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_9357_70631">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        View More
                    </button>
                )
        } },
    ]
    const tableRowData = activityLists;

    return (
        <>
            <Header />
            <ThemeProvider theme={myTheme}>
                <Table tableColumnStructure={tableColumnStructure} tableRowData={tableRowData} pageName="Activity Log" checkboxSelection={false}/>
            </ThemeProvider>
            {selectedActivity && <DetailsBox selectedActivity={selectedActivity}/>}
        </>
    )
}

export default ActivityLogInner
