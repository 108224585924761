import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FilterIcon, ExportIcon, ChevronDownIcon, BackIcon, CopyIcon, UserAvatar, ForwardArrow, ReplayIcon, QuestiontagIcon, PlusIcon } from '../assets';
import { Card, Table } from './index.js';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import FetchApi from '../utils/FetchApi';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import axios from "axios";
import {getAllCustomers, getCustomerStats, getEachCustomerStats, getCustomer} from "../services/requests/customers";
import {FormatDatetime} from "../utils/FormatDatetime";
import config from '../config.js';



function CustomerInner() {
    const [customer, setCustomer] = useState(null);
    const [allCustomer, setAllCustomer] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [overflowStatus, setOverflowStatus] = useState(false);
    const [tab, setTab] = useState(1);
    const [customerStats, setCustomerStats] = useState(null);

    const [firstname, setFirstname] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);

    const [allFilteredData, setFilteredData] = useState(null);
    const [value, setValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [status, setStatus] = useState("");
    const [avoidStatus, setAvoidStatus] = useState(false);
    const [eachCustomerStats, setEachCustomerStats] = useState(null);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const customerID = searchParams.get("customerId");
    useEffect(() => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));

        if(customerID) {
            getCustomer(customerID).then(response=>{
                const customerInfo =  response?.data;
                const customerResponse = {
                    id: customerInfo.customerId,
                    customerName: `${customerInfo.firstName} ${customerInfo.lastName}`,
                    email: customerInfo.email,
                    contact: `${customerInfo.phoneNumber}`,
                    status: customerInfo.status,
                    ...customerInfo,
                }

                fetchCustomerStat(customerResponse);
                setCustomer([customerResponse]);
                setFilteredData([customerResponse]);
                setAllCustomer([customerInfo]);
            });
        }else{
            fetchAllCustomers();
        }
        
        getCustomerStats().then(response=>{
            setCustomerStats(response?.data);
        });
    }, [customerID])

    const fetchAllCustomers = () => {
        getAllCustomers().then(response=>{
            const customerResponse = response?.data?.content.reverse().map((elem, index) => (
                {
                    id: elem.customerId,
                    customerName: `${elem.firstName} ${elem.lastName}`,
                    email: elem.email,
                    contact: `${elem.phoneNumber}`,
                    status: elem.status
                }
            ));
            setCustomer(customerResponse);
            setFilteredData(customerResponse);
            setAllCustomer(response?.data?.content.reverse());
        });
    }

    const fetchCustomerStat = (data) => {
        getEachCustomerStats(data?.customerId).then(response=>{
            let totalSpend = 0;
            let totalSuccessful = 0;
            let totalTransaction = response.data.content.length;
            let latestTransaction = response.data.content[totalTransaction - 1];
            response.data.content.forEach(transaction=>{
                totalSpend = totalSpend + transaction.amount;
                const successfulCount = transaction.status === "SUCCESSFUL" ? 1 : 0;
                totalSuccessful = totalSuccessful + successfulCount;
            });
            setEachCustomerStats({
                totalSpend, totalSuccessful, totalTransaction, latestTransaction
            })
        });
        
        setShowPopup(true);
        setCustomerDetails(data);
        const currentCustomerStatus = data?.status === "ACTIVE" ? false : true;
        setAvoidStatus(()=> currentCustomerStatus);
    }

    const tableColumnStructure = [
        { field: 'id', headerName: 'Customer ID', width: 240 },
        { field: 'customerName', headerName: 'Customer Name', flex: 1, minWidth: 150 },
        { field: 'email', headerName: 'Email', flex: 1, minWidth: 150 },
        { field: 'contact', headerName: 'Contact', width: 130 },
        { field: 'status', headerName: 'Status', width: 100 },
        {
            field: 'action', headerName: 'Action', width: 130, renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const data = allCustomer.filter(elem => elem.customerId === params.row.id);
                    if (data.length === 1) {
                        // Fetch transaction data summary for this customer
                        fetchCustomerStat(data[0]);
                    }
                    // console.log(data)
                };
                return <button className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white" onClick={onClick}>View More</button>
            }
        }
    ]

    let sortedDataDESC = customer ? [...customer] : null;
    const tableRowData = sortedDataDESC?.reverse();

    const hideOverlay = () => {
        navigate('/customers')
        setShowPopup(false);
    }

    const hideOverlay2 = () => {
        setOverflowStatus(prevState => !prevState);
    }

    const showOverlay2 = () => {
        setOverflowStatus(prevState => !prevState);
    }
    const SetFirstname = (e) => {
        setFirstname(e.target.value);
    }
    const SetLastname = (e) => {
        setLastname(e.target.value);
    }
    const SetEmail = (e) => {
        setEmail(e.target.value);
    }

    function isAlpha(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123) && (code !== 32 && code !== 45)) { // lower alpha (a-z)
            return false;
            }
        }
        return true;
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    
    const AddUser = (e) => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const payload = {
            email: email,
            firstName: firstname,
            lastName: lastname,
            phoneNumber: phone
        }
        if(isAlpha(firstname)){
            if(isAlpha(lastname)){
                if(validateEmail(email)){
                    if(email){
                        if(firstname){
                            if(lastname){
                                if(phone){
                                    const response = FetchApi({ endpoint: `/identity-manager-service/api/v1/webpos/customer`, payload: payload, authorization: authorization, method: "POST" });
                                    response.then(async resp => {
                                        if (resp.customerId) {
                                            await fetchAllCustomers();
                                            toast.success("User Successfully Added");
                                            hideOverlay2()
                                        }
                                    }).catch(err => {
                                        setTab(3);
                                    });
                                }else{
                                    toast.error("Phone number is required");
                                }
                            }else{
                                toast.error("Lastname is required!")
                            }
                        }else{
                            toast.error("Firstname is required!");
                        }
                    }else{
                        toast.error("Email is required!");
                    }
                }else{
                    toast.error("Email address is invalid");
                }
            }else{
                toast.error("Lastname can only be Alphabet");
            }
        }else{
            toast.error("Firstname can only be Alphabet");
        }
    }

    const FilterDataById = (id) => {
        // console.log(id, tableRowData)
        if(status){
            const data = tableRowData;
            const outcome = data.filter(element=> element.status == status.toUpperCase());
            setFilteredData(outcome);
        }else{
            setValue(id);
            if(id){
                const data = tableRowData;
                // const outcome = data.filter(element=> element.id === id);
                const outcome = data.filter(element=> element.id.toLowerCase().indexOf(id.toLowerCase()) > -1);
                setFilteredData(outcome);
            }else{
                setFilteredData(tableRowData);
            }
        }
        hideFilterDropdown();
    }

    const ClearFilter = () => {
        setValue(()=> '');
        setStatus(()=> '');
        setFilteredData(tableRowData);
        hideFilterDropdown();
    }

    const showFilterDropdown = () => setFilterDropdown(true);
    const hideFilterDropdown = () => setFilterDropdown(false);

    const ToggleCustomerAvoidStatus = () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const payload = {
            
        }
        const header = {
            headers: {
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        if(customerDetails?.customerId){
            const customerId = customerDetails?.customerId;
            const endpoint = `${config.BASE_URL_AUTH}/identity-manager-service/api/v1/webpos/customer/toggle-avoid/${customerId}?avoid=${!avoidStatus}`;

            axios.patch(endpoint, payload, header).then(response => {
                if(avoidStatus){
                    toast("Customer Allowed");
                    setAvoidStatus(!avoidStatus);
                }else{
                    toast("Customer Avoided");
                    setAvoidStatus(!avoidStatus);
                }
            }).catch(err => {
                toast("Error Occured: ");
            });
        }
    }

    return (
        <>
        <ToastContainer />
            {
                overflowStatus && (
                    <div onClick={(e) => e.target.classList.contains("overlay") ? hideOverlay2() : null} className="overlay w-full h-screen fixed px-5 md:px-0 flex items-start justify-center pt-28 top-0 left-0 z-30">
                        {
                            tab === 1 && (
                                <div className="w-full md:w-1/2 h-auto rounded bg-white pb-4 ...">
                                    <div className="popup-header flex items-center px-4 h-14 shadow">
                                        Create a new customer
                            </div>
                                    <div className="px-4">
                                        <div className="w-full pt-6">
                                            <label className="text-sm font-medium">First Name</label>
                                            <input onChange={SetFirstname} type="text" placeholder="Richard" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>
                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Last Name</label>
                                            <input onChange={SetLastname} type="text" placeholder="Daniel" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>
                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Email Address</label>
                                            <input onChange={SetEmail} type="text" placeholder="blessing@example.com" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>
                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Phone Number</label>
                                            <PhoneInput
                                                className="w-full px-3 text-sm h-12 border border-gray-300 rounded"
                                                country={'ng'}
                                                // value={this.state.phone}
                                                onChange={phone => setPhone(phone)}
                                            />
                                        </div>
                                    </div>
                                    <div className="popup-header flex justify-between items-center px-4 h-14 pt-5">
                                        <button className="bg-gray-200 border border-gray-300 text-gray-900 py-3 rounded text-sm px-5" onClick={hideOverlay2}>Cancel</button>
                                        <button onClick={AddUser} className="bg-primary-theme text-white py-3 rounded text-sm px-5">Create</button>
                                    </div>
                                </div>
                            )
                        }
                        {
                            tab === 2 && (
                                <div className="w-full md:w-1/2 h-auto flex py-5 flex-col items-center rounded bg-white pb-4 ...">
                                    <svg width="132" height="132" viewBox="0 0 173 173" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="86.5987" cy="86.7262" r="86.0406" fill="#F1F7FA" />
                                        <path d="M56.8117 84.0237L79.286 109.213L119.182 68.6789" stroke="#27AE60" stroke-width="6" />
                                    </svg>

                                    <h5 className="text-xl mt-3 font-semibold text-center">User Successfully Added</h5>
                                    <button className="bg-gray-200 mt-4 border border-gray-300 text-gray-900 py-3 rounded text-sm px-5" onClick={hideOverlay2}>Cancel</button>
                                </div>
                            )
                        }{
                            tab === 3 && (
                                <div className="w-full md:w-1/2 h-auto flex py-5 flex-col items-center rounded bg-white pb-4 ...">
                                    <svg width="90" height="72" viewBox="0 0 90 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M88.3544 59.6455L52.6167 4.82016C51.0373 2.39715 48.2084 0.950195 45.0492 0.950195C41.8901 0.950195 39.0611 2.39683 37.4817 4.82016L1.74439 59.6455C0.164631 62.0685 0.164631 64.9617 1.74439 67.3851C3.32415 69.8084 6.15274 71.255 9.31189 71.255H80.7876C83.9464 71.255 86.7754 69.8084 88.3548 67.3854C89.9342 64.9621 89.9338 62.0688 88.3544 59.6455ZM45.0496 62.3356C41.9132 62.3356 39.3616 60.0757 39.3616 57.2977C39.3616 54.5201 41.9132 52.2601 45.0496 52.2601C48.1859 52.2601 50.7375 54.5201 50.7375 57.2977C50.7371 60.076 48.1856 62.3356 45.0496 62.3356ZM50.7758 40.8008C50.7758 43.5973 48.2069 45.8727 45.0496 45.8727C41.8922 45.8727 39.3233 43.5973 39.3233 40.8008V17.523C39.3233 16.1962 40.5378 15.1205 42.0358 15.1205H48.0637C49.5621 15.1205 50.7762 16.1962 50.7762 17.523V40.8008H50.7758Z" fill="#FF6700"></path>
                                    </svg>

                                    <h5 className="text-xl mt-3 font-semibold text-center">User Failed to be Added</h5>
                                    <button className="bg-gray-200 mt-4 border border-gray-300 text-gray-900 py-3 rounded text-sm px-5" onClick={hideOverlay2}>Cancel</button>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                showPopup && (
                    <div className="w-full">
                        <button className="mx-8 my-4 flex items-center text-sm gap-2" onClick={hideOverlay}>
                            <BackIcon /> Back
                        </button>
                        <div className="grid grid-cols-1 md:grid-cols-2 h-auto px-8">
                            <div className="bg-white w-full h-auto border px-6 border-gray-200 rounded-xl py-5">
                                <div className="flex justify-between items-start px-8 py-5">
                                    <span className="text-xs text-gray-500">Full name</span>
                                    <span className="text-xs font-semibold flex items-center gap-2">{(customerDetails?.firstName)} {(customerDetails?.lastName)} <CopyIcon /></span>
                                </div>
                                <hr />
                                <div className="flex justify-between items-start px-8 py-5">
                                    <span className="text-xs text-gray-500">Added Date</span>
                                    <span className="text-xs font-semibold flex items-center gap-2">{FormatDatetime(customerDetails?.createdAt)} </span>
                                </div>
                                <hr />
                                <div className="flex justify-between items-start px-8 py-5">
                                    <span className="text-xs text-gray-500">Customer Id</span>
                                    <span className="text-xs font-semibold flex items-center gap-2">{customerDetails?.customerId}</span>
                                </div>
                                <hr />
                                <div className="flex justify-between items-start px-8 py-5">
                                    <span className="text-xs text-gray-500">Email</span>
                                    <span className="text-xs font-semibold flex items-center gap-2">{customerDetails?.email} </span>
                                </div>
                                <hr />
                                <div className="flex justify-between items-start px-8 py-5">
                                    <span className="text-xs text-gray-500">Phone number</span>
                                    <span className="text-xs font-semibold flex items-center gap-2">{customerDetails?.phoneNumber}</span>
                                </div>
                                <hr />
                                <div className="flex justify-between items-center h-20 px-5 mt-5 border border-gray-200 rounded-xl">
                                    <div className="text-xs text-gray-500 flex items-center gap-2">
                                        <div>
                                            <span className="text-xs flex items-center gap-2 font-medium text-black">Avoid this customer <QuestiontagIcon /> </span>
                                        </div>
                                    </div>
                                    <label className={`custom_switch relative w-12 h-6 rounded-full bg-gray-400`}>
                                        <input type="checkbox" onClick={ToggleCustomerAvoidStatus} defaultChecked={avoidStatus} className="hidden" />
                                        <span className={`circle w-4 h-4 left-1 bg-black rounded-lg block absolute`}></span>
                                    </label>
                                </div>
                            </div>
                            <div className="bg-white w-full h-auto border border-gray-200 rounded-xl p-1">
                                <div className="h-12 rounded bg-red-100 w-full flex items-center px-4 gap-5">
                                    <button className="text-primary-theme text-sm">Transactions</button>
                                    {/* <button className=" text-sm">Subcriptions</button> */}
                                </div>
                                <div className="h-20 shadow rounded w-full flex items-center gap-8 px-5">
                                    <div className="">
                                        <span className="text-sm">Successful</span>
                                        <p className="text-sm">{eachCustomerStats?.totalSuccessful}/<span className="text-gray-400">{eachCustomerStats?.totalTransaction}</span></p>
                                    </div>
                                    <div className="">
                                        <span className="text-sm text-gray-400">Total spend</span>
                                        <p className="text-sm">NGN {eachCustomerStats?.totalSpend}</p>
                                    </div>
                                </div>
                                <div className="h-24 shadow rounded w-full flex items-center justify-between gap-8 px-5">
                                    <div className="">
                                        <span className="text-sm text-gray-400">Recent Transactions</span>
                                        <p className="text-sm flex mt-3 items-center gap-2"><span className="h-3 w-3 bg-green-600 rounded-full flex"></span> NGN {eachCustomerStats?.latestTransaction?.amount} ({eachCustomerStats?.latestTransaction?.channel})</p>
                                    </div>
                                    <div className="flex flex-col items-end">
                                        <Link to={`/transactions?cid=${customerDetails?.customerId}`} className="text-sm text-primary-theme">View more</Link>
                                        <p className="text-sm mt-3">{eachCustomerStats?.latestTransaction?.rcre_time}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !showPopup && (
                    <>
                        <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
                            <div className="flex items-center gap-6">
                                <div className="flex items-center relative">
                                    <button className="flex items-center" onClick={showFilterDropdown}>
                                    <FilterIcon />
                                    <span className="text-sm">Filter</span>
                                    </button>
                                    {
                                        filterDropdown && (
                                        <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                                            <span onClick={hideFilterDropdown} className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm">close</span>
                                            <h5 className="font-semibold">Status</h5>
                                            <input value={value} placeholder="Search by reference ID" onChange={(e)=>setValue(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" />

                                            <h5 className="font-semibold mt-2">Customer Status</h5>
                                            <div className="flex items-center gap-4">
                                                <select type="" onChange={(e)=>setStatus(e.target.value)} className="w-full h-10 rounded border border-gray-200">
                                                <option value="">Choose status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>

                                            <div className="flex items-center gap-4 mt-5">
                                                <button onClick={ClearFilter} className="py-3 w-full text-xs rounded shadow">Clear filter</button>
                                                <button onClick={()=>FilterDataById(value)}  className="py-3 w-full rounded bg-primary-theme text-white text-xs">Apply filter</button>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                                <div className="1/5 flex gap-1 ...">
                                    <input placeholder="Search by reference ID" onChange={(e)=>FilterDataById(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" />
                                    <button onClick={()=>FilterDataById(value)} className="bg-primary-theme py-2 px-5 text-white text-sm">Search</button>
                                </div>
                            </div>
                            <div className="flex items-center gap-6">
                                <button onClick={showOverlay2} className="flex bg-primary-theme text-white shadow gap-1 h-10 items-center text-sm px-3">
                                    <PlusIcon />
                                    Add Customer
                                </button>
                                {
                            allFilteredData?.length > 0
                                ?
                                <CSVLink data={allFilteredData}>
                                    <button className="flex shadow gap-2 h-10 items-center text-sm px-5">
                                        <ExportIcon />
                                        Export
                                        <ChevronDownIcon />
                                    </button>
                                </CSVLink>
                                :
                                <button onClick={()=> toast("You can't export empty data.")} className="flex shadow gap-2 h-10 items-center text-sm px-5">
                                    <ExportIcon />
                                    Export
                                    <ChevronDownIcon />
                                </button>
                            }
                            </div>
                        </div>
                        <Grid spacing={2} container className="px-6 pt-5">
                            <Grid item xs={12} sm={12} md={4}>
                                <Card name="Total Customers" amount={customerStats && customerStats?.totalCustomers} date="2nd of Jan, 2022" notCurrency="true" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card name="Active Customers" amount={customerStats && customerStats?.statusStats[0]?.count} date="2nd of Jan, 2022" notCurrency="true" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card name="Inactive Customers" amount={customerStats && customerStats?.avoidedCustomers} date="2nd of Jan, 2022" notCurrency="true" />
                            </Grid>
                        </Grid>
                        <Table tableColumnStructure={tableColumnStructure} tableRowData={allFilteredData && allFilteredData.length > 0 ? allFilteredData : null} pageName="Customer" />
                    </>
                )
            }
        </>
    )
}

export default CustomerInner
