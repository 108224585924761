import React, { useRef } from 'react'
import NotificationTab from './NotificationTab';
import ProfileTab from './ProfileTab';
import ChangePassword from './ChangePassword';
import ChangePin from './ChangePin';
import LoginTab from './LoginTab';
import TransparencyTab from './TransparencyTab';
import ReferalTab from './ReferalTab';
import { Routes, Route, Navigate as Redirect } from 'react-router-dom';
import EditProfile from './EditProfile';
// import KYCTab from './Kyc';

function RightOptions() {
    const profileTab = useRef();
    // const kycTab = useRef();
    const notificationTab = useRef();
    const loginTab = useRef();
    const transparencyTab = useRef();
    const referalTab = useRef();
    // Sub routes
    const editProfile = useRef();

    return (
        <div className="w-2/3 bg-gray-50 px-4 ...">
            <Routes>
                {/* Sub Routes for Profile */}
                <Route path="profile/edit" element={
                    <div className="w-full" ref={editProfile}>
                        <EditProfile />
                    </div>
                } />

                <Route path="profile/account-statement" element={
                    <div className="w-full" ref={profileTab}>
                        <ProfileTab />
                    </div>
                } />

                <Route path="profile/change-pin" element={
                    <div className="w-full" ref={profileTab}>
                        <ChangePin />
                    </div>
                } />

                <Route path="profile/change-password" element={
                    <div className="w-full" ref={profileTab}>
                        <ChangePassword />
                    </div>
                } />

                {/* Sub Routes for Setting */}
                <Route path="/" element={
                    <div className="w-full" ref={profileTab}>
                        <ProfileTab />
                    </div>
                } />

                {/* <Route path="kyc" element={<Redirect to="/submit-kyc" />} /> */}

                
                {/* <div className="w-full">
                    <KYCTab />
                </div>
             */}

                <Route path="profile" element={
                    <div className="w-full" ref={profileTab}>
                        <ProfileTab />
                    </div>
                } />

                <Route path="notification" element={
                    <div className="w-full" ref={notificationTab}>
                        <NotificationTab />
                    </div>
                } />

                <Route path="refferal" element={
                    <div className="w-full" ref={referalTab}>
                        <ReferalTab />
                    </div>
                } />

                <Route path="transparency" element={
                    <div className="w-full" ref={transparencyTab}>
                        <TransparencyTab />
                    </div>
                } />

                <Route path="login" element={
                    <div className="w-full" ref={loginTab}>
                        <LoginTab />
                    </div>
                } />

            </Routes>
        </div>
    )
}

export default RightOptions
