import React from 'react'

function BigCard({ title, value }) {
    return (
        <div className="flex justify-center hover:shadow-md transition duration-500 transition-shadow items-start pl-5 flex-col w-full h-36 md:h-32 border border-gray-200 rounded-lg">
            <h5 className="text-sm font-semibold text-primary-theme text-left">{title} </h5>
            <h3 className="font-semibold text-2xl mt-1 flex items-center">NGN {value ? value : <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</h3>
        </div>
    )
}

export default BigCard
