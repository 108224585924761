

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FilterIcon, ExportIcon, ChevronDownIcon, BackIcon, CopyIcon, UserAvatar, ForwardArrow, ReplayIcon } from '../assets';
import { Card, Table } from './index.js';
import CardTwo from './CardTwo';
import FetchApi from '../utils/FetchApi';
import { Link, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import { getAllTransactions, getRevenueStats, getRevenueSummary, getCustomerTransactions } from "../services/requests/transactions";
import { FormatDatetime } from "../utils/FormatDatetime";
import { getAllReferredUsers} from '../services/requests/referral';

function ReferralInner() {
    const [allFilteredData, setAllFilteredData] = useState([]);
    const[refferral, setRefferral] = useState([]);

    const tableColumnStructure = [
        { field: 'id', headerName: 'Reference ID', width: 120 },
        { field: 'fullName', headerName: 'Full name', width: 200 },
        { field: 'dateJoined', headerName: 'Signup Date', width: 200 },
        { field: 'email', headerName: 'Email addres', width: 230 },
        { field: 'numberOfTransactions', headerName: 'Transaction Performed', width: 200 },
        // {
        //     field: 'action', headerName: 'Action', width: 130, renderCell: (params) => {
        //         const onClick = (e) => {
        //             e.stopPropagation(); // don't select this row after clicking

        //             // const data = allTrxn.filter(elem => elem.refNo === params.row.id);
        //             // if (data.length === 1) {
        //             //     setShowPopup(true);
        //             //     setTransactionDetails(data[0]);
        //             //     console.log(data);
        //             // }
        //         };
        //         return <button className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white" onClick={onClick}>View More</button>
        //     }
        // }
    ];


    useEffect(() => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
        let referenceCode;
        if(JSON.parse(sessionStorage.getItem("otherInfo"))){
            // referenceCode = JSON.parse(sessionStorage.getItem("otherInfo")).referral;
            referenceCode = JSON.parse(sessionStorage.getItem("otherInfo")).referenceCode;
        }

        getAllReferredUsers(referenceCode).then(response => {
            const refferralResponse = response?.data?.referredUsers?.map((elem, index) => (
                {
                    id: index+1,
                    fullName: elem.fullName,
                    email: elem.email,
                    numberOfTransactions: elem.numberOfTransactions,
                    dateJoined: FormatDatetime(elem.dateJoined)
                }
            ));
            setRefferral(refferralResponse);
            setAllFilteredData(response.data.referredUsers);
        }).catch(error => {
            console.log("Error occured")
        });

    }, []);

    let sortedDataDESC = refferral ? [...refferral] : null;
    const tableRowData = sortedDataDESC;

    return (
        <>
            <ToastContainer />
            {
                (
                    <>
                        <div className="px-8 pt-6 pb-2">
                            <Link to="/setting/refferal" className="flex items-center gap-2">
                                <BackIcon />
                                Back
                            </Link>
                        </div>
                        
                        <Table tableColumnStructure={tableColumnStructure} tableRowData={tableRowData && tableRowData.length > 0 ? tableRowData : null} pageName="Referral" />
                    </>
                )
            }
        </>
    )
}

export default ReferralInner