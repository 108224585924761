import React from 'react';
import { ExportIcon, ChevronDownIcon } from '../../assets';

function Header() {
  return (
    <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
        <div className="flex items-center gap-6">
            <div className="flex items-center">
                <span className="text-sm">Duration</span>
            </div>
            <div className="1/5 flex gap-1 ...">
                <div className="rounded flex items-center pl-2 border border-gray-200 relative">
                    <span className="text-sm">From</span>
                    <input type="date" className="from w-full h-10 text-sm px-2" />
                    <span className="text-sm">To</span>
                    <input type="date" className="to w-full h-10 text-sm px-2" />
                </div>
            </div>
        </div>
        <button className="flex shadow gap-2 h-10 items-center text-sm px-5">
            <ExportIcon />
            Export
        <ChevronDownIcon />
        </button>
    </div>
  )
}

export default Header
