import React from 'react'
import Option from './Option';

function LeftOptions() {
    return (
        <div className="w-1/3 shadow px-3 py-4 ...">
            <Option title="Update profile" subtitle="Username, name and password" link="/setting/profile"/>
            <Option title="KYC Verification" subtitle="Uploading documents, fill forms for verification." link="/submit-kyc"/>
            <Option title="Notification settings" subtitle="Email, SMS, Push notification" link="/setting/notification"/>
            <Option title="WayaQuick Settings" subtitle="KYC, Settlement Account, Preferences, etc" link="/setting/wayapay" />
            <Option title="Team Members" subtitle="Roles & Access Management" link="/setting/role-access"/>
            {/* <Option title="Share Feedback" subtitle="Write to us on how we can serve you better." link="/setting/feedback"/> */}
            <Option title="Invite a friend" subtitle="Share WayaQuick to your friends & family" link="/setting/refferal"/>
            <Option title="Transparency" subtitle="Terms & Condition, privacy policy, about us!" link="/setting/transparency"/>
            {/* <Option title="Login Information" subtitle="Location, device, time of login" link="/setting/login"/> */}
        </div>
    )
}

export default LeftOptions
