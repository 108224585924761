import React from 'react';
import styles from './styles.module.css';

function Option2({title, status}) {
    return (
        <div className="w-full mb-3 shadow h-14 flex justify-between items-center px-3 rounded">
            <span className="text-sm font-medium">{title}</span>
            <label className={`${styles.custom_switch} relative w-12 h-6 rounded-full bg-gray-400`}>
                <input type="checkbox" defaultChecked={status} className="hidden"/>
                <span className={`${styles.circle} w-4 h-4 left-1 bg-black rounded-lg block absolute`}></span>
            </label>
        </div>
    )
}

export default Option2
