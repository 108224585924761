import React from 'react';
import Option2 from './Option2';

function NotificationTab() {
    return (
        <>
            <h4 className="mt-5 mb-3">Notifications</h4>
            <Option2 title="Push notifications" status={true} />
            <Option2 title="SMS notifications" status={false} />
            <Option2 title="Email notifications" status={true} />
            <Option2 title="News about new products and updates" status={true} />
        </>
    )
}

export default NotificationTab
