import React, {useEffect, useState, useRef} from 'react';
import Grid from '@mui/material/Grid';
import { FilterIcon, ExportIcon, ChevronDownIcon, BackIcon } from '../assets';
import { Table } from './index.js';
import { Button } from '@mui/material';
import CardThree from './CardThree';
import axios from "axios";
import {getAllDispute, getDisputeStats, acceptDispute, rejectDispute} from "../services/requests/disputes";
import {FormatDatetime} from "../utils/FormatDatetime";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";


const Loader = () => {
    return (
        <div className="h-6 w-6 rounded-full animate-spin" style={{border: "3px solid #ffffff",borderRight: "3px solid transparent"}}></div>
    )
}

function DisputeInner() {
    const [disputes, setDisputes] = useState([]);
    const [disputesStats, setDisputesStats] = useState([]);
    const [allDispute , setAllDispute] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [disputeDetails, setDisputeDetails] = useState(null);
    const [status, setStatus] = useState("");
    const [type, setType] = useState("");
    const [allFilteredData, setFilteredData] = useState(null);
    const [value, setValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [approveTab, setapproveTab] = useState(true);
    const [disputeStats, setDisputeStats] = useState([]);
    const [loading, setLoading] = useState(false);

    const [proofType, setProofType] = useState(null);
    const [proofNote, setProofNote] = useState(null);
    const [proofDocs, setProofDocs] = useState(null);

    const [refundedAmount,setRefundedAmount] = useState("");
    const [comment, setComment] = useState("");
    const documentField = useRef();
    
    useEffect(()=>{
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        getAllDispute(merchantId).then(response=>{
            const tableRowData = response?.data?.content?.map(dispute=> ({
                id: dispute.merchantCustomerDisputeId, 
                status: dispute.disputeResolutionStatus.split("_").join(" "),
                type: dispute.disputeType, 
                transaction: `NGN${dispute.transactionAmount} on ${dispute.transactionDate}`, 
                customer: dispute.merchantEmail, 
                date: dispute.disputeInitiationDate, 
                dueDate: dispute.merchantResponseDueDate 
            }));
            setDisputes(response?.data?.content);
            setAllDispute(tableRowData);
            setFilteredData(tableRowData)
        });
        getDisputeStats(merchantId).then(response=>{
            let b = {};
            response?.data?.disputeResolutionStatus.forEach(element => {
                b[Object.values(element)[0]] = Object.values(element)[1];
            });
            setDisputeStats(b);
        });
    }, []);

    const tableColumnStructure = [
        {field: 'id', headerName: "Dispute ID" ,width: 260},
        { field: 'status', headerName: 'Dispute Status', width: 160 },
        { field: 'type', headerName: 'Dispute Type', width: 130 },
        { field: 'transaction', headerName: 'Transaction', width: 280 },
        { field: 'customer', headerName: 'Customer', width: 200 },
        { field: 'date', headerName: 'Date Created', width: 200 },
        { field: 'dueDate', headerName: 'Due When', width: 200 },
         {
            field: 'action', headerName: 'Action', width: 130, renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    const data = disputes?.filter(elem => elem.merchantCustomerDisputeId === params.row.id);
                    if (data.length === 1) {
                        setShowPopup(true);
                        setapproveTab(true);
                        setDisputeDetails(data[0]);
                    }
                };
                return <button className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white" onClick={onClick}>View More</button>
            }
        }
    ]

    // const tableRowData = [
    //     { 
    //         id: 1, 
    //         status: 'Settled',
    //          type: 'Chargeback', 
    //         transaction: "NGN100 on Jul. 12, 2021", 
    //         customer: 'email@example.com', 
    //         date: 'August 12, 2021', 
    //         dueDate: 'In a day' 
    //     },
       
    // ]


    const FilterDataById = (id) => {
        setValue(id);
        if(startDate){
            const data = allDispute;
            const outcome = data.filter(element=> `${element.date.split("T")[0].split("-")[2]}-${element.date.split("T")[0].split("-")[0]}-${element.date.split("T")[0].split("-")[1]}` == startDate);
            setFilteredData(outcome);
            hideFilterDropdown();
        }else{
            if(id){
                const data = allDispute;
                // const outcome = data.filter(element=> element.id === id);
                const outcome = data.filter(element=> element.id.toLowerCase().indexOf(id.toLowerCase()) > -1);
                setFilteredData(outcome);
                hideFilterDropdown();
            }else if(status){
                const data = allDispute;
                const outcome = data.filter(element=> element.status === status.toUpperCase());
                setFilteredData(outcome);
                hideFilterDropdown();
            }else if(type){
                const data = allDispute;
                const outcome = data.filter(element=> element.type === type.toUpperCase());
                // alert(JSON.stringify(data));
                // alert(type)
                setFilteredData(outcome);
                hideFilterDropdown();
            }else{
                setFilteredData(allDispute);
                hideFilterDropdown();
            }
        }
    }

    const ClearFilter = () => {
        setValue(()=> '');
        setStartDate(()=> '');
        setStatus(()=> '');
        setType(()=> '');
        setFilteredData(allDispute);
        hideFilterDropdown();
    }

    const showFilterDropdown = () => setFilterDropdown(true);
    const hideFilterDropdown = () => setFilterDropdown(false);

    const hideOverlay = () => {
        setShowPopup(false);
    }

    const activateTab = (e) => {
        const accept = document.querySelector("#accept");
        const reject = document.querySelector("#reject");
        if(e.target.id == "accept"){
            accept.classList.add("text-primary-theme", "border-primary-theme");
            accept.classList.remove("text-gray-500");
            reject.classList.remove("text-primary-theme", "border-primary-theme");
            reject.classList.add("text-gray-500");
            setapproveTab(true);
        }else{
            accept.classList.remove("text-primary-theme", "border-primary-theme");
            accept.classList.add("text-gray-500");
            reject.classList.add("text-primary-theme", "border-primary-theme");
            reject.classList.remove("text-gray-500");
            setapproveTab(false);
        }
    }

    const acceptDisputeHandler = (id) => {
        setLoading(true);
        const payload={
            merchantAcceptanceComment: comment,
            newRefundAmount: refundedAmount ? refundedAmount : disputeDetails?.transactionAmount
        }
        acceptDispute(id, payload).then(response=>{
            if(!response.status){
                toast(response.message.data[0]);
            }else{
                toast("Dispute accepted.");
            }
            setLoading(false);
        }).catch(error=>{
            toast(error.response);
            setLoading(false);
        })
    }

    const uploadProofDocument = () => {
        const proofFiles = documentField.current;
        setProofDocs(proofFiles.files[0]);
    }

    const rejectDisputeHandler = (id) => {
        var formDataPayload = new FormData();
        if(formDataPayload){
            setLoading(true);
            formDataPayload.append("merchantRejectionDocumentType", proofType);
            formDataPayload.append("disputeRejectReason", proofNote);
            formDataPayload.append("files", proofDocs);

            rejectDispute(id, formDataPayload).then(response=>{
                if(!response.status){
                    toast(response.message);
                }else{
                    toast("Dispute rejected.");
                }
                setLoading(false);
            }).catch(error=>{
                toast(error)
            });
        }else{
            toast("Upload document as proof.")
        }
    
    }

    return (
        <>
        <ToastContainer />
        {
                showPopup && (
                    <div className="w-full">
                        <button className="mx-8 my-4 flex items-center text-sm gap-2" onClick={hideOverlay}>
                            <BackIcon /> Back
                        </button>
                        <div className="grid grid-cols-3 border border-gray-200 h-auto px-8">
                            <div className="col-span-1 h-auto bg-red-50">
                                <h4 className="relative text-lg py-3 px-4">
                                    Details reason for date 
                                    <span className="w-24 h-1 bg-primary-theme absolute left-4 bottom-3"></span>
                                </h4>
                                <p className="px-4 mt-6 capitalize">{disputeDetails?.reasonForDisputeInDetails}</p>
                            </div>
                            <div className="col-span-2 h-auto">
                                <div className="w-full h-12 shadow flex items-center justify-center">
                                    <h5>Resolve transaction dispute</h5>
                                </div>
                                <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                                    <h5>Customer Name</h5>
                                    <h5 className="font-medium text-sm">{disputeDetails?.customerFirstName} {disputeDetails?.customerLastName}</h5>
                                </div>
                                <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                                    <h5>Customer Email</h5>
                                    <h5 className="font-medium text-sm">{disputeDetails?.customerEmail}</h5>
                                </div>
                                <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                                    <h5>Created At</h5>
                                    <h5 className="font-medium text-sm">{disputeDetails?.dateCreated}</h5>
                                </div>
                                <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                                    <h5>Dispute Type</h5>
                                    <h5 className="font-medium text-sm">{disputeDetails?.disputeType}</h5>
                                </div>
                                <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                                    <h5>Dispute ID</h5>
                                    <h5 className="font-medium text-sm">{disputeDetails?.merchantCustomerDisputeId}</h5>
                                </div>
                                {
                                    disputeDetails?.accepted && (
                                        <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                                            <h5>Dispute Status</h5>
                                            <h5 className="font-medium text-sm capitalize">{disputeDetails?.accepted ? <span className="py-2 px-4 rounded-lg bg-green-500 text-white text-sm">Accepted</span> : <span className="py-2 px-4 rounded-lg bg-primary-theme text-white text-sm">Rejected</span>}</h5>
                                        </div>
                                    ) 
                                }{
                                    !disputeDetails?.accepted && (
                                        <>
                                            <div className="mx-4 h-16 flex items-center justify-between px-4">
                                                <button onClick={activateTab} id="accept" className="text-primary-theme h-10 px-4 border-b border-primary-theme">Accept Chargeback</button>
                                                <button onClick={activateTab} id="reject" className="text-gray-500 h-10 px-4 border-b">Reject Chargeback</button>
                                            </div>
                                            {
                                                approveTab && (
                                                    <>
                                                        <div className="mx-4 h-auto pb-2 flex items-center justify-between px-4">
                                                            <h5>By accepting this chargeback, you're instructing us to process a refund that will be deducted from your next settlement.</h5>
                                                        </div>
                                                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                                                            <h5 className="font-semibold text-sm my-2">How much should we refund?</h5>
                                                            <input type="number" onChange={(e)=> setRefundedAmount(e.target.value)} defaultValue={disputeDetails?.transactionAmount} className="w-full h-10 rounded border border-gray-300" />
                                                        </div>
                                                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                                                            <textarea onChange={(e)=> setComment(e.target.value)} className="text-sm border-b border-gray-300 font-thin w-full h-14 mt-4 focus:outline-none" placeholder="Add a comment [Optional]"></textarea>
                                                        </div>
                                                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                                                            <button onClick={()=>acceptDisputeHandler(disputeDetails?.merchantCustomerDisputeId)} className="bg-primary-theme flex items-center gap-2 text-white text-sm rounded py-2 px-4">Accept Chargeback  {
                                                                loading ? <Loader /> : ""
                                                            }</button>    
                                                        </div>
                                                    </>
                                                )
                                            }{
                                                !approveTab && (
                                                    <>
                                                        <div className="mx-4 h-auto pb-2 flex flex-col items-between justify-center px-4">
                                                            <h5 className="font-medium text-lg">Upload document<span className="text-red-500">*</span></h5>
                                                            <p className="text-sm text-gray-500 itlaic">You now need to upload further evidence. This could be a proof of delivery, an invoice or some user correspondencies.</p>
                                                        </div>
                                                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                                                            <h5 className="font-semibold text-sm my-2">Type of proof</h5>
                                                            <select onChange={(e)=>setProofType(e.target.value)} className="w-full h-10 rounded border border-gray-300 text-sm px-3">
                                                                <option value="Signed Invoices (electronic or paper)">Signed Invoices (electronic or paper)</option>
                                                                <option value="Sales receipts (electronic or paper)">Sales receipts (electronic or paper)</option>
                                                                <option value="Delivery confirmation documents">Delivery confirmation documents</option>
                                                                <option value="Service usage times, dates, etc">Service usage times, dates, etc</option>
                                                                <option value="Proof that a digital product was downloaded by the customer">Proof that a digital product was downloaded by the customer</option>
                                                            </select>
                                                        </div>
                                                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                                                            <input type="file" className="" onChange={uploadProofDocument} ref={documentField}/>
                                                        </div>
                                                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                                                            <button onClick={()=>rejectDisputeHandler(disputeDetails?.merchantCustomerDisputeId)} className="bg-primary-theme text-white text-sm rounded py-2 px-4">Reject Chargeback</button>    
                                                        </div>
                                                    </>
                                                )
                                            }
                                            </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        {
            !showPopup && (
                <>
                    {/* <div className="my-4 mx-6 bg-gray-100 flex gap-2">
                        <button className="bg-primary-theme text-white px-3 py-2 text-sm rounded-full">Wayapay Dispute</button>
                        <button className="text-gray-700 px-3 py-2 text-sm rounded-full">Auth & Notification Disputes</button>
                        <button className="text-gray-700 px-3 py-2 text-sm rounded-full">Other Disputes</button>
                    </div> */}
                    <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
                        <div className="flex items-center gap-6">
                            <div className="flex items-center relative">
                                <button className="flex items-center" onClick={showFilterDropdown}>
                                <FilterIcon />
                                <span className="text-sm">Filter</span>
                                </button>
                                {
                                    filterDropdown && (
                                    <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                                        <span onClick={hideFilterDropdown} className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm">close</span>
                                        {/* <h5 className="font-semibold">Status</h5>
                                        <input value={value} placeholder="Search by reference ID" onChange={(e)=>setValue(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" /> */}

                                        <h5 className="font-semibold mt-2">Date</h5>
                                        <div className="flex items-center gap-4">
                                            <input placeholder="Search by dispute ID" onChange={(e)=>setStartDate(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" type="date"/>
                                        </div>

                                        <h5 className="font-semibold mt-2">Dispute Status</h5>
                                        <div className="flex items-center gap-4">
                                            <select type="" onChange={(e)=>setStatus(e.target.value)} className="w-full h-10 rounded border border-gray-200">
                                            <option value="">Choose status</option>
                                                <option value="RESPONSE NEEDED">RESPONSE NEEDED</option>
                                                <option value="UNDER REVIEW">UNDER REVIEW</option>
                                                <option value="MERCHANT WON">MERCHANT WON</option>
                                                <option value="CUSTOMER WON">CUSTOMER WON</option>
                                            </select>    
                                        </div>

                                        <h5 className="font-semibold mt-2">Dispute Type</h5>
                                        <div className="flex items-center gap-4">
                                            <select type="" onChange={(e)=>setType(e.target.value)} className="w-full h-10 rounded border border-gray-200">
                                            <option value="">Choose status</option>
                                                <option value="CHARGEBACK">CHARGEBACK</option>
                                                <option value="FRAUD">FRAUD</option>
                                                <option value="COMPLAINT">COMPLAINT</option>
                                            </select>    
                                        </div>

                                        <div className="flex items-center gap-4 mt-5">
                                            <button onClick={ClearFilter} className="py-3 w-full text-xs rounded shadow">Clear filter</button>
                                            <button onClick={()=>FilterDataById(value)}  className="py-3 w-full rounded bg-primary-theme text-white text-xs">Apply filter</button>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                            <div className="1/5 flex gap-1 ...">
                                <input placeholder="Search by dispute ID" onChange={(e)=>FilterDataById(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" />
                                <button onClick={()=>FilterDataById(value)} className="bg-primary-theme py-2 px-5 text-white text-sm">Search</button>
                            </div>
                        </div>
                        {
                            allFilteredData?.length > 0
                            ?
                            <CSVLink data={allFilteredData}>
                                <button className="flex shadow text-white h-10 items-center text-sm px-3 rounded bg-green-500">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.1673 16.6666H5.00065C4.08018 16.6666 3.33398 15.9205 3.33398 15V5.83331C3.33398 4.91284 4.08018 4.16665 5.00065 4.16665H8.33398V5.83331H5.00065V15H14.1673V11.6666H15.834V15C15.834 15.9205 15.0878 16.6666 14.1673 16.6666ZM9.75065 11.4225L8.57565 10.2441L13.8198 4.99998H10.834V3.33331H16.6673V9.16665H15.0007V6.17915L9.75065 11.4225Z" fill="white"/>
                                </svg>
                                    Export Data
                                </button>
                            </CSVLink>
                            :
                            <button onClick={()=> toast("You can't export empty data.")} className="flex shadow text-white h-10 items-center text-sm px-3 rounded bg-green-500">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.1673 16.6666H5.00065C4.08018 16.6666 3.33398 15.9205 3.33398 15V5.83331C3.33398 4.91284 4.08018 4.16665 5.00065 4.16665H8.33398V5.83331H5.00065V15H14.1673V11.6666H15.834V15C15.834 15.9205 15.0878 16.6666 14.1673 16.6666ZM9.75065 11.4225L8.57565 10.2441L13.8198 4.99998H10.834V3.33331H16.6673V9.16665H15.0007V6.17915L9.75065 11.4225Z" fill="white"/>
                                </svg>
                                Export Data
                            </button>
                        }
                    </div>
                    <Grid spacing={2} container className="px-6 pt-5">
                        <Grid item xs={12} sm={12} md={3}>
                            <CardThree notCurrency="true" color="text-primary-theme" name="TOTAL DISPUTE" amount={disputeStats?.RESPONSE_NEEDED} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <CardThree notCurrency="true" color="text-green-500" name="TOTAL WON DISPUTE" amount={disputeStats?.MERCHANT_WON} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <CardThree notCurrency="true" color="text-gray-600" name="TOTAL LOST DISPUTE" amount={disputeStats?.CUSTOMER_WON} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <CardThree notCurrency="true" color="text-primary-theme" name="TOTAL DISPUTE UNDER REVIEW" amount={disputeStats?.UNDER_REVIEW} />
                        </Grid>
                    </Grid>
                    <Table tableColumnStructure={tableColumnStructure} tableRowData={allFilteredData} pageName="Transaction"/>
                </>
            )
        }
        </>
    )
}

export default DisputeInner