import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  FilterIcon,
  ExportIcon,
  ChevronDownIcon,
  CopyIcon,
  BackIcon,
} from "../assets";
import { Card, Table } from "./index.js";
import {
  getAllSubscription,
  createSubscription,
  getSubscriptionStats,
  getSubscribersDetails,
  cancelSubscription,
} from "../services/requests/subscriptions";
// import { getSubscribersDetails } from "../services/requests/payment_link";
import {
  getAllPlan,
  createPlan,
  deletePlan,
  updatePlan,
  getSinglePlan,
} from "../services/requests/plans";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import { getAllCustomers } from "../services/requests/customers";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { FormatDatetime } from "../utils/FormatDatetime";

function CustomerInner() {
  const [subscription, setSubscription] = useState([]);
  const [subscriptionStats, setSubscriptionStats] = useState(null);
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [status, setStatus] = useState("");
  const [status2, setStatus2] = useState("");
  const [showPopup, setShowPopup] = useState("");
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  const [filterDropdown, setFilterDropdown] = useState(false);
  const [filterDropdown2, setFilterDropdown2] = useState(false);

  const [allSubscription, setAllSubscription] = useState([]);
  const [allFilteredData, setFilteredData] = useState([]);
  const [subscriptionTab, setSubscriptionTab] = useState(true);
  const [otherSubscriptionDetails, setOtherSubscriptionDetails] =
    useState(null);
  const [createSubOverlay, setcreateSubOverlay] = useState(false);
  const [createPlanOverlay, setcreatePlanOverlay] = useState(false);
  const [editPlanOverlay, setEditPlanOverlay] = useState(false);
  const [plansList, setplansList] = useState([]);
  const [subTab, setSubTab] = useState(1);
  const [customer, setcustomer] = useState([]);

  const [plan, setPlan] = useState([]);
  const [allPlan, setAllPlan] = useState([]);
  const [allFilteredData2, setFilteredData2] = useState([]);
  const [selectedPlan, setselectedPlan] = useState(null);
  const [showPlanPopup, setShowPlanPopup] = useState(false);

  const [planName, setPlanName] = useState("");
  const [planDescription, setPlanDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [interval, setInterval] = useState("");
  const [chargeNunmber, setChargeNumber] = useState("");
  const [planDetails, setPlanDetails] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [startDate2, setStartDate2] = useState("");

  const [startDateImmediate, setStartDateImmediate] = useState(false);
  const [description, setDescription] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [expiryDate, setexpiryDate] = useState("");
  const [expiryStatus, setexpiryStatus] = useState(false);

  const [startDateTomorrow, setstartDateTomorrow] = useState("2022-01-01");
  const [today, setToday] = useState("2022-01-01");

  useEffect(() => {
    // setTomorrow of start date
    var tomorrow = new Date(startDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    setstartDateTomorrow(
      tomorrow.toLocaleString().split(", ")[0].split("/").reverse().join("-") +
        "T08:00"
    );
  }, [startDate]);

  useEffect(() => {
    // setToday date
    var _today = new Date();
    var dd = _today.getDate();
    var mm = _today.getMonth() + 1; //January is 0!
    var yyyy = _today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    _today = yyyy + "-" + mm + "-" + dd;
    setToday(_today + "T08:00");

    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    getSubscriptionStats(merchantId).then((response) => {
      if (response?.status) {
        //  console.log('resp', response)
        setSubscriptionStats(response?.data);
      }
    });

    getAllSubscription().then((response) => {
      const tableRowData = response?.data?.content?.map((subscription) => ({
        id: subscription.customerSubscriptionId,
        subscriptionLink: subscription.customerPaymentLink,
        customerId: subscription.customerId,
        createdAt: subscription.createdAt,
        dueDate: subscription.nextDueDate,
        status: subscription.status,
      }));
      setSubscription(response?.data?.content);
      setAllSubscription(tableRowData);
      setFilteredData(tableRowData);

      // let b = {};
      // response?.data?.settlements.forEach(element => {
      //     b[Object.values(element)[0]] = Object.values(element)[1];
      // });
    });

    fetchAllPlans();

    getAllCustomers().then((response) => {
      const customerResponse = response?.data?.content.map((elem, index) => ({
        id: elem.customerId,
        customerName: `${elem.firstName} ${elem.lastName}`,
        email: elem.email,
        contact: `${elem.phoneNumber}`,
        status: elem.status,
      }));
      setcustomer(customerResponse);
    });
  }, []);

  const fetchAllPlans = () => {
    getAllPlan().then((response) => {
      const tableRowData2 = response?.data?.content?.map((plan) => ({
        planName: plan.planName,
        id: plan.planId,
        planAmount: `NGN ${plan.planAmount}`,
        createdBy: plan.createdBy,
        createdAt: plan.createdAt,
        totalCount: plan.totalCount,
        totalRevenueGenerated: plan.totalRevenueGenerated,
      }));

      setplansList(tableRowData2);
      setPlan(response?.data?.content);
      setAllPlan(tableRowData2);
      setFilteredData2(tableRowData2);
    });
  };

  const fetchSinglePlan = (planId) => {
    getSinglePlan(planId).then((response) => {
      console.log(response, "RESPONSE");
      setPlanDetails(response?.data);
    });
  };

  const hideOverlay = () => {
    setShowPopup(false);
  };

  const ActivatePlanTab = () => {
    setSubscriptionTab(false);
  };
  const ActivateSubscriptionTab = () => {
    setSubscriptionTab(true);
  };

  const tableColumnStructure = [
    { field: "id", headerName: "Subscription ID", width: 290 },
    {
      field: "subscriptionLink",
      headerName: "Subscription Link",
      width: 350,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigator.clipboard.writeText(params.row.subscriptionLink);
          toast("Link copied.");
        };
        return (
          <div className="relative">
            <span className="relative z-10">{params.row.subscriptionLink}</span>
            <button
              className="bg-white absolute left-80"
              style={{ zIndex: 100 }}
              onClick={onClick}
            >
              <CopyIcon />
            </button>
          </div>
        );
      },
    },
    { field: "customerId", headerName: "Customer ID", width: 250 },
    { field: "createdAt", headerName: "Created At", width: 220 },
    { field: "dueDate", headerName: "Next Due on", width: 150 },
    { field: "status", headerName: "Status", width: 190 },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const data = subscription.filter(
            (elem) => elem.customerSubscriptionId === params.row.id
          );
          if (data.length === 1) {
            setShowPopup(true);
            const selectedSubscription = data[0];
            setSubscriptionDetails(selectedSubscription);
            // Fetch details about payment link
            const payload = {
              merchantId: JSON.parse(
                window.sessionStorage.getItem("merchantId")
              ),
              customerSubscriptionId:
                selectedSubscription?.customerSubscriptionId,
            };
            getSubscribersDetails(payload)
              .then((response) => {
                const data = response.data[0];

                setOtherSubscriptionDetails(data);
              })
              .catch((error) => {
                toast.error(
                  "Error occured while fetching subscription subscribers"
                );
              });
          }
        };
        return (
          <button
            className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white"
            onClick={onClick}
          >
            View More
          </button>
        );
      },
    },
  ];

  const tableColumnStructure2 = [
    { field: "planName", headerName: "Plan Name", width: 140 },
    {
      field: "id",
      headerName: "Plan ID",
      width: 270,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigator.clipboard.writeText(params.row.id);
          toast("Link copied.");
        };
        return (
          <div className="relative">
            <span className="relative z-10">{params.row.id}</span>
            <button
              className="bg-white absolute left-full"
              style={{ zIndex: 100 }}
              onClick={onClick}
            >
              <CopyIcon />
            </button>
          </div>
        );
      },
    },
    { field: "planAmount", headerName: "Amount", width: 110 },
    // { field: 'createdBy', headerName: 'Created By', width: 120 },
    { field: "createdAt", headerName: "Created At", width: 220 },
    { field: "totalCount", headerName: "No. of Subscriptions", width: 140 },
    { field: "totalRevenueGenerated", headerName: "Total Revenue", width: 190 },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const data = plan.filter((elem) => elem.planId === params.row.id);
          if (data.length === 1) {
            setShowPlanPopup(true);
            setPlanDetails(data[0]);
          }
        };
        return (
          <button
            className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white"
            onClick={onClick}
          >
            View More
          </button>
        );
      },
    },
  ];

  const showCreateSubscriptionOverlay = () => {
    setcreateSubOverlay(true);
  };
  const showCreatePlanOverlay = () => {
    setcreatePlanOverlay(true);
  };
  const hideCreateSubscriptionOverlay = (e) => {
    if (
      e.target.classList.contains("overlay") ||
      e.target.classList.contains("close-btn")
    ) {
      setcreateSubOverlay(false);
      setSubTab(1);
    }
  };
  const hideCreatePlanOverlay = (e) => {
    if (
      e.target.classList.contains("overlay") ||
      e.target.classList.contains("close-btn")
    ) {
      setcreatePlanOverlay(false);
      setEditPlanOverlay(false);
    }
  };

  const CreatePlanHandler = () => {
    const payload = {
      currency: "NGN",
      interval: interval.toUpperCase(),
      planAmount: amount,
      planDescription: planDescription,
      planName: planName,
      totalCount: chargeNunmber,
    };
    if (amount >= 100) {
      if (planName && amount) {
        createPlan(payload).then((response) => {
          toast.success(response.message);
          setcreatePlanOverlay(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      } else {
        toast.error("Make sure you fill all the required field");
      }
    } else {
      toast.error("Amount cannot be less than 100");
    }
  };

  const gotoPlan = () => {
    setcreateSubOverlay(false);
    ActivatePlanTab();
    showCreatePlanOverlay();
  };

  const deletePlanFn = (planId) => {
    if (planId) {
      deletePlan(planId)
        .then((response) => {
          toast.success(response.message);
          fetchAllPlans();
          setShowPlanPopup(false);
        })
        .catch((error) => {
          toast.error("Internal error occured while deleting plan");
        });
    }
  };

  const showEditPlanModal = () => {
    setPlanName(planDetails?.planName);
    setPlanDescription(planDetails?.planDescription);
    setAmount(planDetails?.planAmount);
    setInterval(planDetails?.interval);
    setChargeNumber(planDetails?.totalCount);
    setEditPlanOverlay(true);
  };

  const updatePlanFn = () => {
    const payload = {
      currency: "NGN",
      interval: interval.toUpperCase(),
      planAmount: amount,
      planDescription: planDescription,
      planName: planName,
      totalCount: chargeNunmber,
      planId: planDetails?.planId,
      otherDetailsJSON: JSON.stringify({}),
      status: planDetails?.status
    };
    if (amount >= 100) {
      if (planName && amount) {
        updatePlan(payload).then((response) => {
          fetchSinglePlan(planDetails?.planId);
          toast.success(response.message);
          setEditPlanOverlay(false);
        });
      } else {
        toast.error("Make sure you fill all the required field");
      }
    } else {
      toast.error("Amount cannot be less than 100");
    }
  };

  const AddCustomerToSubscription = () => {
    const payload = {
      applyDateAfterFirstPayment: startDateImmediate,
      customerId: customerId,
      linkCanExpire: expiryStatus,
      linkExpirationDate: new Date(expiryDate),
      note: description,
      notifyCustomer: true,
      planId: selectedPlan?.id,
      startDateAfterFirstPayment: new Date(startDate),
      totalCount: selectedPlan?.totalCount,
    };

    createSubscription(payload)
      .then((response) => {
        toast(response.message);
        setcreateSubOverlay(false);
        setSubTab(1);
        window.location.reload();
      })
      .catch((error) => {
        toast("Error occured");
        setcreateSubOverlay(false);
        setSubTab(1);
        window.location.reload();
      });
  };

  const cancelSubscriptionFn = (customerSubscriptionId) => {
    if (customerSubscriptionId) {
      cancelSubscription(customerSubscriptionId)
        .then((response) => {
          toast.success(response.message);
          window.location.reload();
        })
        .catch((error) => {
          toast.error("Internal error occured while cancelling");
        });
    }
  };

  const moveToNextTab = () => {
    if (subTab == 1) {
      if (selectedPlan && startDate) {
        setSubTab(() => (subTab <= 2 ? subTab + 1 : subTab));
      } else {
        toast("All fields here are required.");
      }
    }
    if (subTab == 2) {
      if (customerId && expiryDate && description) {
        setSubTab(() => (subTab <= 2 ? subTab + 1 : subTab));
      } else {
        toast.error("All fields here are required.");
      }
    }
  };

  // filtering for subscription
  const FilterDataById = (id) => {
    setValue(id);
    if (startDate) {
      const data = allSubscription;
      const outcome = data?.filter(
        (element) => element.createdAt.split("T")[0] == startDate
      );
      setFilteredData(outcome);
      hideFilterDropdown();
    } else {
      if (id) {
        const data = allSubscription;
        // element=> element.id === id);
        const outcome = data.filter(
          (element) => element.id.toLowerCase().indexOf(id.toLowerCase()) > -1
        );
        setFilteredData(outcome);
        hideFilterDropdown();
      }
      // else if(status){
      //     const data = allSubscription;
      //     const outcome = data?.filter(element=> element.status === status.toUpperCase());
      //     setFilteredData(outcome);
      //     hideFilterDropdown();
      // }
      else {
        setFilteredData(allSubscription);
        hideFilterDropdown();
      }
    }
  };

  const ClearFilter = () => {
    setValue(() => "");
    setStartDate(() => "");
    setStatus(() => "");
    setFilteredData(allSubscription);
    hideFilterDropdown();
  };

  const showFilterDropdown = () => setFilterDropdown(true);
  const hideFilterDropdown = () => setFilterDropdown(false);

  // filtering for plan
  const FilterDataById2 = (id) => {
    setValue(id);
    if (startDate) {
      const data = allPlan;
      const outcome = data?.filter(
        (element) => element.createdAt.split("T")[0] == startDate
      );
      setFilteredData2(outcome);
      hideFilterDropdown2();
    } else {
      if (id) {
        const data = allPlan;
        // const outcome = data.filter(element=> element.id === id);
        const outcome = data.filter(
          (element) => element.id.toLowerCase().indexOf(id.toLowerCase()) > -1
        );
        setFilteredData2(outcome);
        hideFilterDropdown2();
      }
      // else if(status){
      //     const data = allSubscription;
      //     const outcome = data?.filter(element=> element.status === status.toUpperCase());
      //     setFilteredData(outcome);
      //     hideFilterDropdown();
      // }
      else {
        setFilteredData2(allPlan);
        hideFilterDropdown2();
      }
    }
  };

  const ClearFilter2 = () => {
    setValue2(() => "");
    setStartDate2(() => "");
    // setStatus(()=> '');
    setFilteredData2(allPlan);
    hideFilterDropdown2();
  };

  const showFilterDropdown2 = () => setFilterDropdown2(true);
  const hideFilterDropdown2 = () => setFilterDropdown2(false);

  return (
    <>
      <ToastContainer />
      <div className="my-4 mx-6 bg-gray-100 flex justify-between gap-2">
        {!showPopup && (
          <div className="btn-group flex gap-2">
            <button
              onClick={ActivateSubscriptionTab}
              className={`${
                subscriptionTab ? "bg-primary-theme" : "text-gray-700"
              } text-white px-3 py-2 text-sm rounded-full`}
            >
              Subscription
            </button>
            <button
              onClick={ActivatePlanTab}
              className={`${
                !subscriptionTab ? "bg-primary-theme" : "text-gray-700"
              } text-white px-3 py-2 text-sm rounded-full`}
            >
              Plans
            </button>
          </div>
        )}

        {showPopup && (
          <div className="w-full">
            <button
              className="mx-8 my-4 flex items-center text-sm gap-2"
              onClick={hideOverlay}
            >
              <BackIcon /> Back
            </button>
            <div className="grid grid-cols-1 h-auto px-8">
              <div className="bg-white w-full h-auto border px-6 border-gray-200 rounded-xl py-5">
                <h5 className="px-6">
                  <strong>
                    Subscription - {subscriptionDetails?.customerSubscriptionId}
                  </strong>
                </h5>
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Customer Name</span>
                  <span className="text-xs font-semibold flex items-center gap-2">
                    {`${otherSubscriptionDetails?.firstName} ${otherSubscriptionDetails?.lastName}`}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Customer Email</span>
                  <span className="text-xs font-semibold flex items-center gap-2">
                    {otherSubscriptionDetails?.email}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Phone Number</span>
                  <span className="text-xs font-semibold flex items-center gap-2">
                    {otherSubscriptionDetails?.phoneNumber}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Customer Status</span>
                  <span className="text-xs font-semibold flex items-center gap-2">
                    {otherSubscriptionDetails?.customerStatus}
                  </span>
                </div>
                <hr />
                
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Customer Card</span>
                  <span className="text-xs font-semibold flex items-center gap-2">
                    **** **** **** 4414{" "}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-center px-8 pt-5">
                  <Link
                    to="/customers"
                    className="text-sm py-2 px-4 shadow text-primary-theme"
                  >
                    View Customer
                  </Link>
                </div>
                {/* <hr />
                                    <div className="flex justify-between items-start px-8 py-5">
                                        <span className="text-xs text-gray-500">Email</span>
                                        <span className="text-xs font-semibold flex items-center gap-2">{subscriptionDetails?.email} </span>
                                    </div>
                                    <hr />
                                    <div className="flex justify-between items-start px-8 py-5">
                                        <span className="text-xs text-gray-500">Phone number</span>
                                        <span className="text-xs font-semibold flex items-center gap-2">{subscriptionDetails?.phoneNumber}</span>
                                    </div> */}
              </div>
              <div className="bg-white w-full mt-4 h-auto border border-gray-200 rounded-xl p-1">
                <div className="h-12 rounded bg-red-100 w-full flex items-center px-4 gap-5">
                  <button className="text-primary-theme text-sm">
                    Subcription
                  </button>
                  {/* <button className=" text-sm">Subcriptions</button> */}
                </div>
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Plan ID</span>
                  <span className="text-xs font-semibold flex items-center gap-2">
                    {subscriptionDetails?.planId}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Customer Subscription ID</span>
                  <span className="text-xs font-semibold flex items-center gap-2">
                    {otherSubscriptionDetails?.customerSubscriptionId}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Status</span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-green-500">
                    {subscriptionDetails?.status}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">
                    Subscription Code
                  </span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-gray-500">
                    {subscriptionDetails?.customerSubscriptionId}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Total Payment Amount Made</span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-gray-500">
                    {subscriptionDetails?.totalRevenueGenerated}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Amount</span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-gray-500">
                    {subscriptionDetails?.totalChargeCount}
                  </span>
                </div>
                <hr />
                {/* <div className="flex justify-between items-start px-8 py-5">
                                        <span className="text-xs text-gray-500">Interval</span>
                                        <span className="text-xs font-semibold flex items-center gap-2 text-gray-500"> Monthly</span>
                                    </div> */}
                <hr />
                {/* <div className="flex justify-between items-start px-8 py-5">
                                        <span className="text-xs text-gray-500">Payments</span>
                                        <span className="text-xs font-semibold flex items-center gap-2 text-gray-500"> {subscriptionDetails?.numberOfTimesCharged} of {subscriptionDetails?.totalChargeCount}</span>
                                    </div> */}
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">
                    Subscription date
                  </span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-gray-500">
                    {" "}
                    {otherSubscriptionDetails?.startDateAfterFirstPayment}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">Date Unsubscription</span>
                  <span className="text-xs font-semibold flex items-center gap-2">
                    {otherSubscriptionDetails?.dateUnsubscribed?? '--'}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">
                    Resubscription Date
                  </span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-gray-500">
                    {" "}
                    {otherSubscriptionDetails?.reSubscriptionDate}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">
                    Subscription Payment Date
                  </span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-gray-500">
                    {" "}
                    {otherSubscriptionDetails?.subscriptionPaymentDate}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">
                    Link Expiration Date
                  </span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-gray-500">
                    {" "}
                    {otherSubscriptionDetails?.linkExpirationDate}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-start px-8 py-5">
                  <span className="text-xs text-gray-500">
                    Next charge date
                  </span>
                  <span className="text-xs font-semibold flex items-center gap-2 text-gray-500">
                    {" "}
                    {otherSubscriptionDetails?.startDateAfterFirstPayment}
                  </span>
                </div>
                <hr />
                <div className="flex justify-between items-center px-8 pt-5">
                  <button
                    className="text-sm py-2 px-4 shadow text-white bg-primary-theme"
                    onClick={() =>
                      cancelSubscriptionFn(
                        subscriptionDetails?.customerSubscriptionId
                      )
                    }
                  >
                    Cancel this subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {subscriptionTab &&
          !showPopup &&
          (allFilteredData?.length > 0 ? (
            <CSVLink data={allFilteredData}>
              <button className="flex shadow ml-auto gap-2 h-10 items-center text-sm px-5">
                <ExportIcon />
                Export Subscription
                <ChevronDownIcon />
              </button>
            </CSVLink>
          ) : (
            <button
              onClick={() => toast("You can't export empty data.")}
              className="flex shadow ml-auto gap-2 h-10 items-center text-sm px-5"
            >
              <ExportIcon />
              Export Subscription
              <ChevronDownIcon />
            </button>
          ))}
        {!subscriptionTab &&
          !showPopup &&
          (allFilteredData2?.length > 0 ? (
            <CSVLink data={allFilteredData2}>
              <button className="flex shadow ml-auto gap-2 h-10 items-center text-sm px-5">
                <ExportIcon />
                Export Plan
                <ChevronDownIcon />
              </button>
            </CSVLink>
          ) : (
            <button
              onClick={() => toast("You can't export empty data.")}
              className="flex shadow ml-auto gap-2 h-10 items-center text-sm px-5"
            >
              <ExportIcon />
              Export Plan
              <ChevronDownIcon />
            </button>
          ))}
      </div>
      {subscriptionTab && !showPopup && (
        <>
          {createSubOverlay && (
            <div
              onClick={hideCreateSubscriptionOverlay}
              className="w-full overlay h-screen fixed top-0 left-0 bg-['#000095'] flex items-center justify-center"
              style={{ zIndex: "120" }}
            >
              <div className="w-2/3 h-auto flex bg-white mx-auto mt-14 ...">
                <div className="w-1/3 p-5">
                  <h4 className="text-xl font-semibold">Create Subscription</h4>
                  <p className="pr-16 leading-tight text-sm">
                    Provide details to create a subscription link
                  </p>
                </div>
                <div className="w-2/3 bg-gray-200 h-auto">
                  <nav className="w-full h-12 bg-white shadow flex items-center px-5">
                    Subscription Details
                  </nav>
                  {subTab == 1 && (
                    <div className="plan-details p-5">
                      <div className="input-container flex flex-col items-start">
                        <label className="text-sm flex items-center mb-2 w-3/4">
                          Select plan <span className="text-red-500">*</span>
                          <button
                            onClick={gotoPlan}
                            className="bg-primary-theme text-white py-2 px-4 rounded flex text-xs ml-auto"
                          >
                            Create Plan
                          </button>
                        </label>
                        <select
                          onChange={(e) => {
                            const selectedPlan = plansList.find(
                              (selectedPlan) =>
                                selectedPlan.planName === e.target.value
                            );
                            setselectedPlan(selectedPlan);
                          }}
                          name="plans"
                          className="w-3/4 h-10 flex rounded border border-gray-200 text-sm px-3 ..."
                        >
                          <option>Select plan</option>
                          {plansList.map((plan) =>
                            plan.planName ? (
                              <option>{plan.planName}</option>
                            ) : null
                          )}
                        </select>
                      </div>

                      <div className="input-container mt-3 flex flex-col items-start">
                        <label className="text-sm">
                          Start Date <span className="text-red-500">*</span>
                        </label>
                        <div className="flex items-center my-2 gap-2">
                          {/* <input type="checkbox" onChange={(e)=> setStartDateImmediate(e.target.checked)} /> <span className="text-sm">Immediate subscription starts with the first payment</span> */}
                        </div>
                        {/* disabled={startDateImmediate ? true : false} */}
                        <input
                          name="startDate"
                          type="datetime-local"
                          min={today}
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="w-3/4 h-10 flex rounded border border-gray-200 text-sm px-3 ..."
                        />

                        <div className="flex items-center my-2 gap-2">
                          <span className="text-sm">
                            Date from which subscription should start.
                          </span>
                        </div>
                      </div>

                      <div className="input-container mt-3 flex flex-col items-start">
                        <label className="text-sm">
                          Total count <span className="text-red-500">*</span>
                        </label>
                        <input
                          value={
                            selectedPlan?.totalCount
                              ? selectedPlan?.totalCount
                              : "1"
                          }
                          type="number"
                          className="w-3/4 h-10 flex rounded border border-gray-200 text-sm px-3 ..."
                        />

                        <div className="flex items-center my-2 gap-2">
                          <span className="text-sm">
                            Number of billing cycle to be charged.
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {subTab == 2 && (
                    <div className="plan-details p-5">
                      <div className="input-container flex flex-col items-start">
                        <label className="text-sm">
                          Select customer{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <select
                          name=""
                          onChange={(e) => setCustomerId(e.target.value)}
                          className="w-3/4 h-10 flex rounded border border-gray-200 text-sm px-3 ..."
                        >
                          <option>Select your customer</option>
                          {customer.map((customer) =>
                            customer.email ? (
                              <option value={customer?.id}>
                                {customer.email}
                              </option>
                            ) : null
                          )}
                        </select>
                        <p className="tetx-gray-600 mt-2 text-sm">
                          Can't find customer?{" "}
                          <Link to="/customer" className="text-primary-theme">
                            Create new customer
                          </Link>
                        </p>
                      </div>

                      <div className="input-container mt-3 flex flex-col items-start">
                        <div className="flex items-center gap-3 mt-6">
                          <input
                            checked
                            type="checkbox"
                            name="notify-customer"
                          />
                          <span className="text-sm">Notify Customer</span>
                        </div>
                        <div className="flex items-center gap-3 my-3">
                          {expiryStatus && (
                            <input
                              type="checkbox"
                              checked
                              onChange={(e) =>
                                setexpiryStatus(e.target.checked)
                              }
                              name="notify-customer"
                            />
                          )}
                          {!expiryStatus && (
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setexpiryStatus(e.target.checked)
                              }
                              name="notify-customer"
                            />
                          )}{" "}
                          <span className="text-sm">Link Expiry Date</span>
                        </div>

                        <label className="text-sm">
                          Expiry Date <span className="text-red-500">*</span>
                        </label>

                        <input
                          type="datetime-local"
                          disabled={expiryStatus ? false : true}
                          name="expiryDate"
                          value={expiryDate}
                          min={startDateTomorrow}
                          onChange={(e) => setexpiryDate(e.target.value)}
                          className="w-3/4 h-10 flex rounded border border-gray-200 text-sm px-3 ..."
                        />
                      </div>

                      <div className="input-container mt-3 flex flex-col items-start">
                        <label className="text-sm">
                          Add note <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Describe your subscription"
                          className="w-3/4 h-20 flex rounded border border-gray-200 text-sm px-3 ..."
                        />
                      </div>
                    </div>
                  )}
                  {subTab == 3 && (
                    <div className="plan-details p-5">
                      <h5 className="font-semibold text-md">Plan Name</h5>
                      <p className="text-sm">{selectedPlan?.planName}</p>

                      <h5 className="font-semibold text-md mt-6">
                        Billing Details
                      </h5>
                      <p className="text-sm flex items-center gap-2">
                        The subscription is{" "}
                        <h5 className="font-semibold">
                          {selectedPlan?.planAmount}
                        </h5>{" "}
                        {selectedPlan?.interval}
                      </p>

                      <p className="text-xs mt-6">First payment</p>
                      <h5 className="font-semibold text-md">
                        Authorization Payment {selectedPlan?.planAmount}{" "}
                      </h5>
                      <p className="text-sm">
                        subscription Amount = {selectedPlan?.planAmount}*
                        {selectedPlan?.totalCount}(QTY)
                      </p>

                      <p className="text-xs mt-6">
                        Every month after the first payment payment
                      </p>
                      <h5 className="font-semibold text-md">
                        Recurrent Payments {selectedPlan?.planAmount}{" "}
                      </h5>

                      <p className="text-xs mt-6 flex items-center gap-3">
                        Number of cycles ={" "}
                        <h5 className="text-lg font-semibold">
                          {selectedPlan?.totalCount}
                        </h5>
                      </p>
                    </div>
                  )}
                  <nav className="w-full h-14 bg-white shadow flex items-center justify-end gap-3 px-5">
                    {subTab > 1 && (
                      <button
                        onClick={() =>
                          setSubTab(() => (subTab > 1 ? subTab - 1 : subTab))
                        }
                        className="bg-primary-theme text-white text-sm px-6 rounded py-2"
                      >
                        Previous
                      </button>
                    )}
                    <button
                      onClick={() =>
                        subTab <= 2
                          ? moveToNextTab()
                          : AddCustomerToSubscription()
                      }
                      className="bg-primary-theme text-white text-sm px-6 rounded py-2"
                    >
                      {subTab <= 2 ? "Next" : "Finish"}
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          )}
          <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
            <div className="flex items-center gap-6">
              <div className="flex items-center relative">
                <button
                  className="flex items-center"
                  onClick={showFilterDropdown}
                >
                  <FilterIcon />
                  <span className="text-sm">Filter</span>
                </button>
                {filterDropdown && (
                  <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                    <span
                      onClick={hideFilterDropdown}
                      className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm"
                    >
                      close
                    </span>
                    {/* <h5 className="font-semibold">Status</h5>
                                            <input value={value} placeholder="Search by reference ID" onChange={(e)=>setValue(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" /> */}

                    <h5 className="font-semibold mt-2">Date Range</h5>
                    <div className="flex items-center gap-4">
                      <input
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                        type="date"
                      />
                    </div>

                    {/* <h5 className="font-semibold mt-2">Subscription Status</h5>
                                            <div className="flex items-center gap-4">
                                                <select type="" onChange={(e)=>setStatus(e.target.value)} className="w-full h-10 rounded border border-gray-200">
                                                <option value="">Choose status</option>
                                                    <option value="abandoned">Abandoned</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="failed">Failed</option>
                                                    <option value="successful">Successful</option>
                                                </select>    
                                            </div> */}

                    <div className="flex items-center gap-4 mt-5">
                      <button
                        onClick={ClearFilter}
                        className="py-3 w-full text-xs rounded shadow"
                      >
                        Clear filter
                      </button>
                      <button
                        onClick={() => FilterDataById(value)}
                        className="py-3 w-full rounded bg-primary-theme text-white text-xs"
                      >
                        Apply filter
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="1/5 flex gap-1 ...">
                <input
                  onChange={(e) => FilterDataById(e.target.value)}
                  placeholder="Search by subscription ID"
                  className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                />
                <button className="bg-primary-theme py-2 px-5 text-white text-sm">
                  Search
                </button>
              </div>
            </div>
            <button
              onClick={showCreateSubscriptionOverlay}
              className="flex shadow bg-primary-theme text-white gap-2 h-10 items-center text-sm px-5"
            >
              Create New Subscription
            </button>
          </div>

          <div className="px-6 grid grid-cols-5 gap-5 pt-5">
            <div className="w-full">
              <Card
                name="Total Active  Subscription"
                amount={subscriptionStats?.totalActiveRecurrentSubscription}
                date="2nd of Jan, 2022"
                notCurrency="true"
              />
            </div>
            <div className="w-full">
              <Card
                name="Total Inactive  Subscription"
                amount={subscriptionStats?.totalInActiveRecurrentSubscription}
                date="2nd of Jan, 2022"
                notCurrency="true"
              />
            </div>
            <div className="w-full">
              <Card
                name="Total Completed Subscription"
                amount={subscriptionStats?.totalCompletedRecurrentSubscription}
                date="2nd of Jan, 2022"
                notCurrency="true"
              />
            </div>
            <div className="w-full">
              <Card
                name="Total Canceled Subscription"
                amount={subscriptionStats?.totalCancelRecurrentSubscription}
                date="2nd of Jan, 2022"
                notCurrency="true"
              />
            </div>
            <div className="w-full">
              <Card
                name="Subscription Needed Attention"
                amount={subscriptionStats?.totalAttentionRecurrentPlan}
                date="2nd of Jan, 2022"
                notCurrency="true"
              />
            </div>
          </div>
          <Table
            tableColumnStructure={tableColumnStructure}
            tableRowData={allFilteredData}
            pageName="Subscription"
          />
        </>
      )}
      {!subscriptionTab && (
        <>
          {(createPlanOverlay || editPlanOverlay) && (
            <div
              onClick={hideCreatePlanOverlay}
              className="w-full overlay h-screen fixed top-0 left-0 bg-['#000095'] overflow-y-scroll"
              style={{ zIndex: "120" }}
            >
              {/* { */}
              {/* tab === 1 &&  */}(
              <div className="w-full md:w-2/5 h-auto rounded mx-auto bg-white pb-4 ...">
                <div className="popup-header flex items-center px-4 h-14 shadow">
                  {editPlanOverlay ? "Update" : "Create a new"} plan
                </div>
                <div className="px-4">
                  <div className="w-full pt-6">
                    <label className="text-sm font-medium">
                      Plan Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setPlanName(e.target.value)}
                      value={planName}
                      placeholder="Name of your plan"
                      className="w-full px-3 text-sm h-12 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="w-full pt-3">
                    <label className="text-sm font-medium">
                      Plan Description
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setPlanDescription(e.target.value)}
                      value={planDescription}
                      placeholder="Optional"
                      className="w-full px-3 text-sm h-12 border border-gray-300 rounded"
                    />
                    <p className="mt-1 text-xs text-gray-400 italic">
                      The Plan Name and Plan Description will appear on the
                      invoice as entered above.
                    </p>
                  </div>
                  <div className="w-full pt-3">
                    <label className="text-sm font-medium">
                      Plan Amount <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                      placeholder="Cost of the plan (NGN)"
                      className="w-full px-3 text-sm h-12 border border-gray-300 rounded"
                    />
                    <p className="mt-1 text-xs text-gray-400 italic">
                      Minimum Plan amount is NGN 100
                    </p>
                  </div>
                  <div className="w-full pt-3">
                    <label className="text-sm font-medium">
                      Interval <span className="text-red-500">*</span>
                    </label>
                    <select
                      type="text"
                      onChange={(e) => setInterval(e.target.value)}
                      value={interval}
                      placeholder="Cost of the plan (NGN)"
                      className="w-full px-3 text-sm h-12 border border-gray-300 rounded"
                    >
                      <option>Choose plan interval</option>
                      <option value="DAILY">Daily</option>
                      <option value="WEEKLY">Weekly</option>
                      <option value="MONTHLY">Monthly</option>
                      <option value="QUARTERLY">Quarterly</option>
                      <option value="SEMI_ANNUAL">Semi Annually</option>
                      <option value="ANNUALLY">Annually</option>
                      <option value="BI_ANNUAL">Bi Annually</option>
                    </select>
                  </div>
                  <div className="w-full pt-3">
                    <label className="text-sm font-medium">
                      Number of times to charge a subscriber?{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setChargeNumber(e.target.value)}
                      value={chargeNunmber}
                      placeholder="Set Limit [Optional]"
                      className="w-full px-3 text-sm h-12 border border-gray-300 rounded"
                    />
                    <p className="mt-1 text-xs text-gray-400 italic">
                      The fied numer of time you want this transaction to run
                      for. Leave it empty to charge subscribers indefinitely.
                    </p>
                  </div>
                </div>
                <div className="popup-header flex justify-between items-center px-4 h-14 pt-5">
                  <button
                    onClick={hideCreatePlanOverlay}
                    className="close-btn bg-gray-200 border border-gray-300 text-gray-900 py-3 rounded text-sm px-5"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={editPlanOverlay ? updatePlanFn : CreatePlanHandler}
                    className="bg-primary-theme text-white py-3 rounded text-sm px-5"
                  >
                    {editPlanOverlay ? "Update" : "Create"} Plan
                  </button>
                </div>
              </div>
              )
            </div>
          )}
          {showPlanPopup && (
            <div className="w-full">
              <button
                className="mx-8 my-4 flex items-center text-sm gap-2"
                onClick={() => setShowPlanPopup(false)}
              >
                <BackIcon /> Back
              </button>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 h-auto px-8">
                <div className="bg-white w-full h-auto border px-6 border-gray-200 rounded-xl py-5">
                  <h5 className="px-6">
                    <strong>
                      Plan - {subscriptionDetails?.customerSubscriptionId}
                    </strong>
                  </h5>
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">Plan status</span>
                    <div className="text-xs font-semibold flex items-center gap-2">
                      <span className="text-green-600 bg-green-100 py-2 px-3 rounded border border-green-600">
                        ACTIVE
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">Plan name</span>
                    <span className="text-xs font-semibold flex items-center gap-2">
                      {planDetails?.planName}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">
                      Plan description
                    </span>
                    <span className="text-xs font-semibold flex items-center gap-2">
                      {planDetails?.planDescription}{" "}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">Plan ID</span>
                    <span className="text-xs font-semibold flex items-center gap-2">
                      {planDetails?.planId}{" "}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">Plan Interval</span>
                    <span className="text-xs font-semibold flex items-center gap-2">
                      {planDetails?.interval}{" "}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">
                      Max. Number of Payments
                    </span>
                    <span className="text-xs font-semibold flex items-center gap-2">
                      {planDetails?.totalCount}{" "}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">
                      No. of Subscriptions
                    </span>
                    <span className="text-xs font-semibold flex items-center gap-2">
                      {planDetails?.planId}{" "}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">Total Revenue</span>
                    <span className="text-xs font-semibold flex items-center gap-2">
                      {planDetails?.totalRevenueGenerated}{" "}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between items-start px-8 py-5">
                    <span className="text-xs text-gray-500">Date Created</span>
                    <span className="text-xs font-semibold flex items-center gap-2">
                      {planDetails?.createdAt}{" "}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-center gap-3 items-center px-8 pt-5">
                    <button
                      className="text-sm py-2 px-4 shadow text-primary-theme"
                      onClick={showEditPlanModal}
                    >
                      Update plan
                    </button>
                    <button
                      className="text-sm py-2 px-4 shadow text-white bg-primary-theme"
                      onClick={() => deletePlanFn(planDetails?.planId)}
                    >
                      Delete plan
                    </button>
                  </div>
                  {/* <hr />
                                            <div className="flex justify-between items-start px-8 py-5">
                                                <span className="text-xs text-gray-500">Email</span>
                                                <span className="text-xs font-semibold flex items-center gap-2">{subscriptionDetails?.email} </span>
                                            </div>
                                            <hr />
                                            <div className="flex justify-between items-start px-8 py-5">
                                                <span className="text-xs text-gray-500">Phone number</span>
                                                <span className="text-xs font-semibold flex items-center gap-2">{subscriptionDetails?.phoneNumber}</span>
                                            </div> */}
                </div>
                <div className="bg-white w-full mt-4 lg:mt-0 h-auto border border-gray-200 rounded-xl p-1">
                  <div className="h-12 rounded w-full flex items-center px-4 gap-8">
                    <button className="text-sm text-gray-500">
                      Subscriptions(1)
                    </button>
                    <button className="text-sm text-gray-500">
                      Subscribers (1)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!showPlanPopup && (
            <>
              <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
                <div className="flex items-center gap-6">
                  <div className="relative">
                    <button
                      className="flex items-center"
                      onClick={showFilterDropdown2}
                    >
                      <FilterIcon />
                      <span className="text-sm">Filter</span>
                    </button>
                    {filterDropdown2 && (
                      <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                        <span
                          onClick={hideFilterDropdown2}
                          className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm"
                        >
                          close
                        </span>
                        {/* <h5 className="font-semibold">Status</h5>
                                                        <input value={value} placeholder="Search by reference ID" onChange={(e)=>setValue(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" /> */}

                        <h5 className="font-semibold mt-2">Date Range</h5>
                        <div className="flex items-center gap-4">
                          <input
                            onChange={(e) => setStartDate(e.target.value)}
                            className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                            type="date"
                          />
                        </div>

                        {/* <h5 className="font-semibold mt-2">Subscription Status</h5>
                                                        <div className="flex items-center gap-4">
                                                            <select type="" onChange={(e)=>setStatus(e.target.value)} className="w-full h-10 rounded border border-gray-200">
                                                            <option value="">Choose status</option>
                                                                <option value="abandoned">Abandoned</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="failed">Failed</option>
                                                                <option value="successful">Successful</option>
                                                            </select>    
                                                        </div> */}

                        <div className="flex items-center gap-4 mt-5">
                          <button
                            onClick={ClearFilter2}
                            className="py-3 w-full text-xs rounded shadow"
                          >
                            Clear filter
                          </button>
                          <button
                            onClick={() => FilterDataById2(value)}
                            className="py-3 w-full rounded bg-primary-theme text-white text-xs"
                          >
                            Apply filter
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="1/5 flex gap-1 ...">
                    <input
                      onChange={(e) => FilterDataById2(e.target.value)}
                      placeholder="Search by plan ID"
                      className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                    />
                    <button className="bg-primary-theme py-2 px-5 text-white text-sm">
                      Search
                    </button>
                  </div>
                </div>
                <button
                  onClick={showCreatePlanOverlay}
                  className="flex shadow bg-primary-theme text-white gap-2 h-10 items-center text-sm px-5"
                >
                  Create New Plan
                </button>
              </div>
              <Table
                height="430px"
                tableColumnStructure={tableColumnStructure2}
                tableRowData={allFilteredData2}
                pageName="Plan"
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default CustomerInner;
