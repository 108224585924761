import React from 'react';
import Select from 'react-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function UserInviteForm({
    handleInviteInputChange,
    handleInviteSelectChange,
    availableRoles,
    sendInviteHandler,
    invitePayload,
    setInvitePayload
}) {
    return (
        <>
        <h5 className="font-semibold text-center text-xl">Invite Team Member</h5>
        <p className="text-sm text-center text-gray-400 w-3/5 mx-auto">Please enter  email to invite team members to have access to your dashboard</p>

        <div className="px-12">
            <input value={invitePayload?.email} name="email" onChange={handleInviteInputChange} type="email" placeholder="Email address" className="w-full h-12 my-3 border border-gray-300 rounded px-3 text-md"/>

            <input value={invitePayload?.fullName} name="fullName" onChange={handleInviteInputChange} type="text" placeholder="Fullname" className="w-full h-12 my-3 border border-gray-300 rounded px-3 text-md"/>

            <Select value={availableRoles?.filter(availableRole => availableRole.value === invitePayload?.customRole)} name="customRole" onChange={(e)=>handleInviteSelectChange(e, "customRole")} className="w-full h-12 my-3 rounded text-md" placeholder={<p>Select Role</p>} options={availableRoles} />

            {/* <div className="flex gap-2 items-center">
                 <FormControlLabel control={<Checkbox
                    checked={"checked"}
                    onChange={"handleInviteInputChange"}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />} label="The team member is a developer" />
                
            </div> */}

            <button onClick={sendInviteHandler} className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded">Invite Team Member</button>
        </div>
    </>
  )
}

export default UserInviteForm
