import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import AgDashboardInner from '../components/AgDashboardInner';
import AgCommission from '../components/AgCommission';

function AGDashboard() {
    const [sidebarState, setSidebarState] = useState("hidden");
    const [isUsers, setIsusers] = useState(true);
    const toggleSidebar = () => {
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }
    return (
        <div className="body-container">
            <Sidebar toggleSidebar={toggleSidebar} sidebarState={sidebarState}/>
            <div className="relative">
                <Navbar pageName="Transaction" toggleSidebar={toggleSidebar}/>
                <div className="1/5 mx-5 mt-5 flex gap-1 ...">
                  <button
                    onClick={() => setIsusers(true)}
                    className={isUsers ? `bg-primary-theme py-2 px-5 text-white text-sm`: `border px-5 py-2`}>
                    Onboarded Agents
                  </button>
                  <button
                    onClick={() => setIsusers(false)}
                    className={!isUsers ? `bg-primary-theme py-2 px-5 text-white text-sm`: `border px-5 py-2`}>
                    Commission Earned
                  </button>
                </div>
                {isUsers ? (
                  <AgDashboardInner />
                ) : (
                  <AgCommission />
                )}
            </div>
        </div>
    )
}

export default AGDashboard;
