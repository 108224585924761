

import {httpGet} from "../https";
import {identityService, paymentGateway} from "../index";

export const getAllCustomers = () => {
    return httpGet(`${identityService}/webpos/customer/filter-search?size=1000000&order=ASC`);
}

export const getCustomerStats = () => {
    return httpGet(`${identityService}/webpos/customer/fetch/stats`);
}

export const getEachCustomerStats = (customerId) => {
    return httpGet(`${paymentGateway}/transactions/${customerId}`)
}

export const getCustomer = (customerId) => {
    return httpGet(`${identityService}/webpos/customer/${customerId}`)
}

