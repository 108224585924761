import React,{useState,useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {getAllPermissions} from "../../services/requests/roles.js";

function PermissionCheckbox({permissions, setPermissions}) {
    const [_permissions, _setPermissions] = useState([]);

    useEffect(()=>{
        AllPermissions();
    },[]);
    const AllPermissions = async() => {
        const response = await getAllPermissions();
        const filteredResponse = response?.map(({id, name, description})=>({
            id, name, description, status: false
        }));
        _setPermissions(filteredResponse);
    }
    const handleInviteInputChange = (name) => {
        
        const updatedPermissions = _permissions?.map(permission=> {
            if(permission.name===name){
                return {
                    ...permission,
                    status: !permission.status
                }
            }else{
                return permission;
            }
        });
        _setPermissions(updatedPermissions);

        let newPermissions = [];
        updatedPermissions.forEach(permission=> {
            if(permission.status){
                newPermissions.push({
                    id: permission.id,
                    name: permission.name,
                    description: permission.description
                });
            }
        })
        setPermissions(newPermissions);
    }
  return (
    <div className="flex gap-2 items-center flex-wrap h-32 overflow-y-scroll">
        {
            _permissions.map(({id, name, status})=> (
                <FormControlLabel control={<Checkbox
                    checked={status}
                    onChange={()=>handleInviteInputChange(name)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />} label={name} />
                    ))
        }
    </div>
  )
}

export default PermissionCheckbox
