import {httpGet, httpPut} from "../https";
import {notificationService} from "../index";

export const getNotifications = (userId) => {
    return httpGet(`${notificationService}/in-app-notifications/${userId}?page=0`);
}

export const updateNotificationStatus = ({userId, notificationId, read}) => {
    return httpPut(`${notificationService}/in-app-notifications/${userId}/${notificationId}`, {
        read: read
    });
}

