import React from 'react';
import config from "../config.js";
import { SetUpIcon, ApiDocIcon, DashboardGuideIcon, FAQicon, FeedbackIcon, WhatsappIcon } from '../assets';

function SupportInner() {
    return (
        <div className="support-container relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 p-5 w-full bg-red-50">
            <div className="w-full h-64 bg-white rounded-xl flex flex-col items-center py-8">
                <SetUpIcon/>
                <h4 className="text-xl font-semibold mt-3">{"Setup Guide"}</h4>
                <p className="text-sm px-10 text-center mt-2">{"Learn how to build amazing things with the Wayapaychat API"}</p>

                <a href={`${config?.WEBSITE_URL2}/get-started`} target="_blank">
                    <button className="bg-primary-theme py-2 px-6 text-white text-sm mt-3 rounded">{"SHOW ME"}</button>
                </a>
            </div>
            <div className="w-full h-64 bg-white rounded-xl flex flex-col items-center py-8">
                <ApiDocIcon />
                <h4 className="text-xl font-semibold mt-3">{"API Documentation"}</h4>
                <p className="text-sm px-10 text-center mt-2">{"Learn how to integrate Wayapaychat with your business"}</p>

                <a href={`${config?.WEBSITE_URL2}/get-started`} target="_blank">
                    <button className="bg-primary-theme py-2 px-6 text-white text-sm mt-3 rounded">{"VIEW DOC"}</button>
                </a>
            </div>
            <div className="w-full h-64 bg-white rounded-xl flex flex-col items-center py-8">
                <DashboardGuideIcon />
                <h4 className="text-xl font-semibold mt-3">{"Contact us"}</h4>
                <p className="text-sm px-10 text-center mt-2">{"Contact us"}</p>

                <a href={`${config?.WEBSITE_URL2}/contact`} target="_blank">
                    <button className="bg-primary-theme py-2 px-6 text-white text-sm mt-3 rounded">{"EXPLORE"}</button>
                </a>
            </div>

            <a href={"https://wa.me/+2348066252349?text=Hello%20WayapayAdmin%20I%20have%20a%20question%20for%20you%20."} target="_blank" className="w-12 h-12 rounded-full fixed bottom-16 right-16 px-2 flex items-center justify-center shadow">
            <span className="w-full h-full absolute rounded-full border border-gray-500 animate-ping"></span>
            <WhatsappIcon />
            </a>
        </div>
    )
}

export default SupportInner
