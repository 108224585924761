import React from 'react'

const Loader = () => {
    return (
        <div className="h-6 w-6 rounded-full animate-spin" style={{border: "3px solid #ffffff",borderRight: "3px solid transparent"}}></div>
    )
}
function Button({ text, nextHandlerTo, id, actionHandler, loading }) {
    return ( 
        <div className="px-0 pt-5 pb-6 w-full ...">
            {
                actionHandler
                    ?
                    <button onClick={() => !loading ? actionHandler() : ''} className="bg-primary-theme text-white text-sm rounded h-12 w-full flex items-center justify-center">{loading ? <Loader /> : text}</button>
                    :
                    nextHandlerTo
                        ?
                        <button onClick={!loading ? nextHandlerTo : ''} className="bg-primary-theme text-white text-sm rounded py-4 w-full flex items-center justify-center">{loading ? <Loader /> : text}</button>
                        :
                        <button className="bg-primary-theme text-white text-sm rounded py-4 w-full flex items-center justify-center">{loading ? <Loader /> : text}</button>
            }
        </div>
    )
}

export default Button
