import React, {useState, useEffect, useRef} from 'react';
import { AndroidIcon, IOSIcon, NotificationBellIcon } from '../assets';
import Spinner from "../assets/spinner.png";
import Grid from '@mui/material/Grid';
import {Link} from "react-router-dom";
import axios from "axios";
import FetchApi from '../utils/FetchApi';
import {getNotifications, updateNotificationStatus} from "../services/requests/notification";
import { toast } from 'react-toastify';

const styles = {
    notification_details: {
        fontSize: "13px",
        display: 'flex',
        justifyContent: "space-between",
        color: "#FF6700",
        marginTop: "8px",
    }
}
function NotificationInner({ setIsUnreadMsgExist }) {
    const notificationContainerRef = useRef(null);
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [recentlyUpdatedObject, setRecentlyUpdatedObject] = useState([]);
    const [notifications, setNotifications] = useState([]);

    useEffect(()=>{
        fetchNotification(); 
    },[]);
    
    function isMessageMovedToViewport(element, id) {
        const elementTop = element?.getBoundingClientRect().top;
        const viewHeight = window.innerHeight;
        if(elementTop + 32 < viewHeight && elementTop > 32 && parseInt(elementTop%75) < 2){
            // if the element enter viewport && element has not entered the top screen && element will update it status in (screenheight / 75) times
            // Unread messages that are in the viewport
            const userId = JSON.parse(sessionStorage.getItem("userData")).id;
            const payload = {
                userId: userId, 
                notificationId: id,
                read: true
            }
            updateNotificationMsgStatus(payload);
        }
    }
    const fetchNotification = async () => {
        const userId = JSON.parse(window.sessionStorage.getItem("userData")).id;
        const response =  await getNotifications(userId);
        if(response.status){
            const notifications = [];
            const _unreadNotifications = [];
            response.data.notifications.forEach((element, index) => {
                notifications.push({
                    id: element.notificationId,
                    elementId: "message"+index,
                    title: element.category,
                    description: element.content,
                    date: Date(element.dateSent).split("GMT")[0],
                    seen: element.read
                });
                if(!element.read){
                    // If we have unread messages
                    setIsUnreadMsgExist(true); 
                    // ************
                    _unreadNotifications.push({
                        id: element.notificationId,
                        elementId: "message"+index,
                        title: element.category,
                        description: element.content,
                        date: Date(element.dateSent).split("GMT")[0],
                        seen: element.read
                    })
                }
                // setPageRendered(true);
            });
            setUnreadNotifications(_unreadNotifications);
            setNotifications(notifications);
        } else {
            console.log('error', response);
            // toast.error('error');
        }
        
    }

    const updateNotificationMsgStatus = async(payload) => {
        const updateResponse = await updateNotificationStatus(payload);
    }

    const checkAllNotificationStatus = () => {
        unreadNotifications.forEach(({id, elementId, seen}) => {
            if(!seen){
                const element = document.querySelector("#"+elementId);
                isMessageMovedToViewport(element, id);
            }
        });
    }
            
    useEffect(()=>{
        setTimeout(() => {
            checkAllNotificationStatus();
            // Add the same function to onrendered and onscroll
            notificationContainerRef.current.onscroll = () => {
                checkAllNotificationStatus();
            }
        }, 2000);
    },[unreadNotifications]);

    return (
        <>
            <Grid spacing={2} container className="px-6 pt-5">
                <Grid item xs={12} sm={12} md={5} style={{height: "calc(100vh - 75px)"}} 
                ref={notificationContainerRef} className="overflow-y-scroll">
                   {
                       notifications.length > 0
                       ?
                       notifications.map(({elementId, title, description, linkText, date, seen})=> (
                           <div id={elementId} className="border-r border-gray-300 h-100 pr-20 pt-10">
                                <div className="border-b border-gray-200 pb-5">
                                    <h5 className="title flex items-center font-semibold text-lg gap-3">
                                        <NotificationBellIcon />
                                        {title}
                                    </h5>
                                    <p className="description text-md mt-1"> {description} <Link to="" className="text-primary-theme">{linkText}</Link>
                                    </p>
                                    <p style={styles.notification_details}>
                                        {date}
                                        <span className="text-gray-500">{seen ? "seen" : "unseen"}</span>
                                    </p>
                                </div>
                            </div>
                       ))
                       :
                        <div className="flex items-center gap-2">
                            {/*<img src={Spinner} className="w-6 h-6 animate-spin"/>
                            <p className="text-sm">Fetching notifications...</p>
                       */}
                       </div>
                   }
                   
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                    <div className="flex justify-end">
                        <div className="w-1/2 lg:w-3/4 mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50 ...">
                            <button className="absolute -right-3 -top-3">
                                {/* <CloseIcon /> */}
                            </button>
                            <h5 className="text-xl font-semibold">Download our App</h5>
                            <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                            <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
                                <div className="flex items-center flex-col sm:flex-row gap-2">
                                    <button className="mx-2"><AndroidIcon /></button>
                                    <button className="mx-2"><IOSIcon /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
           
        </>
    )
}

export default NotificationInner

