import React from 'react';
import {Link} from 'react-router-dom';

function InnerOption({ title, link }) {
    return (
        <Link to={`${link}`} className="w-full h-12 relative rounded shadow flex flex-col justify-center px-3 mb-3">
            <h5 className="font-medium text-sm">{title}</h5>

            <svg className="absolute right-3 top-4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z" fill="#1C1C1C" />
            </svg>
        </Link>
    )
}

export default InnerOption
