import React from 'react';
import {TransparencyOption} from './Option';

function TransparencyTab() {
    return (
        <>
            <h4 className="mt-5 mb-3">TransparencyTab</h4>
            <TransparencyOption title="Terms & Condition" subtitle="Read about our terms & conditions" link="https://wayaquick.com/compliance"/>
            <TransparencyOption title="Privacy Policy" subtitle="Read about our privacy & policy" link="https://wayaquick.com/privacy"/>
            <TransparencyOption title="About us" subtitle="Who we are" link="https://wayalinks.com/about"/>
        </>
    )
}

export default TransparencyTab
