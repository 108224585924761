import React, { useState, useRef, useContext, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FAB from '../../components/FAB.jsx';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { WayaPayLogo1, Icon, FailedIcon, CloseIcon, Loader } from '../../assets/index.js';
import { Modal } from '@mui/material'
import axios from 'axios';
import config from '../../config.js';
import OtpInput from '../../components/OtpInput.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AuthContext} from "../../context/AuthContext";
import { authOTP } from "../../services/requests/auth";
import newLogo  from '../../assets/newLogo.png'
import { decrypt } from '../../utils/helpers.js';

const AuthLogin = ({props}) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [status, setStatus] = useContext(AuthContext);
    const [otpModal, setOtpModal] = useState(false);
    const [otp, setOTP] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        boxShadow: 24,
        p: 4,
      };
      const { from } = useParams();
    const loginHandler = () => {
        
        setLoading(true);
       
        const iv = new URLSearchParams(window.location?.href).get('iv');
        const ecncryptedToken = new URLSearchParams(window.location?.href).get('token');
        const userId = new URLSearchParams(window.location?.href).get('userId');

        const token = decrypt({ iv, encryptedData: ecncryptedToken });
        if (token && userId) {
          let session = sessionStorage;
          session.setItem("token", token);
          const configuration = {
            timeout: config.REQUEST_TIMEOUT,
            headers: {
              'content-type': 'application/json',
              authorization: token,
              'CLIENT-ID': 'WAYAQUICK',
              'CLIENT-TYPE': 'CORPORATE',
              
            },
          };  
         axios.post(`${config.BASE_URL_AUTH}/auth-service/api/v1/auth/validate-user`, {}, configuration).then(response=> {
            axios.post(`${config.BASE_URL_AUTH}/identity-manager-service/api/v1/waya-merchant/init/${userId}`, configuration).then(resp2=> {
                if(resp2?.data?.data?.merchantId){
                    setLoading(false);
                    sessionStorage.setItem("isLoggedin", true);
                    session.setItem("token", JSON.stringify(token));
                    session.setItem("userData", JSON.stringify(response?.data?.data));
                    session.setItem("merchantId", JSON.stringify(resp2?.data?.data?.merchantId));
                    session.setItem("isCorporate", true);

                    setErrMsg(null);
                    toast("Login successfully.");
                    setStatus(true);
                    navigate("/");
                }else{
                    toast("Merchant ID is empty");
                    setLoading(false);
                }
            });
         })
        }else{
        window.location.href = `/`;
            setErrMsg("Account is not corporate.");
            toast("");
            setLoading(false);
        }
    /*  }else{
        setTabIndex(3);
        setLoading(false);
    } */

    }

    useEffect(() => {
      loginHandler()
      // window.location.href = `${envConfig.websiteAppUrl}/login`;
    }, []);
    return (
        <>
        {isLoading ? <Loader /> : (
        <>
            <React.Fragment>
            <ToastContainer />
                <div className="onboarding-background w-full h-auto flex items-start justify-center">
                    <div className="onboarding-form-container bg-white border-t-4 border-primary-theme pb-12">
                        <div className="flex flex-col items-center pt-10 px-6">
                            <Link to="/">
                                <img src={newLogo} width='150px'/>
                            </Link>

                            <h4 className="title font-semibold text-xl text-gray-500 mt-10 mb-6">Welcome Back</h4>
                        </div>
                        
                    </div>

                    <FAB />
                </div>
            </React.Fragment>
            </>
        )}
        <Modal 
         open={otpModal} 
         onClose={() => setOtpModal(false)}
         aria-labelledby="verify otp modal"
         aria-describedby="input otp from email"
        >
        <div className="input-wrapper px-10 max-w-2xl bg-white" style={style}>
        
            <h2 className="font-bold text-2xl">Enter OTP</h2>
            <Input label="OTP" name="otp" placeholder="Enter OTP" value={otp} onChange={(e)=>setOTP(e.target.value)} notRequired={false} />
            
            <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
            ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

            <Button text="Log In" actionHandler={loginHandler} loading={loading} />
        </div>
       </Modal>
     </>
    )
}

export default AuthLogin;
