import axios from 'axios';

let rootUrl;
// Businesses links
let wayaPay;
let wayaBank;
let wayaGram;
let wayaPos;

// let MODE = 'staging';
let MODE = process.env.REACT_APP_API_MODE;

// Set the value of mode (on production) using the identity service api an store the state in the user browser. If there is not result in browser storage do api call and store the right mode. (Incase intruder edit the state in the browser manually, ....);

// the data to store in the browser will be encoded. and 2 random integers will be generated and added to front and back of the mode before ecoding. It will also be removed after decoding
const mode = sessionStorage.getItem('mode');
const authorization = JSON.parse(sessionStorage.getItem('token'));
const getToggleStatus = () => {
	const header = {
		headers: {
			authorization: authorization,
			'CLIENT-ID': 'WAYAQUICK',
            'CLIENT-TYPE': 'CORPORATE',
		}
	};
	axios
		.get(
			'https://services.wayapay.ng/identity-manager-service/api/v1/waya-merchant/auth-user/merchant-account',
			header
		)
		.then((response) => {
			// live = '\x96+Þ'
			// staging = '²Ö \x8Ax'
			const _mode = response.data.data.merchantKeyMode === 'PRODUCTION' ? 'live' : 'staging';
			const startInt = Math.floor(Math.random() * 9);
			const endInt = Math.floor(Math.random() * 9);
			const a = JSON.stringify(startInt + _mode + endInt);
			const encryptedMode = window.btoa(a);
			sessionStorage.setItem('mode', encryptedMode);
			window.location.reload();
		});
};

// Only check where to route root address to, after authorization token is found;
if (authorization && false) {
	if (mode) {
		const decriptedMode = JSON.parse(atob(mode));
		const startInt = 1;
		const endInt = decriptedMode.length - 1;
		const currentMode = decriptedMode.substring(startInt, endInt);
		if (currentMode == 'live') {
			MODE = 'live';
		} else if (currentMode == 'staging') {
			MODE = 'staging';
		} else {
			// Reset the value to staging... mostlikely someone manually tamper with the session storage...
			MODE = 'staging';
			const startInt = Math.floor(Math.random() * 9);
			const endInt = Math.floor(Math.random() * 9);
			const a = JSON.stringify(startInt + 'staging' + endInt);
			const encryptedMode = window.btoa(a);
			sessionStorage.setItem('mode', encryptedMode);
		}
	} else {
		getToggleStatus();
	}
}

if (MODE === 'live') {
	rootUrl = 'https://services.wayabank.ng';
	wayaPay = 'https://services.wayapay.ng';
	wayaBank = 'https://services.wayabank.ng';
	wayaGram = 'https://wayagram.ng';
	wayaPos = 'https://wayapos.ng';
} else {
	rootUrl = 'https://services.staging.wayabank.ng';
	wayaPay = 'https://services.staging.wayapay.ng';
	wayaBank = 'https://services.staging.wayabank.ng';
	wayaGram = 'https://staging.wayagram.ng';
	wayaPos = 'https://staging.wayapos.ng';
}

const authService = `${rootUrl}/auth-service`;
const paymentGateway = `${rootUrl}/payment-gateway/api/v1`;
const identityService = `${rootUrl}/identity-manager-service/api/v1`;
const disputeService = `${rootUrl}/dispute-service/api/v2/dispute`;
const ussdService = `${rootUrl}/ussd-service`;
const cardService = `${rootUrl}/card-service`;
const referralService = `${rootUrl}/referral-service`;
const bankReferral = `${wayaPay}/payment-gateway/api/v1`;
const roleService = `${rootUrl}/wayapay-role-access`;
const notificationService = `${rootUrl}/wayapay-notification-api`;
const activityLogService = `${rootUrl}/activity-logservice`
const settlementService = `${wayaPay}/wayapay-settlement-service/api/v1`

export { authService, bankReferral, paymentGateway,roleService, identityService, disputeService, ussdService, referralService, cardService, notificationService, activityLogService, settlementService};
