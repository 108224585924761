


import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom';
import FAB from '../../components/FAB.jsx';
import axios from 'axios';
import Input from '../../components/Input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import Button from '../../components/Button';
import OtpInput from '../../components/OtpInput.jsx';
import config from '../../config.js';
import { Icon, WayaPayLogo1, Loader, BackIcon } from '../../assets/index.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import newLogo from '../../assets/newLogo.png'


const genderOptions = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
]

function Register() {
    const navigate = useNavigate();
    const loader = useRef(null);
    const [tabIndex, setTabIndex] = useState(1);

    const [otp1, setOTP1] = useState(null);
    const [otp2, setOTP2] = useState(null);
    const [otp3, setOTP3] = useState(null);
    const [otp4, setOTP4] = useState(null);
    const [otp5, setOTP5] = useState(null);
    const [otp6, setOTP6] = useState(null);
    const [formTitle, setFormTitle] = useState("Create your WayaQuick account");
    const [orgName, setOrgName] = useState(null);
    const [orgEmail, setOrgEmail] = useState(null);
    const [orgTel, setOrgTel] = useState(null);
    const [businessList, setBusinessList] = useState(null);
    const [loading, setLoading] = useState(false);

    // const [orgDOB, setOrgDOB] = useState(null);
    // const [orgType, setOrgType] = useState(null);
    const [businessType, setBusinessType] = useState(null);
    const [surname, setSurname] = useState(null);
    const [firstname, setFirstname] = useState(null);
    const [email, setEmail] = useState(null);
    const [gender, setGender] = useState("FEMALE");
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [officeAddr, setOfficeAddr] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [referalCode, setReferalCode] = useState(null);
    const [password1, setPassword1] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [indicatorBinary, setIndicatorBinary] = useState(null);
    const [consent1, setConsent1] = useState(false);
    const [ isCac, setIsCac] = useState(false);
    const [cacRegNo, setCacRegNo] = useState(null);
    const [regDate, setRegDate] = useState(null);
    const [dob, setDob] = useState(null); 
    const [companyType, setCompanyType] = useState("BN"); 
    const [spinner, setSpinner] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // const [consent2, setConsent2] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const isCacOption = [ 
        { label: "Registered Business", value: true },
        { label: "Non Registered Business", value: false },
    ];
    const compType = [ 
        { label: "BN", value: "BN" },
        { label: "RC", value: "RC" },
        { label: "IT", value: "IT" },

    ];
    ///kyc-servicev2/no-auth/verifyCacRegNo2/CAC?companyType=RC&regNo=222

    const validateCAC = async () => {
        if (!cacRegNo?.length) return;
        setSpinner(true)
        const endpoint = `${config.BASE_WAYA}/kyc-servicev2/no-auth/verifyCacRegNo2/CAC?companyType=${companyType}&regNo=${cacRegNo}`
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        axios.post(endpoint, configuration).then(response => {
            setSpinner(false);
            toast(response?.data?.message);
            setOrgName(response?.data?.data?.name);
            setOfficeAddr(response?.data?.data?.address);
            const tel = response?.data?.data?.localNumber
            const newTel = tel.indexOf('0') == 0 ? '234'+tel.substring(1) : tel;
            setOrgTel(newTel);
            setRegDate(moment(response?.data?.data?.createdAt).format('YYYY-MM-DD'))
            //  console.log('BN', response?.data?.data?.name);

            const buss =  response?.data
            //  console.log(buss);
        }).catch(err=>{
            setSpinner(false);
            toast(err?.response?.data?.message);
        })
    }
    useEffect(() => {
        const API_ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/business/type/find/all?size=1000`;
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        axios.get(API_ENDPOINT, configuration).then(response => {
            toast("Business type fetched");
            const buss =  response?.data?.businessTypeList.map(elem => (
                { value: elem?.businessType, label: elem.businessType }
            ));
            setBusinessList(buss);
        })
    }, [])

    const SetOrgName = (e) => {
        setOrgName(e.target.value);
    }
    const SetRegDate = (e) => {
        setRegDate(moment(e.target.value).format('YYYY-MM-DD'));
    }
    const SetCACRegNo = (e) => {
        setCacRegNo(e.target.value);
    }
    const SetIsCac = (cacOption) => {
        setOrgName(null);
        setRegDate(null);
        setCacRegNo(null);
        setOfficeAddr(null);
        setOrgTel('+234');
        setIsCac(cacOption.value);
    }
    const SetCompanyType = (e) => {
        setCompanyType(e.value);
    }
    const SetOrgEmail = (e) => {
        setOrgEmail(e.target.value);
    }
    const SetOrgTel = (number) => {
        setOrgTel(number);
    }
    // const SetOrgDOB = (e) => {
    //     setOrgDOB("00/00/0000");
    // }
    // const SetOrgType = (e) => {
    //     setOrgType(e.target.value);
    // }
    // const SetBusinessType = (e) => {
    //     setBusinessType(e.target.value);
    // }
    const SetSurname = (e) => {
        setSurname(e.target.value);
    }
    const SetFirstname = (e) => {
        setFirstname(e.target.value);
    }
    const SetEmail = (e) => {
        setEmail(e.target.value);
    }
    const SetGender = (genderOpt) => {
        setGender(genderOpt.value);
    }
    const SetDob = (e) => {
        setDob(moment(e.target.value).format('YYYY-MM-DD'));
    }
    const SetState = (e) => {
        setState(e.target.value);
    }
    const SetCity = (e) => {
        setCity(e.target.value);
    }
    const SetOfficeAddr = (e) => {
        setOfficeAddr(e.target.value);
    }
    const SetReferalCode = (e) => {
        setReferalCode(e.target.value);
    }
    const SetPhoneNumber = (tel) => {
        setPhoneNumber(tel);
    }
    const SetPassword1 = (e) => {
        setPassword1(e.target.value);
    }
    const SetPassword2 = (e) => {
        setPassword2(e.target.value);
    }

    const nextHandlerTo = () => {
        if (tabIndex === 1) {

            validateOrgInfo();
        } else if (tabIndex === 2) {
            validatePersonalInfo();
        }
        else if (tabIndex === 3) {
            validatePwd();
        }
    }

    const prevHandlerTo = () => {
        if (tabIndex > 1) {
            setTabIndex(tabIndex - 1);
            setErrMsg(null);
        }
    }

    function isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123) && (code !== 32 && code !== 45)) { // lower alpha (a-z)
            return false;
            }
        }
        return true;
    };

    function isAlpha(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123) && (code !== 32 && code !== 45)) { // lower alpha (a-z)
            return false;
            }
        }
        return true;
    };

    // function isNotOnlyNumeric(str) {
    //     var code, i, len;

    //     for (i = 0, len = str.length; i < len; i++) {
    //         code = str.charCodeAt(i);
    //         if ((code > 64 && code < 91) && // upper alpha (A-Z)
    //             (code > 96 && code < 123)) { // lower alpha (a-z)
    //             return false;
    //         }
    //     }
    //     return true;
    // };

    function isOnlyNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 47 && code < 58)) { 
                return false;
            }
        }
        return true;
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };


    const validateOrgInfo = () => {
        setLoading(true);
        if (orgName && orgEmail && businessType) {
            if(isAlphaNumeric(orgName)){
                if(!isOnlyNumeric(orgName)){
                    if((validateEmail(orgEmail))){
                        if(orgTel){
                            if(orgTel?.length === 13){
                                setErrMsg(null);
                                setLoading(false);
                                setTabIndex(2);
                             }else{
                                setLoading(false);
                                setErrMsg("Your mobile number is incorrect");
                            }
                        }else{
                            setLoading(false);
                            setErrMsg("Your mobile number cannot be null");
                        }
                    }else{
                        setLoading(false);
                        setErrMsg("Your email is invalid");
                    }                    
                }else{
                    setLoading(false);
                    setErrMsg("Organisation name cannot be only number");
                }
            }else{
                setLoading(false);
                setErrMsg("Organisation name can only be Alpha numeric");
            }
        } else {
            toast("All fields are required!");
            setLoading(false);
            setErrMsg("All the fields here are required. Kindly fill all to continue to the next tab.");
        }
    }
    const validatePersonalInfo = () => {
        setLoading(true);
        if (surname && firstname && email && gender && state && city && officeAddr) {
            if(isAlpha(surname)){
                // if(!isOnlyNumeric(surname)){
                    if(isAlpha(firstname)){
                        // if(!isOnlyNumeric(firstname)){
                            if(validateEmail(email)){
                                if(phoneNumber?.length === 13){
                                    if(isAlpha(state)){
                                        // if(!isOnlyNumeric(state)){
                                            if(isAlpha(city)){
                                                // if(!isOnlyNumeric(city)){
                                                    // if(isAlphaNumeric(officeAddr)){
                                                        if(!isOnlyNumeric(officeAddr)){
                                                            setErrMsg(null);
                                                            setLoading(false);
                                                            setTabIndex(3);
                                                        }else{
                                                            setLoading(false);
                                                            setErrMsg("Office Address cannot be only numeric");
                                                        }
                                                    // }else{
                                                    //     setLoading(false);
                                                    //     setErrMsg("Office Address can only be Alpha numeric");
                                                    // }
                                                // }else{
                                                //     setLoading(false);
                                                //     setErrMsg("City cannot be only numeric");
                                                // }
                                            }else{
                                                setLoading(false);
                                                setErrMsg("City can only be Alphabet");
                                            }
                                        // }else{
                                        //     setLoading(false);
                                        //     setErrMsg("State cannot be only numeric");
                                        // }
                                    }else{
                                        setLoading(false);
                                        setErrMsg("State can only be Alphabet");
                                    }
                                }else{
                                    setLoading(false);
                                    setErrMsg("Your mobile number is incorrect");
                                }
                            }else{
                                setLoading(false);
                                setErrMsg("Invalid email address")
                            }
                        // }else{
                        //     setLoading(false);
                        //     setErrMsg("First name cannot be only numeric");
                        // }
                    }else{
                        setLoading(false);
                        setErrMsg("First name can only be Alphabet");
                    }
                // }else{
                //     setLoading(false);
                //     setErrMsg("Surname cannot be only numeric");
                // }
            }else{
                setLoading(false);
                setErrMsg("Surname can only be Alphabet");
            }
        
        } else {
            toast("All fields are required!");
            setLoading(false);
            setErrMsg("All the fields here are required. Kindly fill all to continue to the next tab.");
        }
    }
    const validatePwd = () => {
        setLoading(true);
        if (password1 && password2) {
            if (consent1) {
                if (password1 === password2) {
                    const pwd_regex_alpha1 = /[a-z]/;
                    const pwd_regex_alpha2 = /[A-Z]/;
                    const pwd_regex_digit = /[0-9]/;
                    const pwd_regex_char = /[!@#$%^&*]/;
                    if (password1.length < 8) {
                        const error = "Your password must be at least 8 characters";
                        toast(error);
                        setErrMsg(error);
                        setLoading(false);
                    } else if (password1.search(pwd_regex_alpha1) < 0) {
                        const error = "Your password must contain at least one letter.";
                        toast(error);
                        setErrMsg(error);
                        setLoading(false)
                    } else if (password1.search(pwd_regex_alpha2) < 0) {
                        const error = "Your password must contain at least one capital letter.";
                        toast(error);
                        setErrMsg(error);
                        setLoading(false)
                    } else if (password1.search(pwd_regex_digit) < 0) {
                        const error = "Your password must contain at least one digit.";
                        toast(error);
                        setErrMsg(error);
                        setLoading(false)
                    } else if (password1.search(pwd_regex_char) < 0) {
                        const error = "Your password must contain at least one special character.";
                        toast(error);
                        setErrMsg(error);
                        setLoading(false)
                    } else {
                        setErrMsg(null);
                        setFormTitle("Verify Your Account");
                        setTabIndex(4);
                        setLoading(false)
                        // Call the register API here
                    }
                } else {
                    setErrMsg("Password must contain at least 8 characters, an uppercase letter, a lowercase letter, a number and a special character.");
                    setLoading(false);
                    toast("Password not validated")
                }
            } else {
                setErrMsg("Check the boxes below to accept our terms and conditions to continue.");
                setLoading(false);
                toast("Agree to our T & C")
            }
        } else {
            setErrMsg("All the fields here are required. Kindly fill all to continue.");
            setLoading(false);
            toast("All fields required!")
        }
    }
    useEffect(() => {
        //  window.location.href='https://staging.wayabank.ng/login?client=wayaquick'
        window.location.href=`${config?.BANK_WEB_URL}/signup?client=wayaquick`
        // 'http://localhost:3001/login?client=wayaquick'
    }, []);
    useEffect(() => {
        if (tabIndex === 3) {
            if (password1 && password1 === password2) {
                const pwd_regex_alpha1 = /[a-z]/;
                const pwd_regex_alpha2 = /[A-Z]/;
                const pwd_regex_digit = /[0-9]/;
                const pwd_regex_char = /[!@#$%^&*]/;
                if (password1.length < 8) {
                    setIndicatorBinary([0, 0, 0, 0, 0]);
                    setErrMsg("Your password must be at least 8 characters");
                } else if (password1.search(pwd_regex_alpha1) < 0) {
                    setIndicatorBinary([1, 0, 0, 0, 0]);
                    setErrMsg("Your password must contain at least one letter.");
                } else if (password1.search(pwd_regex_alpha2) < 0) {
                    setIndicatorBinary([1, 1, 0, 0, 0]);
                    setErrMsg("Your password must contain at least one capital letter.");
                } else if (password1.search(pwd_regex_digit) < 0) {
                    setIndicatorBinary([1, 1, 1, 0, 0]);
                    setErrMsg("Your password must contain at least one digit.");
                } else if (password1.search(pwd_regex_char) < 0) {
                    setIndicatorBinary([1, 1, 1, 1, 0]);
                    setErrMsg("Your password must contain at least one special character.");
                } else {
                    setIndicatorBinary([1, 1, 1, 1, 1]);
                    setErrMsg(null)
                }
            } else {
                setIndicatorBinary([0, 0, 0, 0, 0]);
                setErrMsg("The two password fields must match. Kindly check and try again.");
            }
        }
    }, [password1, password2, tabIndex])

    const createAccount = () => {
        setLoading(true);
        const registerPayload = {
            admin: false,
            businessType: businessType.value,
            orgType: businessType.value,
            dateOfBirth: "2022-02-10",
            city: city,
            email: email?.toLowerCase(),
            gender: gender,
            firstName: firstname,
            officeAddress: officeAddr,
            orgEmail: orgEmail?.toLowerCase(),
            orgName: orgName,
            orgPhone: orgTel,
            password: password1,
            phoneNumber: phoneNumber,
            referenceCode: referalCode,
            state: state,
            surname: surname
        }

        const payload = {
                "businessRegDate": regDate,
                "cacAccount": isCac,
                "cacRegNo": cacRegNo,
                "corporate": true,
                "dateOfBirth": dob,
                "email": email?.toLowerCase(),
                "firstName": firstname,
                "gender": gender,
                "id": 0,
                "otherDetails": {
                  "businessType": businessType.value,
                  "organisationEmail": orgEmail?.toLowerCase(),
                  "organisationName": orgName,
                  "organisationPhone": orgTel,
                  "organizationAddress": officeAddr,
                  "organizationCity": city,
                  "organizationState": state
                },
                "password": password1,
                "phoneNumber": phoneNumber,
                "referenceCode": referalCode,
                "referral": null,
                "smsAlertConfig": true,
                "surname": surname,
                "userId": 0
              
        }

        let errorState = false;
        for (const value in payload) {
            if (value === "") {
                errorState = true;
            }
        }

        if (!errorState && consent1) {
            if(password1 && password2){
                const API_ENDPOINT = `${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/initiate`;
                //const API_ENDPOINT1 = `${config.WAYAPOS_URL}/agency-service/api/v1/auth/merchant/registration`;
                const configuration = {
                    timeout: config.REQUEST_TIMEOUT
                }
                axios.post(API_ENDPOINT, payload, configuration).then(response => {
                    //axios.post(API_ENDPOINT1, registerPayload, configuration) 
                    nextHandlerTo(4);
                    setLoading(false);
                    let session = sessionStorage;
                    session.setItem("regData", JSON.stringify(response.data));
                    setErrMsg(null);
                }).catch(err => {
                    if(Array.isArray(err.response.data.data)){
                        setErrMsg(err.response.data.data.join('. '));
                        setLoading(false);
                    }
                    if(err.toJSON().message === 'Network Error'){
                        toast("Check your network connection");
                        setLoading(false);
                        setErrMsg("Check your network and retry.");
                    }
                    if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                        setErrMsg("Service timeout. Please retry");
                        toast("Error: Service timeout. Please retry");
                        setLoading(false)
                    }else{
                        setErrMsg(err.response.data.message);
                        toast(err.response.data.message);
                        setLoading(false);
                    }
                });
            }else{
                setErrMsg("Make sure you fill all fields. You omitted certain field.");
                setLoading(false);
            }
        } else if (!errorState) {
            setErrMsg("Error occured. Kindly agree to the checkbox below.");
            toast("Agree to our T & C");
            setLoading(false);
        } else {
            setErrMsg("Make sure you fill all fields. You omitted certain field.");
            setLoading(false);
            toast("All field required!");
        }
    }

    const ResendOTP = () => {
        loader.current.classList.remove("hidden");
        const API_ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/auth/resend-otp/signup/${email}`;
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        axios.get(API_ENDPOINT, configuration).then(response => {
            toast("OTP resent!");
            setErrMsg(null);
            loader.current.classList.add("hidden");
        }).catch(err => {
            if(Array.isArray(err.response.data.data)){
                toast("OTP resent failed!");
                setErrMsg(err.response.data.data.join('. '));
                setLoading(false);
            }
            if(err.toJSON().message === 'Network Error'){
                toast("Check your network connection");
                setLoading(false);
                setErrMsg("Check your network and retry.");
            }
            if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                setErrMsg("Service timeout. Please retry");
                toast("Error: Service timeout. Please retry");
                setLoading(false)
            }else{
                setErrMsg(err.response.data.message);
                toast(err.response.data.message);
                setLoading(false);
            }
        });
    }

    const otpHandler = () => {
        const otp = (otp1 + otp2 + otp3 + otp4 + otp5 + otp6);
        const otpValidateData = {
            otp: JSON.parse(otp),
            phoneOrEmail: email?.toLowerCase()
        }
        
        const API_ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/auth/verify-otp`;
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        axios.post(API_ENDPOINT, otpValidateData, configuration).then(response => {
            let session = sessionStorage;
            session.setItem("userData", JSON.stringify(response.data));
            setErrMsg(null);
            setLoading(false);
            toast("OTP verified!");
            setTimeout(() => {
                navigate("/login");
            }, 2500)
            
        }).catch(err => {
            if(Array.isArray(err.response.data.data)){
                setErrMsg(err.response.data.data.join('. '));
                setLoading(false);
                toast("Error occured!");
            }
            if(err.toJSON().message === 'Network Error'){
                toast("Check your network connection");
                setLoading(false);
                setErrMsg("Check your network and retry.");
            }
            if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                setErrMsg("Service timeout. Please retry");
                toast("Error: Service timeout. Please retry");
                setLoading(false)
            }else{
                setErrMsg(err.response.data.message);
                toast(err.response.data.message);
                setLoading(false);
            }
        });
    }

    return (
        <>
        {isLoading ? ( <Loader />) : (
            <React.Fragment>
                <ToastContainer />
                <div className="onboarding-background w-full h-auto flex items-start justify-center">
                    <div className="onboarding-form-container relative bg-white border-t-4 border-primary-theme pb-12">
                        <div className="flex flex-col items-center pt-10 px-6">
                            <Link to="/"><img src={newLogo} width='200px' /></Link>

                            <h4 className="title font-semibold text-xl text-gray-500 mt-10 mb-6 pt-8">
                                <button onClick={prevHandlerTo} className={`absolute ${tabIndex > 1 ? "flex": "hidden"} left-12 top-12 text-sm gap-2`}><BackIcon /> </button>
                                {formTitle}
                            </h4>
                        </div>
                        <div className="input-wrapper px-10">
                            {
                                tabIndex === 1 && (
                                    <div className=" form-step-1" >
                                        <div className="mt-5">
                                            <label className="text-sm text-gray-600 pl-2">Business Category<span className="text-red-600">*</span> </label>
                                            <Select placeholder={<p>Select category</p>} className="text-sm text-gray-400" options={isCacOption} onChange={(value) => SetIsCac(value)} />
                                        </div>
                                        {isCac && (
                                            <>
                                            <div className="mt-5">
                                                <label className="text-sm text-gray-600 pl-2">Company Type<span className="text-red-600">*</span> </label>
                                                <Select placeholder={<p>Select company type</p>} className="text-sm text-gray-400" options={compType} onChange={(value) => SetCompanyType(value)} />
                                            </div>
                                            <Input name="regNo" spinner={spinner} label="CAC Registration No." placeholder="CAC Reg No." onChange={SetCACRegNo} PointerOut={validateCAC} value={cacRegNo} />
                                            </>
                                        )
                                        }
                                        <Input name="orgName" disabledInput={!isCac ? false : true} label="Business Name" placeholder="Enter Business Name" onChange={SetOrgName} value={orgName} />
                                        <Input name="regDate" disabledInput={!isCac ? false : true} label="Business Registered Date" type="date"  onChange={SetRegDate} value={regDate} />
                                        <Input name="businessEmail" label="Business Email Address" placeholder="Enter Business Email Address" onChange={SetOrgEmail} value={orgEmail} />
                                        <div className="realtive pt-6 flex flex-col">
                                            <label className="text-sm text-gray-600 pl-2">Business Phone Number<span className="text-red-600">*</span></label>
                                            <PhoneInput
                                                onlyCountries={['ng']}
                                                country={'ng'}
                                                value={orgTel}
                                                onChange={phone => SetOrgTel(phone)}
                                                placeholder="+234 814 833 1699"
                                            />
                                            {/* <input value={orgTel} name="orgTel" placeholder="234 814 833 1699" type="number" onChange={(e)=>{SetOrgTel(e.target.value)}  required className="input border border-gray-400 rounded px-2 text-sm w-full py-3" /> */}
                                        </div>
                                        {/* <Input placeholder="Business Type" type="text" onChange={SetOrgType} value={orgType} /> */}
                                        {/* <Input type="date" placeholder="Business Date of birth" onChange={SetOrgDOB} value={orgDOB} /> */}
                                        <div className="mt-5">
                                            <label className="text-sm text-gray-600 pl-2">Business Type<span className="text-red-600">*</span> </label>
                                            <Select placeholder={<p>Select business type</p>} className="text-sm text-gray-400" options={businessList} onChange={(value) => setBusinessType(value)} />
                                        </div>
                                        {/* <Input type="text" placeholder="Business Type" onChange={SetBusinessType} value={businessType} /> */}

                                        <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                                        ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>
                                        <Button text="Next" id="1" nextHandlerTo={nextHandlerTo} loading={loading} />
                                    </div>
                                )
                            }

                            {
                                tabIndex === 2 && (
                                    <div className=" form-step-2">
                                        <Input name="surname" label="Surname" placeholder="Enter Surname" onChange={SetSurname} value={surname} />
                                        <Input name="fname" label="Firstname" placeholder="Enter Firstname" onChange={SetFirstname} value={firstname} />

                                        <div className="mt-5 hidden">
                                            <Select className="text-sm text-gray-400" placeholder={<p className="text-sm">Gender</p>} options={genderOptions} onChange={(value) => SetGender(value)} />
                                        </div>
                                        <Input name="regDate"  label="Date of Birth" type="date" placeholder="Date of Birth" onChange={SetDob} value={dob} />

                                        <Input label="Personal Email" name="email" placeholder="Personal Email" type="email" onChange={SetEmail} value={email} />

                                        <div className="relative pt-6">
                                            <label className="text-sm text-gray-600 pl-2">Phone Number<span className="text-red-600">*</span></label>
                                            <PhoneInput
                                                onlyCountries={['ng']}
                                                country={'ng'}
                                                value={phoneNumber}
                                                onChange={phone => SetPhoneNumber(phone)}
                                                placeholder="+234 814 833 1699"
                                            />
                                        </div>

                                        <Input name="state" label="State" placeholder="Enter State" onChange={SetState} value={state} />

                                        <Input name="city" label="City" placeholder="Enter City" onChange={SetCity} value={city} />

                                        <Input name="office-addr" label="Office Address" placeholder="Enter Office Address" onChange={SetOfficeAddr} value={officeAddr} />

                                        <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                                        ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>
                                        <Button text="Next" id="2" nextHandlerTo={nextHandlerTo} loading={loading} />
                                    </div>
                                )
                            }

                            {
                                tabIndex === 3 && (
                                    <div className=" form-step-3">
                                        <Input label="Referal Code" name="referal-code" placeholder="Enter Referal Code" notRequired={true} onChange={SetReferalCode} value={referalCode} />
                                        <Input label="Password" name="password1" placeholder="Enter Password" type="password" icon={<Icon />} onChange={SetPassword1} value={password1} />
                                        <Input label="Confirm Password" name="password2" placeholder="Enter Confirm Password" type="password" icon={<Icon />} onChange={SetPassword2} value={password2} />
                                        <div className="grid grid-cols-5 gap-4 mt-5">
                                            {
                                                indicatorBinary && indicatorBinary.map((indicator, index) =>
                                                    indicator === 1
                                                        ? <div key={index} className="checked indicator rounded-full h-1 w-full"></div>
                                                        : <div key={index} className="indicator rounded-full h-1 w-full"></div>
                                                )
                                            }
                                        </div>

                                        <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                                        ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

                                        <div className="">
                                        <input name="consent-checkbox" type="checkbox" checked={consent1} onChange={() => setConsent1(!consent1)} />
                                        
                                        <label className="checkbox-wrapper text-gray-500">I consent to the collection and processing of my personal data in line with data regulations as described in the <span className="text-primary-theme text-xs underline"><a href='https://wayaquick.com/privacy' target='_blank'>Wayapay Privacy Policy</a></span>
                                        </label>
                                        </div>

                                        <Button text="Create account" id="4" actionHandler={createAccount} loading={loading}/>

                                        <div className="checkbox-container mt-5">
                                            <label className="checkbox-wrapper text-gray-500">By clicking the “Create your account” button, you agree to <a href='https://wayaquick.com/compliance' className="text-primary-theme text-xs underline" target='_blank'>WayaQuick’s terms of acceptable use.</a>
                                                {/* <input type="checkbox" checked={consent2} onChange={() => setConsent2(!consent2)} />
                                        <span className="checkmark"></span> */}
                                            </label>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                tabIndex === 4 && (
                                    <div className="form-step form-step-4">
                                        <p className="text-gray-500 text-md px-12 text-center mb-6">Please Input the OTP sent to your email address or mobile number.</p>
                                        <OtpInput otp1={otp1} otp2={otp2} otp3={otp3} otp4={otp4} otp5={otp5} otp6={otp6} setOTP1={setOTP1} setOTP2={setOTP2} setOTP3={setOTP3} setOTP4={setOTP4} setOTP5={setOTP5} setOTP6={setOTP6} />
                                        <div className="flex justify-center py-6 hidden" ref={loader}>
                                            <Loader />
                                        </div>
                                        <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                                        ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

                                        <Button text="Log in" actionHandler={otpHandler} loading={loading}/>
                                        <p className="text-primary-theme mb-5 text-sm px-12 text-center" onClick={ResendOTP}>Did not get OTP?</p>
                                    </div>
                                )
                            }
                            <p className="text-sm text-center text-gray-500">Have an account? <Link to="/login" className="text-blue-600">Sign In</Link></p>
                        </div>

                    </div>

                    <FAB />
                </div>
            </React.Fragment>
        )}
    </>
    )
}

export default Register
