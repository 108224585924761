import React from 'react'

function SmallList({title, description}) {
    return (
        <div className="w-full my-2 py-2 bg-white shadow rounded flex flex-col justify-center px-3">
            <h5 className="text-sm text-gray-400">{title}</h5>
            <p className="text-sm font-medium">{description}</p>
        </div>
    )
}

export default SmallList
