import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import NotificationInner from '../components/Notification';

function Notification({ user, merchantId }) {
    const [sidebarState, setSidebarState] = useState("hidden");
    const [isUnreadMsgExist, setIsUnreadMsgExist]= useState(false);
    const toggleSidebar = () => {
        // alert(0)
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }
    return (
        <div className="body-container">
            <Sidebar toggleSidebar={toggleSidebar} sidebarState={sidebarState}/>
            <div className="relative">
                <Navbar pageName="Notification" isUnreadMsgExist={isUnreadMsgExist} toggleSidebar={toggleSidebar}/>
                <NotificationInner setIsUnreadMsgExist={setIsUnreadMsgExist}/>
            </div>
        </div>
    )
}

export default Notification;
