import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function ChangeOwnerNew({
    allInvitedUsers,
    changeOwnerHandler,
    changeOwnerPayload,
    handleUpdateOwnerSelectChange}) {
    const [userLists, setUserLists] = useState(null);
    useEffect(()=>{
        let _userLists = [];
        allInvitedUsers.forEach(({name, id}) => _userLists.push({
            label: name,
            value: name
        }));
        setUserLists(_userLists)
    }, [])
    return (
        <>
        <h5 className="font-semibold text-center text-xl">Change Business Owner</h5>
        <p className="text-sm text-center text-black mt-4 w-4/5 mx-auto">This action will transfer this business to another administrator. You will no longer be the owner of this business, and so you can be removed from it.</p>

        <div className="px-12">
            <Select name="name" value={userLists?.filter(user => user.value === changeOwnerPayload.name)} name="roleId" onChange={(e)=>handleUpdateOwnerSelectChange(e, "name")} className="w-full h-12 my-3 rounded text-md" placeholder={<p>Select New Role For This User</p>} options={userLists} />

            <button onClick={changeOwnerHandler} className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded">Transfer</button>
        </div>
    </>
  )
}

export default ChangeOwnerNew
