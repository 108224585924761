import React, { useState } from 'react'

function Input({ name, label, placeholder, type, icon, spinner, disabledInput, PointerOut , value, onChange, Ref, notRequired }) {
    const [dateType, setDateType] = useState("number");
    // console.log(<icon />)

    const InputType = type ? type : "text";
    const toggleType = (e) => {
        if (e.currentTarget.parentElement.children[1].type === "password") {
            e.currentTarget.parentElement.children[1].type = "text";
        } else {
            e.currentTarget.parentElement.children[1].type = "password";
        }
    }
    const Loader = () => {
        return (
            <div className="absolute right-0 h-5 w-5  rounded-full animate-spin" style={{textAlign:'right', border: "3px solid #ff6700",borderRight: "3px solid transparent"}}></div>
        )
    }
    return (
        <div className="input-container mt-6 relative">
            <label className="text-sm text-gray-600 pl-2">{label} {notRequired ? '' : (<span className="text-red-600">*</span>)} </label>
            <input
                /*type={
                    InputType === "date"
                        ? dateType
                        : InputType
                }*/
                type={type}
                ref={Ref}
                onPointerOut={PointerOut}
                onFocus={
                    () => {
                        InputType === "date"
                            ? setDateType("date")
                            : console.log()
                    }
                }
                onBlur={
                    (e) => {
                        e.target.value === ""
                            ? setDateType("number")
                            : setDateType("date")
                    }
                }
                disabled={disabledInput ? true: false}
                onChange={onChange}
                value={value ? value : ""}
                name={name}
                required
                placeholder={placeholder}
                className="input border border-gray-400 rounded px-2 text-sm w-full py-3" />
                {spinner ? <Loader/>: ''}
            {
                icon ?
                <button onClick={toggleType} className="absolute right-3 top-8">{icon}</button>
                :
                ''
            }
        </div>
    )
}

export default Input

