import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import { BigCard, SmallCard, BarChart, BigCard2, DonutGraph } from './Overview/index';
import AccountCard from './AccountCard';
import axios from "axios";
import config from '../config.js';


function OverviewInner({statusStats, paymentChannels, successError, revenueStats, overviewStats, yearMonthStats, settlement}) {
    const [monthStats, setMonthStats] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [accountDetails, setAccountDetails] = useState(null);
    const [successErrorStats, setSuccessErrorStats] = useState(null);
    const [paymentChannelsStats, setPaymentChannelsStats] = useState(null);   
    const [totalRevenue, setTotalRevenue] = useState(null); 

    useEffect(() => {
        let successErrstats = {};
        successError?.forEach(element => {
            successErrstats[Object.values(element)[1]] = Object.values(element)[0];
        });
       
        const total = successErrstats?.FAILED + successErrstats?.SUCCESSFUL;
        const successful = successErrstats?.SUCCESSFUL;
        const failed = successErrstats?.FAILED;
        const successfulPercentage = (successful / total) * 100;
        const failedPercentage = (failed / total) * 100;
        if(total || total === 0){
            setSuccessErrorStats({successfulPercentage: successfulPercentage, failedPercentage: failedPercentage});
        }

        // Calcualte the payment channel to percentage
        let channelstats = {};
        paymentChannels?.forEach(element => {
            channelstats[Object.values(element)[1]] = Object.values(element)[0];
        });

        // alert(JSON.stringify(channelstats))
        const totalChannel = channelstats?.CARD + channelstats?.PAYATTITUDE + channelstats?.USSD + channelstats?.QR;
        const card =  (channelstats?.CARD / totalChannel) * 100;
        const payAttitude =  (channelstats?.PAYATTITUDE / totalChannel) * 100;
        const ussd = (channelstats?.USSD / totalChannel) * 100;
        const qr = (channelstats?.QR / totalChannel) * 100;
        if(totalChannel || totalChannel === 0){
            setPaymentChannelsStats({card, payAttitude, ussd, qr});
        }

        // GET Account details of a merchant
        const userId = JSON.parse(sessionStorage.getItem("userData")).id;
        const token = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                authorization: token,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        
        const ENDPOINT = `${config.BASE_URL}/temporal-service/api/v1/wallet/default/${userId}`;
        axios.get(ENDPOINT, header).then(response=>{
            setAccountDetails(response.data.data)
        });
        if(statusStats){
            let b = {};
            statusStats.forEach(element => {
                b[Object.values(element)[1]] = Object.values(element)[0];
            });
            // setRevenueSummary(b);
        }
        if(yearMonthStats){
            let totalRevenue = 0;
            yearMonthStats.forEach(element=>{
                totalRevenue = totalRevenue + element.totalRevenue;
            });
            setTotalRevenue(totalRevenue);
            let c = {};
            yearMonthStats.forEach(element=> {
                c[Object.values(element)[2]] = Object.values(element)[0];
                // alert(JSON.stringify(Object.values(element)));
            });
            let year = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Sep", "Oct", "Nov", "Dec"];
            // console.log(c)
            // c.map((element, index)=> {
            // alert(JSON.stringify(c))
            for(let element in c){
                //  console.log(element);
                const search = months.forEach((month, index) =>{
                    if(month === element){
                        year[index] = c[element];
                    }
                })
                // Algorithm: loop each element in the object, then brute force months array to find match with current value of element from the object, if theres a match, set the element in year array with the current index, else continue running the loop till it ends...
            };
            setMonthStats(year)
        }
    }, [statusStats, yearMonthStats, successError, paymentChannels])

    return (
        <React.Fragment>
            <div className="px-8 py-4">
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3">
                    <SmallCard title="Successful Transaction’s count" value={overviewStats?.totalSuccess} />
                    <SmallCard title="Pending Trasaction’s Count" value={overviewStats?.totalPending} />
                    <SmallCard title="Abandoned Trasaction’s Count" value={overviewStats?.totalAbandoned} />
                    <SmallCard title="Failed Trasaction’s Count" value={overviewStats?.totalFailed} />
                    <SmallCard title="Refunded Trasaction’s Count" value={overviewStats?.totalRefunded} />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 py-4">
                    <BigCard title="Gross Revenue" value={revenueStats ?.grossRevenue} date={revenueStats ?.latestSettlementDate}/>
                    <BigCard title="Net Revenue" value={revenueStats?.netRevenue}  />
                    {/* <BigCard title="Commissions" value={`NGN 0.00`}  /> */}
                    <AccountCard accountDetails={accountDetails}/>
                </div>
                <div className="grid grid-cols-1 gap-3">
                    <BarChart monthStats={monthStats} total={totalRevenue}/>
                </div>
                <div className="pt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <BigCard2 title="Last Settlement" value={`${settlement ? settlement?.latestSettlement : 0}`}/>
                    <BigCard2 title="Next Settlement" value={`${settlement ? settlement?.nextSettlement : 0}`}/>
                </div>
                <div className="pt-4 grid grid-cols-2 gap-4">
                    {/* <DonutGraph datum={[successErrorStats ? successErrorStats?.successfulPercentage : 10, successErrorStats ? successErrorStats?.failedPercentage : 0]} graph_title="Success Rate" labels={["Successful: ", "Error "]} colors={['#00FF00', '#FF6700']}/>
                    
                    <DonutGraph datum={[paymentChannels ? paymentChannelsStats?.card : 1, paymentChannels ? paymentChannelsStats?.ussd : 1, paymentChannels ? paymentChannelsStats?.payAttitude : 1, paymentChannels ? paymentChannelsStats?.qr : 1]} graph_title="Payment Methods" labels={["Card: ", "USSD:", "Pay Attitude:", "QR Code:"]} colors={['#00FF00', '#FF6700', "#F3CE00", "#0000FF", "#F9F4F4"]}/> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default OverviewInner
