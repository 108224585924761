
import {CardSilver, CopyIcon, GotoWayabank, ForwardArrow} from "../assets/index";

import React, {useState, useEffect} from 'react'
import { Card } from "./index.js";
import Grid from "@mui/material/Grid";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router-dom";
import FetchApi from "../utils/FetchApi";
import axios from "axios";
import config from "../config.js";



function AccountCard({accountDetails}) {

  const navigate = useNavigate();
  const [currentStats, setCurrentStats] = useState(null);
  const [incomeStats, setIncomeStats] = useState(null);
  const [withdrawStats, setWithdrawalStats] = useState(null);

  useEffect(() => {
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        "Content-Type": "application/json",
        authorization: authorization,
        'CLIENT-ID': 'WAYAQUICK',
        'CLIENT-TYPE': 'CORPORATE',
      },
    };


 // Fetch current balance stats
 const ENDPOINT3 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
 axios.get(ENDPOINT3, header).then((response) => {
   setCurrentStats(response.data);
 });
 
 // Fetch income balance stats
 const ENDPOINT4 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
 axios.get(ENDPOINT4, header).then((response) => {
   setIncomeStats(response.data);
 });

 // Fetch withdrawal balance stats
 const ENDPOINT5 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
 axios.get(ENDPOINT5, header).then((response) => {
   setWithdrawalStats(response.data);
 });
}, []);


    return (
        <div className="w-full h-34 md:h-32 rounded py-4 pl-2 pr-4 relative">
              <Card
                name="Current Balance"
                amount={currentStats?.data?.accountBalance}
                income={incomeStats?.data?.totalIncome}
                total={withdrawStats?.data?.totalPayout}
                actionButton={
                  <button
                    onClick={() => navigate("/balance-history")}
                    className="flex justify-between font-medium text-xs shadow gap-2 items-center rounded-full p-1"
                  >
                    <HistoryIcon />
                    View History
                  </button>
                }
              />
        </div>
    )
}

export default AccountCard
