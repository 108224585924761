import React from 'react';
import LeftOptions from './LeftOptions';
import RightOptions from './RightOptions';

function SettingInner() {


    return (
        <div className="w-full flex">
            <LeftOptions />
            <RightOptions />
        </div>
    )
}

export default SettingInner
