import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import BalanceHistory from '../components/BalanceHistory';

function Balance() {
    const [sidebarState, setSidebarState] = useState("hidden");
    const toggleSidebar = () => {
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }
    return (
        <div className="body-container">
            <Sidebar toggleSidebar={toggleSidebar} sidebarState={sidebarState}/>
            <div className="relative">
                <Navbar pageName="Settlement" toggleSidebar={toggleSidebar}/>
                <BalanceHistory />
            </div>
        </div>
    )
}

export default Balance;
