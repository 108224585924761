import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { FilterIcon, ExportIcon, ChevronDownIcon, BackIcon, CopyIcon} from "../assets";
import { Table } from "./index.js";
import { Button } from "@mui/material";
import CardThree from "./CardThree";
import axios from "axios";
import {
  getAllDispute,
  getDisputeStats,
  acceptDispute,
  rejectDispute,
} from "../services/requests/disputes";
import { FormatDatetime } from "../utils/FormatDatetime";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import Settlement from "../pages/settlement";
import { useNavigate, Link } from "react-router-dom";
import FetchApi from "../utils/FetchApi";
import config from "../config.js";

const Loader = () => {
  return (
    <div
      className="h-6 w-6 rounded-full animate-spin"
      style={{
        border: "3px solid #ffffff",
        borderRight: "3px solid transparent",
      }}
    ></div>
  );
};

function BalanceHistory() {
  const [allDispute, setAllDispute] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [allFilteredData, setFilteredData] = useState(null);
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [currentStats, setCurrentStats] = useState(null);
  const [incomeStats, setIncomeStats] = useState(null);
  const [withdrawStats, setWithdrawalStats] = useState(null);
  const [tableRowData, setTableRowData] = useState(null);
  const [settlementDetails, setSettlementDetails] = useState(null);
  const [allSettlement, setAllSettlement] = useState(null);
  const navigate = useNavigate();

  const tableColumnStructure = [
    { field: "id", headerName: "Reference ID", width: 175 },
    { field: "beneficiary", headerName: "Beneficiary", flex: 1, minWidth: 150 },
    {
      field: "settledAmount",
      headerName: "Settled Amount",
      flex: 1,
      minWidth: 150,
    },
    { field: "status", headerName: "Status", width: 120 },
    { field: 'settlementBeneficiaryAccount', headerName: 'Settlement Account', width: 160 },
    { field: "settlementDate", headerName: " Settled Date", width: 170 },
  ];

  const FilterDataById = (id) => {
    setValue(id);
    if (startDate) {
      const data = tableRowData;
      const outcome = data.filter(
        (element) => element.settlementDate.split(" ")[0] == startDate
      );
      setFilteredData(outcome);
      hideFilterDropdown();
    } else {
      if (id) {
        const data = tableRowData;
        // const outcome = data.filter(element=> element.id === id);
        const outcome = data.filter((element) => element.id.indexOf(id) > -1);
        setFilteredData(outcome);
        hideFilterDropdown();
      } else if (status) {
        const data = tableRowData;
        const outcome = data.filter(
          (element) => element.status === status.toUpperCase()
        );
        setFilteredData(outcome);
        hideFilterDropdown();
      } else {
        setFilteredData(tableRowData);
        hideFilterDropdown();
      }
    }
  };

  const ClearFilter = () => {
    setValue(() => "");
    setStartDate(() => "");
    setStatus(() => "");
    setType(() => "");
    setFilteredData(allDispute);
    hideFilterDropdown();
  };

  const showFilterDropdown = () => setFilterDropdown(true);
  const hideFilterDropdown = () => setFilterDropdown(false);

  const hideOverlay = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        "Content-Type": "application/json",
        authorization: authorization,
        'CLIENT-ID': 'WAYAQUICK',
        'CLIENT-TYPE': 'CORPORATE',
      },
    };
    const CopyID = (referenceID) => {
      navigator.clipboard.writeText(referenceID);
      toast("Settlement ID copied");
    };
  


   // Fetch current balance stats
   const ENDPOINT3 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
    axios.get(ENDPOINT3, header).then((response) => {
      setCurrentStats(response.data);
    });
    
    // Fetch income balance stats
    const ENDPOINT4 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
    axios.get(ENDPOINT4, header).then((response) => {
      setIncomeStats(response.data);
    });
   
    // Fetch withdrawal balance stats
    const ENDPOINT5 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
    axios.get(ENDPOINT5, header).then((response) => {
      setWithdrawalStats(response.data);
    });

     // Fetch list of withdrawals
     const ENDPOINT = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/withdrawal/histories?merchantId=${merchantId}`;
     axios.get(ENDPOINT, header).then((response) => {
       const historyResponse = response?.data?.data?.content.map(
         (elem, index) => ({
           id: elem?.id,
           beneficiary: elem?.accountSettlementOption,
           actualAmount: `NGN ${elem?.settlementGrossAmount}`,
           settledAmount: `NGN ${elem?.totalSettledAmount}`,
           settlementDate: FormatDatetime(elem?.dateCreated),
           status: elem?.payoutStatus,
           settlementBeneficiaryAccount: elem?.settlementAccount,
         })
       );
        setTableRowData(historyResponse);
        setFilteredData(historyResponse);
        setAllSettlement(response?.data?.data);
     });
  }, []);


  return (
    <>
      <button
        className="mx-8 my-4 flex items-center text-sm gap-2"
        onClick={() => navigate(-1)}
      >
        <BackIcon /> Back
      </button>
      <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
        <div className="flex items-center relative">
          <div className="1/5 flex gap-1 ...">
            <input
              placeholder="Search by Transaction ID"
              onChange={(e) => FilterDataById(e.target.value)}
              className="w-full h-10 text-sm px-2 rounded border border-gray-200"
            />
            <button
              onClick={() => FilterDataById(value)}
              className="bg-primary-theme py-2 px-5 text-white text-sm"
            >
              Search
            </button>
          </div>
        </div>
        <div>
          {allFilteredData?.length > 0 ? (
            <CSVLink data={allFilteredData}>
              <button className="flex shadow text-white h-10 items-center text-sm px-3 rounded bg-green-500">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.1673 16.6666H5.00065C4.08018 16.6666 3.33398 15.9205 3.33398 15V5.83331C3.33398 4.91284 4.08018 4.16665 5.00065 4.16665H8.33398V5.83331H5.00065V15H14.1673V11.6666H15.834V15C15.834 15.9205 15.0878 16.6666 14.1673 16.6666ZM9.75065 11.4225L8.57565 10.2441L13.8198 4.99998H10.834V3.33331H16.6673V9.16665H15.0007V6.17915L9.75065 11.4225Z"
                    fill="white"
                  />
                </svg>
                Export Data
              </button>
            </CSVLink>
          ) : (
            <button
              onClick={() => toast("You can't export empty data.")}
              className="flex shadow text-white h-10 items-center text-sm px-3 rounded bg-green-500"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1673 16.6666H5.00065C4.08018 16.6666 3.33398 15.9205 3.33398 15V5.83331C3.33398 4.91284 4.08018 4.16665 5.00065 4.16665H8.33398V5.83331H5.00065V15H14.1673V11.6666H15.834V15C15.834 15.9205 15.0878 16.6666 14.1673 16.6666ZM9.75065 11.4225L8.57565 10.2441L13.8198 4.99998H10.834V3.33331H16.6673V9.16665H15.0007V6.17915L9.75065 11.4225Z"
                  fill="white"
                />
              </svg>
              Export Data
            </button>
          )}
        </div>
      </div>

      <Grid spacing={2} container className="px-6 pt-5">
        <Grid item xs={12} sm={12} md={3}>
          <CardThree
            notCurrency="true"
            color="text-primary-theme"
            name="TOTAL INCOME"
            amount={incomeStats?.data?.totalIncome}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <CardThree
            notCurrency="true"
            color="text-green-500"
            name="TOTAL WITHDRAWAL"
            amount={withdrawStats?.data?.totalPayout}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <CardThree
            notCurrency="true"
            color="text-gray-600"
            name="CURRENT BALANCE"
            amount={currentStats?.data?.accountBalance}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <CardThree
            notCurrency="true"
            color="text-primary-theme"
            name="TRANSACTION COUNT"
            amount={allSettlement?.totalElements}
          />
        </Grid>
      </Grid>
      <Table
        tableColumnStructure={tableColumnStructure}
        tableRowData={allFilteredData}
        pageName="Transaction"
        checkboxSelection={false}
      />
   
       {showPopup && (
        <div className="w-full">
          <button
            className="mx-8 my-4 flex items-center text-sm gap-2"
            onClick={hideOverlay}
          >
            <BackIcon /> Back
          </button>
          <div className="grid grid-cols-1 h-auto px-8">
            <div className="bg-white w-full h-auto border px-6 border-gray-200 rounded-xl pt-5 pb-3">
              <div className="grid grid-cols-3 gap-3">
                <div className="w-full h-24 px-3 flex flex-col justify-center rounded-lg border border-gray-200">
                  <h4 className="text-primary-theme">Settled Amount</h4>
                  <h2 className="font-bold text-md h-6">
                    NGN {settlementDetails?.totalSettledAmount}
                  </h2>
                </div>
                <div className="w-full h-24 px-3 flex flex-col justify-center rounded-lg border border-gray-200">
                  <h4 className="text-primary-theme">Settled Date</h4>
                  <h2 className="font-bold text-md h-6">
                    {FormatDatetime(settlementDetails?.dateSettled)}
                  </h2>
                </div>
                <div className="w-full h-24 px-3 flex flex-col justify-center rounded-lg border border-gray-200">
                  <h4 className="text-primary-theme">Settled Account</h4>
                  <h2 className="font-bold text-md h-6">
                    {settlementDetails?.settlementAccount}
                  </h2>
                  <p className="text-gray-400 text-xs">
                    {settlementDetails?.settlementAccount}
                  </p>
                </div>
              </div>

              <div className="w-full h-auto mt-5 border border-gray-300 rounded-lg">
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-gray-300">
                      <th className="text-left text-sm p-3">Reference ID</th>
                      <th className="text-left text-sm p-3">Actual Amount</th>
                      <th className="text-left text-sm p-3">Wayapay Fee</th>
                      <th className="text-left text-sm p-3">Settled Amount</th>
                      <th className="text-left text-sm p-3">
                        Transaction Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-sm p-3">
                        <div className="flex items-center gap-2">
                          <span>
                            {settlementDetails?.settlementReferenceId}
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              (settlementDetails?.settlementReferenceId)
                            }
                          >
                            <CopyIcon />
                          </span>
                        </div>
                      </td>
                      <td className="text-sm p-3">
                        NGN {settlementDetails?.settlementGrossAmount}
                      </td>
                      <td className="text-sm p-3">
                        NGN {settlementDetails?.totalFee}
                      </td>
                      <td className="text-sm p-3">
                        NGN {settlementDetails?.settlementNetAmount}
                      </td>
                      <td className="text-sm p-3">
                        {settlementDetails?.dateCreated}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* /transactions?cid=CUS_ipDHb1658839560360PE1Bv */}
              <Link
                to=""
                className="text-primary-theme mt-3 text-sm block mx-auto"
              >
                click here to view details of this transactions
              </Link>
            </div>
          </div>
        </div>
      )}
       </>
  );
}

export default BalanceHistory;
