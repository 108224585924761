import React, {useState} from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import WayapaySettingInner from './WayapaySettingInner';

function WayapaySetting() {
    const [sidebarState, setSidebarState] = useState("hidden");
    const toggleSidebar = () => {
        // alert(0)
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }
    return (
        <div className="body-container">
            <Sidebar sidebarState={sidebarState}/>
            <div className="relative">
                <Navbar pageName="Wayapay Setting" toggleSidebar={toggleSidebar}/>
                <WayapaySettingInner />
            </div>
        </div>
    )
}

export default WayapaySetting;
