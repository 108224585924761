
import { httpGet, httpPost, httpPut, httpDelete } from '../https';
import { roleService } from '../index';

export const getAvailableRoles = () => {
	return httpGet(`${roleService}/merchant/list-roles`);
};

export const getPermissionIdFromRoleId = (roleId) => {
    return httpGet(`${roleService}/api/v1/roles/${roleId}/permissions`)
}

export const sendInviteService = (email, payload) => {
	return httpPost(`${roleService}/merchant/invite-user/${email}`, payload);
};

export const createCustomRoleRequest = (payload) => {
	return httpPost(`${roleService}/merchant/create-roles`, payload);
};


export const getAllUsers = (userId) => {
	return httpGet(`${roleService}/merchant/list-users`);
};


export const updateUserRole = (payload) => {
	return httpPut(`${roleService}/merchant/edit`, payload);
};

export const deleteUser = (userId) => {
	return httpDelete(`${roleService}/api​/v1​/user-role​/delete​/${userId}`);
}

export const getAllPermissions = () => {
	return httpGet(`${roleService}/merchant/list-permissions`);
};


// export const getPermissionIdFromRoleId = () => {
// 	return httpGet(`${roleService}/api/v1/corporate/user/role/find/all`);
// };


export const getRole = (roleId) => {
	return httpGet(`${roleService}/merchant/list/role-permissions/${roleId}`);
};

export const changeRole = (payload) => {
	return httpPost(`${roleService}/merchant/update-user`, payload)
}

export const deleteRole = (roleId) => {
	return httpDelete(`${roleService}/api/v1/role/delete/${roleId}`)
}

export const completeRegisteration = (payload) => {
	return httpPost(`${roleService}/merchant/register-user`, payload)
}


