import React, {useState, useEffect} from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import ActivityLogInner from '../components/ActivityLog';
import FetchApi from "../utils/FetchApi";
import axios from "axios";
import config from "../config.js";




function ActivityLog({ user, merchantId }) {
    const [sidebarState, setSidebarState] = useState("hidden");
    const toggleSidebar = () => {
        // alert(0)
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }

    return (
        <div className="body-container">
            <Sidebar toggleSidebar={toggleSidebar} sidebarState={sidebarState}/>
            <div className="relative">
                <Navbar pageName="Activity Logs" toggleSidebar={toggleSidebar}/>
                <ActivityLogInner />
            </div>
        </div>
    )
}

export default ActivityLog;
