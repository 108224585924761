import React from 'react'

function DeleteInvitedUser({
  selectedDataToDelete,
  deleteInvitedUserHandler
}) {
  return (
    <div className="flex flex-col items-center px-14">
        <h5 className="font-semibold my-5 text-center text-xl">Remove team memember</h5>
        <p className="text-sm text-center mb-3 text-black w-full mx-auto">By doing this, {selectedDataToDelete?.email} will no longer be able to access this business anymore. Do you want to continue?</p>
        <button onClick={deleteInvitedUserHandler} className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded">Yes, Remove</button>
    </div>
  )
}

export default DeleteInvitedUser;
