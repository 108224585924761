import React, { useEffect, useState, useContext } from "react";
import { BackIcon, QuestiontagIcon, CopyIcon } from "../../assets";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import FetchApi from "../../utils/FetchApi";
import { ApikeysContext } from "../../context/ApikeysContext";
import { ToastContainer, toast } from "react-toastify";
import {
  getBankLists,
  getAllBankAccount,
  addBankAccount,
  updateBankAccount,
  deleteBankAccount,
  setDefaultBankAccount,
  saveConfig,
  getConfig,
  getAccountName,
  getBranches,
} from "../../services/requests/banks";
import "react-toastify/dist/ReactToastify.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "react-select";

// import RadioButtonUncheckedIcon from '@mui/material/RadioButtonUnchecked';
// import RadioButtonCheckedIcon from '@mui/material/RadioButtonChecked';

function WayapaySettingInner() {
  const [value, setValue] = React.useState("1");
  const [supportedBank, setSupportedBank] = useState([]);
  const [supportedBranch, setSupportedBranch] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [configData, setConfigData] = useState(null);

  const [callback, setCallback] = useState("");
  const [webhook, setWebhook] = useState("");

  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [primary, setPrimary] = useState("");

  const [showPopup2, setShowPopup2] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);

  const [allBankDetails, setAllBankDetails] = useState([]);

  const [emailForAllTransaction, setEmailForAllTransaction] = useState(true);
  const [emailCustomerForAllTransaction, setEmailCustomerForAllTransaction] =
    useState(true);
  const [cardPaymentOption, setCardPaymentOption] = useState(true);
  const [ussdPaymentOption, setUssdPaymentOption] = useState(true);
  const [payAttitudePaymentOption, setPayAttitudePaymentOption] =
    useState(true);
  const [walletPaymentOption, setWalletPaymentOption] = useState(true);
  const [bankPaymentOption, setBankPaymentOption] = useState(true);

  const [settlementInterval, setSettlementInterval] = useState("");
  const [holdSettlementDate, setHoldSettlementDate] = useState(
    new Date().toISOString()
  );
  const [settlementMethod, setSettlementMethod] = useState(true);
  const [settlementType, setSettlementType] = useState("instant");
  const [allowCustomerToCancel, setAllowCustomerToCancel] = useState(true);
  const [sendEmailToBusinessAddress, setSendEmailToBusinessAddress] =
    useState(true);
  const [sendEmailToAllMembers, setSendEmailToAllMembers] = useState(true);
  const [sendEmailToSpecificMember, setSendEmailToSpecificMember] =
    useState(true);
  const [specificEmailList, setSpecificEmailList] = useState("");
  const [unfilteredBanks, setUnfilteredBanks] = useState("");
  const [loading, setLoading] = useState(false);
  const [toggleDefault, setToggleDefault] = useState(false);
  const [branch, setBranch] = useState('');


  // const [cardPaymentOption, setCardPaymentOption] = useState(true);
  // const [cardPaymentOption, setCardPaymentOption] = useState(true);
  // const [cardPaymentOption, setCardPaymentOption] = useState(true);

  // const [userKey, setApiKey] = useState(null);
  const [urlHook, setUrlHook] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { apiKey, setApiKey, modeStatus } = useContext(ApikeysContext);
  const productionMode = modeStatus === "PRODUCTION";
  const getBranch = async () => {
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    const response =  await getBranches(merchantId);
    const branchesData = [];
    response?.content?.map(e=> 
      branchesData.push({ label: e?.name, value: e?.name})
    )
    setSupportedBranch(branchesData);
}
  useEffect(() => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));

    const response2 = FetchApi({
      endpoint: `/identity-manager-service/api/v1/waya-merchant/${merchantId}`,
      authorization: authorization,
      method: "GET",
    });

    response2
      .then((resp) => {
        setUrlHook(resp);
      })
      .catch((err) => {
        console.log(err);
      });

    getBankLists()
      .then((response) => {
        // toast("Success");
        const bankLists = [];
        setUnfilteredBanks(response.data);

        response.data.forEach((bank) => {
          bankLists.push({
            label: bank.bankName,
            value: bank.bankName, //bankCode
            bankCode: bank.bankCode,
          });
        });
        setSupportedBank(bankLists);
      })
      .catch((err) => {
        console.log(err.response);
        toast("Error occured...");
      });

    getConfig(merchantId)
      .then((response) => {
        // toast("Success");
        const data = response.data;
        // setConfigData(response.data);
        setEmailForAllTransaction(data.emailMeForEveryTransaction);
        setEmailCustomerForAllTransaction(
          data.emailCustomerForEveryTransaction
        );
        setCardPaymentOption(data.enableCardPayments);
        setUssdPaymentOption(data.enableUSSDPayments);
        setPayAttitudePaymentOption(data.enablePayAttitudePayments);
        setWalletPaymentOption(data.enableWalletPayments);
        setBankPaymentOption(data.enableBankPayments);
        setSettlementInterval(data.settlementInterval);
        setHoldSettlementDate(data.placeSettlementOnHoldUntil);
        setSettlementMethod(data.settleToWalletAccount); //this is the variable used for radio button, the other options are !(negated), that's why I used this instead of settleToBankAccount;
        setAllowCustomerToCancel(data.allowCustomersToCancelSubscriptions);
        setSendEmailToBusinessAddress(
          data.sendNotificationToBusinessEmailAddressOnly
        );
        setSendEmailToAllMembers(data.sendNotificationToAllTeamMembers);
        setSendEmailToSpecificMember(data.sendNotificationToSpecificUsersOnly);
        setSpecificEmailList(
          data.selectedUsersToReceiveEmailNotifications[0].email
        );
        setSettlementType(data.settlementType)
      })
      .catch((err) => {
        console.log(err.response);
        toast("Error occured...");
      });

    getAllBankAccount(merchantId)
      .then((response) => {
        toast(response.message);
        console.log(response);
        let data = response.data.reverse().map((element) => {
          // if(element.deleted === true){
          return {
            id: element.id,
            bankName: element.settlementBankName,
            accountNumber: element.settlementBankAccountNumber,
            accountName: element.settlementBankAccountName,
            branch: element.branchCategory,
            currency: element.currency,
            default: element.isPrimary,
          };
          // }
        });
        setAllBankDetails(response.data);
        setBankAccounts(data);
      })
      .catch((err) => {
        console.log(err.response);
        toast("Error occured...");
      });
  }, []);

  useEffect(() => {
    getBranch();
  }, []);

  const generateHandler = () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const payload = {
      merchantId: JSON.parse(sessionStorage.getItem("merchantId")),
    };
    //  console.log(payload);
    const response2 = FetchApi({
      endpoint:
        "/identity-manager-service/api/v1/waya-merchant/api-keys/generate",
      payload: {},
      authorization: authorization,
      method: "PATCH",
    });
    response2
      .then((resp) => {
        toast.success("New credentials generated.");
        const keys = {
          activePublicKey: resp.publicKey,
          activeSecretKey: resp.secretKey,
        };
        setApiKey(keys);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 350 },
    { field: "bankName", headerName: "Bank Name", width: 160 },
    { field: "accountNumber", headerName: "Account Number", width: 160 },
    { field: "accountName", headerName: "Account Name", width: 190 },
    { field: "branch", headerName: "Branch/Category", width: 190 },
    { field: "currency", headerName: "Currency", width: 120 },
    {
      field: "default",
      headerName: "Default",
      width: 160,
      renderCell: (params) =>
        params.row.default ? (
          <span className="bg-primary-theme h-6 p-4 text-white flex items-center rounded-full text-sm">
            Default
          </span>
        ) : (
          <span className="bg-gray-300 h-6 p-4 text-black flex items-center rounded-full text-sm">
            Non default
          </span>
        ),
    },
    {
      // deleteBankAccount
      field: "delete",
      headerName: "Delete",
      width: 130,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const data = allBankDetails.filter(
            (elem) => elem.id === params.row.id
          );
          if (data.length === 1) {
            deleteBankAccount(data[0].id, data[0].merchantId)
              .then((response) => {
                toast(response.message?.message || response.message);
                // window.location.reload();
              })
              .catch((err) => {
                toast("Error occured.");
              });
          }
        };
        return (
          <button
            className="text-sm py-3 px-4 rounded-lg bg-gray-100 hover:bg-primary-theme hover:text-white text-primary-theme"
            onClick={onClick}
          >
            Delete
          </button>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const data = allBankDetails.filter(
            (elem) => elem.id === params.row.id
          );
          if (data.length === 1) {
            setShowPopup2(true);
            setAccountName(data[0]?.settlementBankAccountName);
            setAccountNumber(data[0]?.settlementBankAccountNumber);
            setBankName(data[0]?.settlementBankName);
            setBankCode(data[0]?.id);
            setBankDetails(data[0]);
            setBranch(data[0]?.branchCategory);
            // const currentCustomerStatus = data[0]?.status === "ACTIVE" ? false : true;
            // setAvoidStatus(()=> currentCustomerStatus);
          }
          //  console.log(data);
        };
        return (
          <button
            className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white"
            onClick={onClick}
          >
            View More
          </button>
        );
      },
    },
  ];

  // const rows = [];

  const saveWayaMerchantHandler = () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const payload = {
      merchantCallbackUrl: callback ? callback : urlHook?.merchantCallbackURL,
      merchantWebHookUrl: webhook ? webhook : urlHook?.merchantWebHookURL,
    };
    const response2 = FetchApi({
      endpoint: "/identity-manager-service/api/v1/waya-merchant",
      authorization: authorization,
      method: "PATCH",
      payload: payload,
    });
    response2
      .then((resp) => {
        if (resp.response.status != "200") {
          toast(resp.response.data.details[0]);
        } else {
          // setUrlHook(resp);
          setUrlHook(resp);
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Error occured.");
      });
  };

  const addBankAccountHandler = () => {
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    const payload = {
      bankAccountName: accountName,
      bankAccountNumber: accountNumber,
      bankName: bankName,
      primary: primary,
      bankCode: bankCode,
    };
    addBankAccount(payload, merchantId, settlementInterval,branch)
      .then((response) => {
        toast("Success");
        window.location.reload();
      })
      .catch((err) => {
        toast("Error occured");
      });
  };

  const UpdateBankAccountHandler = () => {
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    const payload = {
      accountName: accountName,
      accountNumber: accountNumber,
      bankName: bankName,
      bankCode: bankCode,
      currency: "NAIRA",
      merchantSettlementBankAccountId:
        bankDetails?.merchantSettlementBankAccountId,
      ...bankDetails,
    };

    updateBankAccount(merchantId, bankCode, settlementInterval || 'WEEKLY', settlementType, branch)
      .then((response) => {
        toast(response.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        toast("Error occured");
      });
  };

  const setAccountAsDefault = (e) => {
    const isChecked = e?.target?.checked;
    if (isChecked) {
      setDefaultBankAccount(bankDetails?.merchantSettlementBankAccountId, branch)
        .then((response) => {
          toast(response.message);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          toast("Error occured");
        });
      e.target.disabled = true;
    }
  };

  const saveConfiguration = async () => {
    try {
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      let payloadData = {
        allowCustomersToCancelSubscriptions: allowCustomerToCancel,
        defaultCurrency: "NGN",
        emailCustomerForEveryTransaction: emailCustomerForAllTransaction,
        emailMeForEveryTransaction: emailForAllTransaction,
        enableBankPayments: bankPaymentOption,
        enableCardPayments: cardPaymentOption,
        enablePayAttitudePayments: payAttitudePaymentOption,
        enableUSSDPayments: ussdPaymentOption,
        enableWalletPayments: walletPaymentOption,
        placeSettlementOnHoldUntil: holdSettlementDate, //"2022-06-03T14:17:28.568Z"
        selectedUsersToReceiveEmailNotifications: [
          {
            email: specificEmailList,
          },
        ],
        sendNotificationToAllTeamMembers: sendEmailToAllMembers,
        sendNotificationToBusinessEmailAddressOnly: sendEmailToBusinessAddress,
        sendNotificationToSpecificUsersOnly: sendEmailToSpecificMember,
        settleToBankAccount: !settlementMethod,
        settleToWalletAccount: settlementMethod,
        settlementInterval: settlementInterval,
        transactionSettlementOption: settlementMethod ? "WALLET" : "BANK",
        
      };
      await saveConfig(payloadData);
      await setDefaultBankAccount(merchantId, bankCode, settlementInterval, settlementType);
      toast.success('Success');
    } catch(err) {
        toast.error("Error occured");
    };
  };

  return (
    <div className="relative">
      <ToastContainer />
      {showPopup && (
        <div className="w-full h-screen absolute flex items-center justify-center z-40 bg-black bg-opacity-50 ">
          <div className="w-3/5 py-4 h-auto bg-white rounded ...">
            <header className="flex rounded shadow-sm items-center justify-center">
              <h5 className="text-lg">ADD BANK ACCOUNT FOR SETTLEMENT</h5>
            </header>
            <div className="w-full py-4 flex-col px-4 flex items-start">
              <label className="text-sm">
                Select Bank <span className="text-red-700">*</span>
              </label>
              <Select
                value={{
                  label: supportedBank?.filter(
                    (elem) => elem.value === bankName
                  )[0]?.label,
                  value: supportedBank?.filter(
                    (elem) => elem.value === bankName
                  )[0]?.value,
                }}
                onChange={(e) => {
                  setBankName(e.value);
                  setBankCode(e.bankCode);
                }}
                className="w-full h-10 rounded-lg border border-gray-200 text-sm"
                options={supportedBank}
                placeholder={<>Select your bank provider</>}
              />
            </div>
            <div className="w-full relative flex-col px-4 flex items-start">
              <label className="text-sm">
                Account number <span className="text-red-700">*</span>
              </label>
              {/* loading */}
              {loading && (
                <span
                  style={{
                    border: "2px solid #000099",
                    borderTop: "2px solid transparent",
                  }}
                  className="w-4 h-4 animate-spin rounded-full absolute right-6 bottom-5 block"
                ></span>
              )}
              {/* loading */}
              <input
                onChange={(e) => {
                  setAccountNumber(() => e.target.value);
                  if (e.target.value.length === 10 && bankName) {
                    setLoading(true);
                    const payload = {
                      accountNumber: e.target.value,
                      bankCode: unfilteredBanks?.filter(
                        (elem) => elem.bankName === bankName
                      )[0].bankCode,
                      //bankName: bankName
                    };
                    getAccountName(payload)
                      .then((response) => {
                        if (response.data) {
                          setAccountName(response.data.slice(3));
                        } else {
                          setAccountName("");
                        }

                        setLoading(false);
                      })
                      .catch((error) => {
                        toast.error("Unable to fetch account name");
                        setLoading(false);
                      });
                  } else if (e.target.value.length > 4 && !bankName) {
                    setAccountName("");
                    toast("Select your bank");
                  } else {
                    setAccountName("");
                  }
                }}
                type=""
                className="w-full px-3 my-1 h-10 shadow"
                placeholder="Enter your account number"
              />
            </div>
            <div className="w-full flex-col px-4 flex items-start">
              <label className="text-sm">
                Account name <span className="text-red-700">*</span>
              </label>
              <input
                disabled
                value={accountName}
                type=""
                className="w-full px-3 my-1 h-10 shadow"
                placeholder="Enter your account name"
              />
            </div>
            <div className="w-full py-4 flex-col px-4 flex items-start">
              <label className="text-sm">
                Select Branch/Category <span className="text-red-700">*</span>
              </label>
              <Select
                value={{
                  label: supportedBranch?.filter(
                    (elem) => elem.labl === branch
                  )[0]?.label,
                  value: supportedBranch?.filter(
                    (elem) => elem.value === branch
                  )[0]?.value,
                }}
                onChange={(e) => {
                  setBranch(e.name);
                }}
                className="w-full h-10 rounded-lg border border-gray-200 text-sm"
                options={supportedBranch}
                placeholder={<>Select branch/category</>}
              />
            </div>
            <div className="w-full px-4 flex items-center text-sm">
              <Checkbox
                defaultChecked={false}
                onChange={(e) => setPrimary(e.target.checked)}
              />
              Set as Primary Account?
            </div>
            <div className="w-ful px-4 flex justify-between py-3 items-start">
              <button
                onClick={() => setShowPopup(false)}
                className="bg-gray-100 text-sm  py-2 px-5 rounded"
              >
                Cancel
              </button>
              <button
                onClick={addBankAccountHandler}
                className="bg-primary-theme text-white text-sm py-2 px-5 rounded"
              >
                Add bank account
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopup2 && (
        <div className="w-full h-screen absolute flex items-center justify-center z-40 bg-black bg-opacity-50 ">
          <div className="w-3/5 py-4 h-auto bg-white rounded">
            <header className="flex rounded shadow-sm items-center justify-center">
              <h5 className="text-lg">UPDATE BANK ACCOUNT FOR SETTLEMENT</h5>
            </header>
            <div className="w-full py-4 flex-col px-4 flex items-start">
              <label className="text-sm">Bank Name</label>
              <input
                defaultValue={bankName}
                readOnly={true}
                type=""
                className="w-full px-3 my-1 h-10 shadow"
                placeholder="Enter your account number"
              />
            </div>
            <div className="w-full flex-col px-4 flex items-start">
              <label className="text-sm">
                Account number <span className="text-red-700">*</span>
              </label>
              <input
                defaultValue={accountNumber}
                readOnly={true}
                type=""
                className="w-full px-3 my-1 h-10 shadow"
                placeholder="Enter your account number"
              />
            </div>
            <div className="w-full flex-col px-4 flex items-start">
              <label className="text-sm">
                Account name <span className="text-red-700">*</span>
              </label>
              <input
                defaultValue={accountName}
                readOnly={true}
                type=""
                className="w-full px-3 my-1 h-10 shadow"
                placeholder="Enter your account name"
              />
            </div>
            <div className="w-full flex-col px-4 flex items-start">
              <label className="text-sm">
                Branch/Category name <span className="text-red-700">*</span>
              </label>
              <input
                defaultValue={branch}
                readOnly={true}
                type=""
                className="w-full px-3 my-1 h-10 shadow"
                placeholder="Enter your branch/category name"
              />
            </div>

            {!bankDetails?.defaultSettlementAccount && (
              <div className="h-20 p-5 mt-5 border border-gray-200">
              <div className="flex justify-between items-center">
                <div className="text-xs text-gray-500 flex items-center gap-2">
                  <div>
                    <span className="text-xs flex items-center gap-2 font-medium text-black">
                      Set account as default{" "}
                    </span>
                  </div>
                </div>
                <label
                  className={`custom_switch relative w-12 h-6 rounded-full bg-gray-400`}
                >

                  <input
                    type="checkbox"
                    defaultChecked={bankDetails?.defaultSettlementAccount}
                    onClick={(e)=> setToggleDefault(e.target.checked)}
                    className="hidden"
                    defaultValue={toggleDefault}
                  />
                  <span
                    className={`circle w-4 h-4 left-1 bg-black rounded-lg block absolute`}
                  ></span>
                </label>
              </div>
              <select 
                hidden={!toggleDefault} 
                defaultValue={settlementInterval} 
                onChange={(e)=> setSettlementInterval(e.target.value)} 
                className="border text-sm px-4 py-2 border-gray-300"
              >
                  Select when to be settled
                <option value="INSTANTLY">Select when to be settled</option>
                <option value="DAILY">DAILY(T+1)</option>
                <option value="WEEKLY">WEEKLY</option>
              </select>
              </div>
            )}
            <div className="w-ful px-4 flex justify-between py-3 items-start">
              <button
                onClick={() => {
                  setShowPopup2(false)
                  setAccountAsDefault()
                }}
                className="bg-gray-100 text-sm  py-2 px-5 rounded"
              >
                Cancel
              </button>
              <button
                onClick={UpdateBankAccountHandler}
                className="bg-primary-theme text-white text-sm py-2 px-5 rounded"
              >
                Update bank account
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="px-8 pt-6 pb-2">
        <Link to="/setting" className="flex items-center gap-2">
          <BackIcon />
          Back
        </Link>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Settlement Account" value="1" />
              <Tab label="Payment Preferences" value="2" />
              <Tab label="API Keys & Webhooks" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div style={{ height: 400, width: "100%" }}>
              <div className="w-full h-14 flex items-center p-3 justify-end shadow">
                <button
                  onClick={() => setShowPopup(true)}
                  className="py-2 px-5 rounded text-md bg-primary-theme text-white"
                >
                  Add account
                </button>
              </div>
              <DataGrid
                rows={bankAccounts}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
              />
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="flex items-center justify-between">
              <h5 className="border-b-4 text-md font-semibold border-red-500 w-8">
                Preferences
              </h5>
              <button
                onClick={saveConfiguration}
                className="bg-primary-theme text-white rounded px-5 py-2"
              >
                Save changes
              </button>
            </div>
            <div className="flex pt-10">
              <p className="w-1/4 text-sm font-medium">Transaction Option</p>
              <div className="">
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={emailForAllTransaction}
                    onChange={(e) =>
                      setEmailForAllTransaction(e.target.checked)
                    }
                  />
                  Email me for every transaction
                </span>
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={emailCustomerForAllTransaction}
                    onChange={(e) =>
                      setEmailCustomerForAllTransaction(e.target.checked)
                    }
                  />
                  Email customers for every transaction
                </span>
              </div>
            </div>
            <div className="flex pt-4">
              <p className="w-1/4 text-sm font-medium">Default Currency</p>
              <button className="bg-gray-200 font-medium border border-gray-300 text-sm px-8 py-2 rounded">
                NGN
              </button>
            </div>
            <div className="flex pt-4">
              <p className="w-1/4 text-sm font-medium">
                Preferred Payment Methods
              </p>
              <div className="">
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={cardPaymentOption}
                    onChange={(e) => setCardPaymentOption(e.target.checked)}
                  />
                  Enable Card Payments
                </span>
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={ussdPaymentOption}
                    onChange={(e) => setUssdPaymentOption(e.target.checked)}
                  />
                  Enable USSD Payments
                </span>
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={payAttitudePaymentOption}
                    onChange={(e) =>
                      setPayAttitudePaymentOption(e.target.checked)
                    }
                  />
                  Enable PayAttitude Payments
                </span>
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={walletPaymentOption}
                    onChange={(e) => setWalletPaymentOption(e.target.checked)}
                  />
                  Enable Wallet Payments
                </span>
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={bankPaymentOption}
                    onChange={(e) => setBankPaymentOption(e.target.checked)}
                  />
                  Enable Bank Payments
                </span>
              </div>
            </div>
            <hr />
            <div className="flex pt-4 mb-12">
              <p className="w-1/4 text-sm font-medium flex items-center">
                Settlement Type
                <QuestiontagIcon />
              </p>
              <div>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                 //defaultValue={settlementMethod ? "wallet" : "bank"}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="instant"
                    control={
                      <Radio
                        checked={settlementType.toLowerCase() === "instant"}
                        onChange={(e) => setSettlementType("INSTANT")}
                      />
                    }
                    label="Instant"
                  />
                  {settlementType.toLowerCase() === "instant" && 
                    <Select
                      onChange={(e) => {
                        setBankName(e.bankName);
                        setBankCode(e.value);
                        //  console.log(e, 'INSTANT');
                      }}
                      className="w-80 h-10 rounded-lg border border-gray-200 text-sm"
                      options={
                        bankAccounts
                        ?.filter(item => item.bankName.toLowerCase().includes('waya'))
                        ?.map(item => ({ label: `${item.bankName} - ${item.accountNumber}`, value: item.id, bankName: item.bankName }))
                      }
                    />
                  }
                  <FormControlLabel
                    value="others"
                    control={
                      <Radio
                        checked={settlementType.toLowerCase() !== "instant"}
                        onChange={(e) => setSettlementType("TPLUS1")}
                      />
                    }
                    label="Others"
                  />
                  <div>
                  {
                    settlementType.toLowerCase() !== "instant" && 
                    <Select 
                      onChange={(e) => {
                        setBankName(e.bankName);
                        setBankCode(e.value);
                        //  console.log(e, 'OTHERS');
                      }}
                      className="w-80 h-10 rounded-lg border border-gray-200 text-sm"
                      options={
                        bankAccounts
                        ?.filter(item => !item.bankName.toLowerCase().includes('waya'))
                        ?.map(item => ({ label: `${item.bankName} - ${item.accountNumber}`, value: item.id, bankName: item.bankName }))
                      }
                      placeholder={<>Select your account provider to make payment</>}
                    />
                  }
                  </div>
                </RadioGroup>
              </div>
            </div>
            
            {settlementType.toLowerCase() === "others" && <>
            <hr/>
            <div 
            className="flex pt-4 mt-12"
             >
              <p className="w-1/4 text-sm font-medium flex items-center">
                Settlement Schedule
                <QuestiontagIcon />
              </p>
             
                {/* <Select
                  onChange={(e) => {
                    setBankName(e.value);
                    setBankCode(e.bankCode);
                  }}
                  className="w-80 h-10 rounded-lg border border-gray-200 text-sm"
                  options={   [
                    {
                      "value": "DAILY(T+1)", 
                      "label": "DAILY(T+1)", 
                    },
                    {
                      "value": "WEEKLY",
                      "label": "WEEKLY",
                    },  
                  ]

                  }
                  placeholder={<>Select when to be settled</>}
                /> */}

                <select defaultValue={settlementInterval} onChange={(e)=> setSettlementInterval(e.target.value)} className="border text-sm px-4 py-2 border-gray-300">
                                      Select when to be settled
                                    <option value="INSTANTLY">Select when to be settled</option>
                                    <option value="DAILY">DAILY(T+1)</option>
                                    <option value="WEEKLY">WEEKLY</option>
                                </select>
                {/* <span className="flex flex-col justify-center text-sm mt-3 font-medium gap-1"> */}
                {/* <Checkbox /> */}
                {/* Place settlement on hold until
                                    <input type="datetime-local" defaultValue={holdSettlementDate} onChange={(e)=> setHoldSettlementDate(e.target.value)} className="bg-gray-100 ml-2 px-3 py-2 rounded-lg" />
                                </span> */}
              </div>
              </>
              }

            {/* <div className="flex pt-10">
                            <p className="w-1/4 text-sm font-medium">Preferred Earning Method</p>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={settlementMethod ? "wallet": "bank"}
                                    name="radio-buttons-group">
                                    <FormControlLabel value="bank" control={<Radio defaultChecked={!settlementMethod} onChange={(e)=> setSettlementMethod(e.target.checked)}/>} label="Settle to my bank account" />
                                    <FormControlLabel value="wallet" control={<Radio defaultChecked={settlementMethod} onChange={(e)=> setSettlementMethod(e.target.checked)}/>} label="Settle to my WayaBank Account" />
                                </RadioGroup>
                            </FormControl> */}
            {/* <div className=""> */}
            {/* <span className="flex items-center text-sm font-medium gap-1">
                                    <Checkbox defaultChecked={!settlementMethod} onChange={(e)=> setSettlementMethod(e.target.checked)} />
                                    Settle to my bank account
                                    <QuestiontagIcon />
                                </span>
                                <span className="flex items-center text-sm font-medium gap-1">
                                    <Checkbox defaultChecked={settlementMethod} onChange={(e)=> setSettlementMethod(e.target.checked)} />
                                    Settle to my Wayapay Account
                                    <QuestiontagIcon />
                                </span> */}
            {/* </div>  */}
            {/* </div> */}
            <br></br>
            <br></br>
            <br></br>
            <hr />
            <div className="flex pt-4">
              <p className="w-1/4 flex items-center text-sm font-medium">
                Subscription Emails
                <QuestiontagIcon />
              </p>
              <div className="">
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={allowCustomerToCancel}
                    onChange={(e) => setAllowCustomerToCancel(e.target.checked)}
                  />
                  Allow customers to cancel subscriptions.
                </span>
              </div>
            </div>
            <div className="flex items-start pt-4">
              <p className="w-1/4 mt-3 flex items-center text-sm font-medium">
                Send notification emails to other users
              </p>
              <div className="">
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={setSendEmailToBusinessAddress}
                    onChange={(e) =>
                      setSendEmailToBusinessAddress(e.target.checked)
                    }
                  />
                  Send to the business email address only.
                </span>
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={sendEmailToAllMembers}
                    onChange={(e) => setSendEmailToAllMembers(e.target.checked)}
                  />
                  Send to all team member.
                </span>
                <span className="flex items-center text-sm font-medium gap-1">
                  <Checkbox
                    defaultChecked={sendEmailToSpecificMember}
                    onChange={(e) =>
                      setSendEmailToSpecificMember(e.target.checked)
                    }
                  />
                  Send to specific users only.
                </span>
                <span className="flex items-center px-3 text-sm font-medium gap-1">
                  <input
                    onChange={(e) => setSpecificEmailList(e.target.value)}
                    className="w-full h-10 px-3 rounded border border-gray-400"
                    defaultValue={specificEmailList}
                    placeholder="username@address.com"
                  />
                </span>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <h5 className="text-md font-semibold flex relative">
              API Configuration -
              <span className="border-b-4 absolute left-0 -bottom-1 border-red-500 w-8 flex"></span>{" "}
              <span className="text-primary-theme">
                {productionMode ? "Live Mode" : "Test Mode"}
              </span>
            </h5>

            <div
              className="w-full text-primary-theme text-sm gap-2 mt-6 h-12 bg-red-50 flex items-center justify-center"
              hidden={productionMode}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 0.5H2C1.175 0.5 0.5075 1.175 0.5075 2L0.5 15.5L3.5 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5ZM8.75 7.25H7.25V2.75H8.75V7.25ZM8.75 10.25H7.25V8.75H8.75V10.25Z"
                  fill="#F63002"
                />
              </svg>
              These keys are for testing only. Please DO NOT use them in
              production.
            </div>

            <div className="flex items-center gap-4 py-5">
              <p className="text-sm">
                API {productionMode ? "Live" : "Test"} Secret Key
              </p>
              <input
                type=""
                className="w-1/2 text-black border border-gray-200 h-12 text-sm font-medium px-3 rounded bg-gray-100 text-gray-400 ..."
                value={`${apiKey && apiKey.activeSecretKey}`}
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(`${apiKey.activeSecretKey}`);
                  toast("Secret key copied!");
                }}
              >
                <CopyIcon />
              </button>
            </div>

            <div className="flex items-center gap-4 py-5">
              <p className="text-sm">
                API {productionMode ? "Live" : "Test"} Public Key
              </p>
              <input
                type=""
                className="w-1/2 text-black border border-gray-200 h-12 text-sm font-medium px-3 rounded bg-gray-100 text-gray-400 ..."
                value={`${apiKey && apiKey.activePublicKey}`}
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(`${apiKey.activePublicKey}`);
                  toast("Public key copied!");
                }}
              >
                <CopyIcon />
              </button>
            </div>
            <div className="flex w-full items-center justify-start pl-32 py-3 ...">
              <button
                onClick={generateHandler}
                className="flex items-center text-primary-theme shadow bg-white px-6 ml-2 py-2"
              >
                Generate new secret keys
                <QuestiontagIcon />
              </button>
            </div>
            <div className="flex items-center gap-4 py-5">
              <p className="text-sm">
                {productionMode ? "Live" : "Test"} Callback URL
              </p>
              <input
                type=""
                defaultValue={
                  urlHook?.merchantCallbackURL
                    ? urlHook?.merchantCallbackURL
                    : ""
                }
                onChange={(e) => setCallback(e.target.value)}
                className="w-1/2 text-black border border-gray-200 h-12 text-sm font-medium px-3 rounded ..."
                placeholder={`Enter ${
                  productionMode ? "Live" : "Test"
                } Callback URL`}
              />
            </div>
            <div className="flex items-center gap-4 py-5">
              <p className="text-sm">
                {productionMode ? "Live" : "Test"} Webhook URL
              </p>
              <input
                type=""
                defaultValue={
                  urlHook?.merchantWebHookURL ? urlHook?.merchantWebHookURL : ""
                }
                onChange={(e) => setWebhook(e.target.value)}
                className="w-1/2 text-black border border-gray-200 h-12 text-sm font-medium px-3 rounded ..."
                placeholder={`Enter ${
                  productionMode ? "Live" : "Test"
                } Webhook URL`}
              />
            </div>
            <div className="flex w-full items-center justify-start pl-32 py-3 ...">
              <button
                onClick={saveWayaMerchantHandler}
                className="flex items-center text-primary-theme shadow bg-white px-6 ml-2 py-2"
              >
                Save
                <QuestiontagIcon />
              </button>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default WayapaySettingInner;
