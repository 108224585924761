import React from 'react';
import { NavLink } from 'react-router-dom';

function Option({ title, subtitle, link }) {
	return (
		<NavLink
			to={`${link}`}
			className={(navData) =>
				navData.isActive
					? 'bg-white w-full h-14 relative rounded shadow hover:bg-gray-100 flex flex-col justify-center px-3 mb-3'
					: 'bg-gray-100 w-full h-14 relative rounded shadow hover:bg-white flex flex-col justify-center px-3 mb-3'}
		>
			<h5 className="font-medium">{title}</h5>
			<p className="text-xs text-gray-500">{subtitle}</p>

			<svg
				className="absolute right-3 top-4"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
					fill="#1C1C1C"
				/>
			</svg>
		</NavLink>
	);
}

export const TransparencyOption = ({ title, subtitle, link }) => {
	return (
		<a
			href={link}
			target="_blank"
			className="bg-gray-100 w-full h-14 relative rounded shadow hover:bg-white flex flex-col justify-center px-3 mb-3"
		>
			<h5 className="font-medium">{title}</h5>
			<p className="text-xs text-gray-500">{subtitle}</p>

			<svg
				className="absolute right-3 top-4"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
					fill="#1C1C1C"
				/>
			</svg>
		</a>
	);
}

export default Option;
