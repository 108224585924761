import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import "./App.css";
import Dashboard from './pages/dashboard';
import SubmitKyc from './pages/submitKyc';
import Transaction from './pages/transaction';
import Overview from './pages/overview';
import NotYet from './pages/NotYet';
import Settlement from './pages/settlement';
import Customer from './pages/customer';
import Subscription from './pages/subscription';
import PaymentLink from './pages/paymentLink';
import Dispute from './pages/dispute';
import Notification from './pages/notification';
import Setting from './pages/setting';
import Support from './pages/support';
import ActivityLog from './pages/activitylog';
import WayapaySetting from './components/Setting/WayapaySetting';
import Login from './pages/onboarding/login';
import Register from './pages/onboarding/register';
import InviteeRegister from "./pages/onboarding/invitee-register";
import ForgetPassword from './pages/onboarding/forget-password';
import ChangePassword from './pages/onboarding/change-password';
import Referral from './pages/Referral';
import RoleAccess from './pages/RoleAccess';
import SetPin from "./pages/onboarding/pin-setup";
import CheckAuth from './utils/checkAuth';
import {AuthContext} from "./context/AuthContext";
import {ApikeysProvider} from "./context/ApikeysContext";
import BalanceHistory from './pages/balance';
import AGDashboard from './pages/agDashboard';
import AuthLogin from './pages/onboarding/Authpage';

require('dotenv').config()

function App() {
    const [status, setStatus] = useContext(AuthContext);

    return (
        <div>
            {
                !status && (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/auth" element={<AuthLogin />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/invitee-register" element={<InviteeRegister />} />
                    <Route path="/forget-password" element={<ForgetPassword />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    {/* <Route path="/set-pin" element={<SetPin />} /> */}
                    <Route path="*" element={<Navigate to={status ? "/" : "/login"} />} />
                </Routes>
                )
            }{
                status && (
                    <ApikeysProvider>
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/submit-kyc" element={<SubmitKyc />} />
                            <Route path="/overview" element={<Overview />} />
                            <Route path="/transactions" element={<Transaction />} />
                            <Route path="/aggregator-dashboard" element={<AGDashboard />} />
                            <Route path="/settlements" element={<Settlement />} />
                            <Route path="/customers" element={<Customer />} />
                            <Route path="/subscription" element={<Subscription />} />
                            <Route path="/payment-link" element={<PaymentLink />} />
                            <Route path="/dispute" element={<Dispute />} />
                            <Route path="/notification" element={<Notification />} />
                            <Route path="/setting" element={<Setting />} />
                            <Route path="/setting/wayapay" element={<WayapaySetting />} />
                            <Route path="/setting/role-access" element={<RoleAccess />} />
                            <Route path="/setting/view-referral" element={<Referral />} />
                            <Route path="/setting/*" element={<Setting />} />
                            <Route path="/support" element={<Support />} />
                            <Route path="/activity-log" element={<ActivityLog />} />
                            <Route path="/balance-history" element={<BalanceHistory />} />
                            <Route path="/set-pin" element={<SetPin />} />
                            {/* <Route path="*" element={<NotYet />} /> */}
                            <Route path="*" element={<Navigate to={status ? "/" : "/login"} />} />
                        </Routes>
                    </ApikeysProvider>
                )
            }
        </div>
    )
}

export default App
