

import {httpGet, httpPost, httpPatch, httpDelete, httpPut} from "../https";
import {ussdService, identityService,cardService, paymentGateway, settlementService} from "../index";

export const getAllBanks = () => {
    return httpPost(`${settlementService}/transactions/settlements/fetchAllBanks`);

}

export const getBankLists = () => {
    // const addr = "https://services.staging.wayapay.ng/card-service";
    // cardService
    return httpGet(`${cardService}/bankAccount/getBanks/WD`);
}

export const getAccountName = (payload) => {
    // const addr = "https://services.staging.wayapay.ng/card-service";
    // return httpPost(`${cardService}/bankAccount/resolveAccountNumber/WD`, payload);
   return httpPost(`${settlementService}/transactions/settlements/verifyBankAccount`, payload);

}

export const getAllBankAccount = (id) => {
    return httpGet(`${settlementService}/transactions/settlements/fetchAllSettlementBankAccount/byMerchant?merchantId=${id}`);

}

export const updateBankAccount = (id, bankDetailId, settlementTime, settlementType, branch ) => {
    return httpPut(`${settlementService}/transactions/settlements/changeSettlementAccount?branchCategory=${branch}&merchantId=${id}&settlementTime=${settlementTime}&bankDetailId=${bankDetailId}&settlementType=${settlementType}`);

}


export const addBankAccount = (payload, id, settlementTime, branch) => {
    return httpPost(`${settlementService}/transactions/settlements/addBankAccount?branchCategory=${branch}&merchantId=${id}&settlementTime=${settlementTime}`, payload);
    
}

export const deleteBankAccount = (bankDetailId, id) => {
    return httpPut(`${settlementService}/transactions/settlements/deleteSettlementAccount?bankDetailId=${bankDetailId}&merchantId=${id}`,);
    
}

export const setDefaultBankAccount = (id, bankDetailId, settlementTime, settlementType, branch ) => {
    //return httpPatch(`${identityService}/waya-merchant/settlement-bank-account/default?merchantSettlementBankAccountId=${id}`);
    return httpPut(`${settlementService}/transactions/settlements/changeSettlementAccount?branchCategory=${branch}&merchantId=${id}&settlementTime=${settlementTime}&bankDetailId=${bankDetailId}&settlementType=${settlementType}`);

}

export const saveConfig = (payload) => {
    return httpPatch(`${identityService}/waya-merchant/configuration`, payload);
}


export const getConfig = (id) => {
    return httpGet(`${identityService}/waya-merchant/configuration/fetch?merchantId=${id}`);
}

export const getBranches = (id) => {
    return httpGet(`${paymentGateway}/branchcategory/list?merchantId=${id}&page=0&size=100`);
}

export const withdraw = (merchantId, amount) => {
    return httpPost(`${settlementService}/transactions/settlements/withdrawal/${merchantId}?amount=${amount}`);
}

export const withdrawHistory = (merchantId) => {
    return httpPost(`${settlementService}/transactions/settlements/withdrawal/histories?merchantId=${merchantId}`);
}

