import React from 'react';
import HistoryIcon from '@mui/icons-material/History';
import BalanceHistory from './BalanceHistory';

function Card({ name, amount, date, notCurrency, income, total, settled, pending, withdraw, actionButton}) {
    return (
        <div className="w-full h-28 rounded hover:bg-gray-50 border border-gray-100 shadow hover:shadow-md flex flex-col justify-center px-6">
            <div className='flex justify-between' >
            <p className="text-primary-theme font-medium text-sm">{name}</p>
                {actionButton}
            </div>
            <h3 className="text-black text-1xs font-semibold my-1 flex items-center gap-2">{notCurrency ? "" : '₦'}{amount || amount === 0 ? amount : <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</h3>
            {/* <span className="text-xs text-gray-400">On {date}</span> */}
            <div className='flex justify-between gap-x-1'>   
            <p className="text-primary-theme  text-xs" hidden={income === null || income === undefined}>Total Income<br/>₦{income}</p>
            <p className="text-primary-theme text-xs" hidden={total === null || total === undefined}>Total withdrawal<br/>₦{total}</p>
            <p className=" font-medium text-sm  text-gray-400" hidden={!settled}>Settled<br/>{settled}</p>
            <p className=" font-medium text-sm text-yellow-400" hidden={!pending}>Pending<br/>{pending}</p>
            <p className=" font-medium text-sm  text-gray-400" hidden={!withdraw}>Withdraw<br/>{withdraw}</p>
            
            </div>
        </div>
    )
}

export default Card
