
import React, {useEffect, useState} from 'react';
import FetchApi from '../utils/FetchApi';
import { ToastContainer, toast } from 'react-toastify';

export const ApikeysContext = React.createContext();

export function ApikeysProvider({children}) {
    const [apiKey, setApiKey] = useState(null);
    const [tab, setTab] = useState(1);
    const [modeStatus, setModeStatus] =  useState("TEST");
    const [isKYCVerified, setIsKYCVerified] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState("DRAFT");

    useEffect(() => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const response = FetchApi({ endpoint: "/identity-manager-service/api/v1/waya-merchant/fetch/api-keys", authorization: authorization, method: "GET" });
        response.then(resp => {
            setApiKey(resp);
        }).catch(err => {
            toast.error(err?.response?.data?.message)
        });

        getToggleStatus();
        getAccountKycStatus();
    }, []);

    const toggleModeStatus = () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        let payload;
        if(modeStatus === "TEST"){
            payload = {
                wayaMerchantApiMode: "PRODUCTION"
            }
        }else{
            payload = {
                wayaMerchantApiMode: "TEST"
            }
        }
        const response = FetchApi({ endpoint: "/identity-manager-service/api/v1/waya-merchant/api-mode-toggle", payload: payload, authorization: authorization, method: "PUT" });

        response.then(res=>{
            toast.success("Toggle Successful");
            setTimeout(() => {
                window.location.reload();
            }, 700);
            setModeStatus(payload.wayaMerchantApiMode);
            return true;
        }).catch(err=>{
            toast.error(err?.response?.data?.details[0]);
            if(err?.response?.status === 403){
                setTab(2);
                setModeStatus("TEST");
                return false;
            }
        });
    }

    const getToggleStatus = () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        
        const response = FetchApi({ endpoint: "/identity-manager-service/api/v1/waya-merchant/auth-user/merchant-account", authorization: authorization, method: "GET" });
        response.then(resp => {
            // setIsKYCVerified(resp.accountActive);
            setModeStatus(resp.merchantKeyMode);
        }).catch(err => {
            toast.error(err.response.data.details[0]);
        });
    }

    // Fetch KYC info
    const getAccountKycStatus = () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));        
        const response = FetchApi({ endpoint: `/identity-manager-service/api/v1/waya-merchant/business-kyc/${merchantId}`, authorization: authorization, method: "GET" });

        response.then(res=>{
            toast.success("Fetch Successful");
            setApprovalStatus(res.approvalStatus);
        }).catch(err=>{
            toast.error(err.response.data.details[0]);
        });
    }

    return (
        <ApikeysContext.Provider value={{apiKey, setApiKey, tab, setTab, toggleModeStatus, modeStatus, isKYCVerified, approvalStatus}}>
            {children}
        </ApikeysContext.Provider>
    )
}

