import React from 'react';
import Select from 'react-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function UpdateUserInvite({
    availableRoles,
    updateUserRoleHandler,
    changeRolePayload,
    handleUpdateUserSelectChange}) {
    return (
        <>
        <h5 className="font-semibold text-center text-xl">Change Role</h5>
        <p className="text-sm text-center text-black my-4 w-4/5 mx-auto">This action will change the role of this user. This user will no longer have the access to the previous role.</p>

        <div className="px-12">
            <Select name="roleId" value={availableRoles?.filter(availableRole => availableRole.value === changeRolePayload.roleId)} name="roleId" onChange={(e)=>handleUpdateUserSelectChange(e, "roleId")} className="w-full h-12 my-3 rounded text-md" placeholder={<p>Select New Role For This User</p>} options={availableRoles} />

            <button onClick={updateUserRoleHandler} className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded">Transfer</button>
        </div>
    </>
  )
}

export default UpdateUserInvite
