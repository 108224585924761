

import React, {useState, useEffect} from 'react';
import FetchApi from '../utils/FetchApi';

export const AuthContext = React.createContext();

export function ProtectedRouteProvider({children}) {
    const [status, setStatus] = useState(sessionStorage.getItem("isLoggedin"));
    
    useEffect(() => {
        let token = sessionStorage.getItem("token");
        let userData = sessionStorage.getItem("userData");
        let merchantId = sessionStorage.getItem("merchantId");
        let isCorporate = sessionStorage.getItem("isCorporate");
        
        if (token && userData && isCorporate && merchantId !== "undefined") {
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const id = JSON.parse(sessionStorage.getItem("userData")).id;
            const response = FetchApi({ endpoint: `/auth-service/api/v1/profile/${id}`, authorization: authorization, method: "GET" });
            response.then(resp => {
                sessionStorage.setItem("businessName", resp.otherDetails.organisationName);
                sessionStorage.setItem("otherInfo", JSON.stringify(resp))
                setStatus(true);
            }).catch(err => {
                setStatus(false);
            })
        } else {
            setStatus(false);
        }
    }, []);

    return (
        <AuthContext.Provider value={[status, setStatus]}>
            {children}
        </AuthContext.Provider>
    )
}


