

import {httpGet, httpPut, httpPost, httpPostFormData} from "../https";
import {disputeService} from "../index";

export const getAllDispute = (merchantId) => {
    return httpGet(`${disputeService}/filter-search?size=10000&order=DESC`);
}

export const getDisputeStats = (merchantId) => {
    return httpGet(`${disputeService}/report/stats?merchantId=${merchantId}`);
}

export const acceptDispute = (merchantDisputeId, payload) => {
    return httpPut(`${disputeService}/accept-dispute/${merchantDisputeId}`, payload);
}

export const rejectDispute = (merchantDisputeId, payload) => {
    return httpPostFormData(`${disputeService}/reject-dispute/${merchantDisputeId}`, payload);
}
