import React, { useState, useEffect, useContext } from 'react';
import {Link} from "react-router-dom";
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Main from '../components/Main';
import FetchApi from '../utils/FetchApi';
import { FailedIcon } from '../assets/index.js';

function Dashboard({ user, merchantId }) {
    const [sidebarState, setSidebarState] = useState("hidden");
    const toggleSidebar = () => {
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }    
    return (
        <div className="body-container">
            <Sidebar toggleSidebar={toggleSidebar}  sidebarState={sidebarState}/>
            <div className="">
                <Navbar pageName="Get Started" toggleSidebar={toggleSidebar} />
                <Main/>
            </div>
        </div>
    )
}

export default Dashboard
