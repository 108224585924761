


import { httpGet, httpPost } from "../https";
import { identityService, paymentGateway } from "../index";

export const getAllPaymentLink = (page) => {
    return httpGet(`${identityService}/webpos/payment-link/filter-search?size=1000&order=DESC`);
}

export const createPaymentLink = (payload) => {
    return httpPost(`${identityService}/webpos/payment-link`, payload);
}

export const getSubscribersDetails = (payload) => {
    return httpGet(`${paymentGateway}/transactions/recurrent/fetchRecurrentTransactions?field=paymentLinkId&merchantId=${payload?.merchantId}&pageNo=1&pageSize=20&value=${payload?.paymentLink}`);
}

export const getPaymentLinkAnalysis = (payload) => {
    return httpGet(`${paymentGateway}/transactions/recurrent/paymentLinkAnalysis?merchantId=${payload?.merchantId}&paymentLinkId=${payload?.paymentLink}`);
}
