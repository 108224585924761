import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import SubscriptionInner from '../components/Subscription';

function Subscription() {
    const [sidebarState, setSidebarState] = useState("hidden");
    const toggleSidebar = () => {
        // alert(0)
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }
    return (
        <div sidebarState={sidebarState} className="body-container">
            <Sidebar toggleSidebar={toggleSidebar} sidebarState={sidebarState}/>
            <div className="relative">
                <Navbar pageName="Subscription" toggleSidebar={toggleSidebar}/>
                <SubscriptionInner />
            </div>
        </div>
    )
}

export default Subscription;
