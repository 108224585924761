import { httpDelete, httpGet, httpPost } from '../https';
import { identityService, paymentGateway } from '../index';

export const getAllSubscription = () => {
	return httpGet(`${identityService}/webpos/customer-subscription/filter-search?size=1000&order=DESC`);
};

export const createSubscription = (payload) => {
	return httpPost(`${identityService}/webpos/customer-subscription`, payload);
};

export const getSubscriptionStats = (merchantId) => {
	return httpGet(`${paymentGateway}/transactions/recurrent/analysis?merchantId=${merchantId}`);
};

export const getSubscribersDetails = (payload) => {
    return httpGet(`${identityService}/webpos/customer-subscription/subscription/${payload?.customerSubscriptionId}?merchantId=${payload?.merchantId}`);
}

export const cancelSubscription = (customerSubscriptionId) => {
    return httpDelete(`${identityService}/webpos/customer-subscription/${customerSubscriptionId}`);
}
