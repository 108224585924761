import React from 'react'

function SmallCard({ title, value }) {
    return (
        <div className="flex justify-center hover:shadow-md transition duration-500 transition-shadow items-center flex-col w-full h-36 md:h-20 border border-gray-200 rounded-lg">
            <h5 className="text-xs text-left">{title} </h5>
            <h3 className="font-semibold text-xl mt-3">{value || value === 0 ? value : <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</h3>
        </div>
    )
}

export default SmallCard
