
import axios from "axios";

export const httpGet = async(url, isNotAuth) => {
    if (!navigator.onLine) {
        throw ({
            response: {
                data: {
                    error: 'Please check your internet'
                }
            }
        });
    }
    try {
        const res = await axios.get(`${url}`,
        !isNotAuth
            ? {
                headers: {
                    'content-type': 'application/json',
                    authorization: JSON.parse(window.sessionStorage.getItem("token")),
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                },
            }
            : {}
        );
        return res.data;
    } catch (error) {
        return {
            status: false,
            message: error.response?.data.message
        }
    }
}



export const httpPut = async(url, payload, isNotAuth) => {
    if (!navigator.onLine) {
        throw ({
            response: {
                data: {
                    error: 'Please check your internet'
                }
            }
        });
    }
    try {
        const res = await axios.put(`${url}`, !payload ? {} : payload,
        !isNotAuth
            ? {
                headers: {
                    'content-type': 'application/json',
                    authorization: JSON.parse(window.sessionStorage.getItem("token")),
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                },
            }
            : {}
        );
        return res.data;
    } catch (error) {
        return {
            status: false,
            message: error.response?.data
        }
    }
}


export const httpPost = async(url, payload, isNotAuth) => {
    if (!navigator.onLine) {
        throw ({
            response: {
                data: {
                    error: 'Please check your internet'
                }
            }
        });
    }
    try {
        const res = await axios.post(`${url}`, !payload ? {} : payload,
        !isNotAuth
            ? {
                headers: {
                    'content-type': 'application/json',
                    authorization: JSON.parse(window.sessionStorage.getItem("token")),
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                },
            }
            : {}
        );
        return res.data;
    } catch (error) {
        return {
            status: false,
            message: error.response?.data.message
        }
    }
}


export const httpPatch = async(url, payload, isNotAuth) => {
    if (!navigator.onLine) {
        throw ({
            response: {
                data: {
                    error: 'Please check your internet'
                }
            }
        });
    }
    try {
        const res = await axios.patch(`${url}`, !payload ? {} : payload,
        !isNotAuth
            ? {
                headers: {
                    'content-type': 'application/json',
                    authorization: JSON.parse(window.sessionStorage.getItem("token")),
                    'CLIENT-ID': 'WAYAQUICK',
                     'CLIENT-TYPE': 'CORPORATE',
                },
            }
            : {}
        );
        return res.data;
    } catch (error) {
        return {
            status: false,
            message: error.response?.data.message
        }
    }
}


export const httpDelete = async(url, isNotAuth) => {
    if (!navigator.onLine) {
        throw ({
            response: {
                data: {
                    error: 'Please check your internet'
                }
            }
        });
    }
    try {
        const res = await axios.delete(`${url}`,
        !isNotAuth
            ? {
                headers: {
                    'content-type': 'application/json',
                    authorization: JSON.parse(window.sessionStorage.getItem("token")),
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                },
            }
            : {}
        );
        return res.data;
    } catch (error) {
        return {
            status: false,
            message: error.response?.data.message
        }
    }
}


export const httpPostFormData = async(url, payload, isNotAuth) => {
    if (!navigator.onLine) {
        throw ({
            response: {
                data: {
                    error: 'Please check your internet'
                }
            }
        });
    }
    try {
        const res = await axios.post(`${url}`, !payload ? {} : payload,
        !isNotAuth
            ? {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: JSON.parse(window.sessionStorage.getItem("token")),
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                },
            }
            : {}
        );
        return res.data;
    } catch (error) {
        return {
            status: false,
            message: error.response?.data.message
        }
    }
}

