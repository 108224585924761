import React, { useState } from 'react';
import RoleAccessInner from '../components/RoleAccess';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

function Referral() {
	const [ sidebarState, setSidebarState ] = useState('hidden');
	const toggleSidebar = () => {
		setSidebarState(sidebarState === 'hidden' ? 'block w-3/4 top-8 ...' : 'hidden');
	};
	return (
		<div className="body-container">
			<Sidebar toggleSidebar={toggleSidebar} sidebarState={sidebarState} />
			<div className="relative">
				<Navbar pageName="Role and access" toggleSidebar={toggleSidebar} />
				<RoleAccessInner />
			</div>
		</div>
	);
}

export default Referral;
