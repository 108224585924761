import React from 'react';
import styles from './styles.module.css';

function InnerOption2({ title, status, action }) {
    return (
        <div className="w-full h-12 relative rounded shadow flex justify-between items-center px-3 mb-3">
            <h5 className="font-medium text-sm">{title}</h5>

            <label className={`${styles.custom_switch} relative w-12 h-6 rounded-full bg-gray-400`}>
                <input type="checkbox" onChange={action} defaultChecked={status} className="hidden" />
                <span className={`${styles.circle} w-4 h-4 left-1 bg-black rounded-lg block absolute`}></span>
            </label>
        </div>
    )
}

export default InnerOption2
