import React from 'react';
import { Link } from 'react-router-dom';
import SmallList from './SmallList';

function LoginTab() {
    return (
        <>
            <h4 className="mt-5 mb-3">LoginTab</h4>
            <SmallList title="Location" description="Ikeja, Lagos Nigeria" />
            <SmallList title="Device" description="Iphone X" />
            <SmallList title="Time" description="1:54PM" />

            <section className="w-full h-auto pb-2 bg-white border border-gray-200 hover:shadow rounded">
                <div className="flex mb-1 justify-between px-3 py-2">
                    <h5 className="text-md font-semibold">TIme Log</h5>
                    <Link to="/#" className="text-sm text-primary-theme">View all</Link>
                </div>
                <div className="flex flex-col px-3 py-2">
                    <h5 className="text-sm font-medium flex justify-between">
                        <span>Wed.19 oct 2020</span>
                        <span>05:55 pm</span>
                    </h5>
                    <h5 className="text-xs w-full flex justify-between">
                        <span>12:00pm</span>
                        <span>Logged out</span>
                    </h5>
                </div>
                <div className="flex flex-col px-3 py-2">
                    <h5 className="text-sm font-medium flex justify-between">
                        <span>Wed.19 oct 2020</span>
                        <span>05:55 pm</span>
                    </h5>
                    <h5 className="text-xs w-full flex justify-between">
                        <span>12:00pm</span>
                        <span>Logged out</span>
                    </h5>
                </div>
                <div className="flex flex-col px-3 py-2">
                    <h5 className="text-sm font-medium flex justify-between">
                        <span>Wed.19 oct 2020</span>
                        <span>05:55 pm</span>
                    </h5>
                    <h5 className="text-xs w-full flex justify-between">
                        <span>12:00pm</span>
                        <span>Logged out</span>
                    </h5>
                </div>
            </section>
        </>
    )
}

export default LoginTab
