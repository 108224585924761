import React from 'react';
import DetailGrid from "./DetailGrid";

function DetailsBox({selectedActivity}) {
  return (
    <div className="w-full p-5">
      <div className="w-full h-auto border border-gray-300 p-5 rounded-lg">
        <h4 className="text-md font-semibold">About this activity</h4>
        <div className="grid grid-cols-3">
            <DetailGrid title="User" value={selectedActivity?.userId} />
            <DetailGrid title="Email" value={selectedActivity?.email} />
            <DetailGrid />
            <DetailGrid title="Type" value={selectedActivity?.type} />
            <DetailGrid title="I.P. Address" value={selectedActivity?.ipAddress} />
            <DetailGrid title="Date" value={selectedActivity?.createdAt} />
        </div>
        <button className="mt-4 text-xs bg-gray-100 border border-gray-200 px-3 py-2 rounded">
            View plan
        </button>
      </div>
    </div>
  )
}

export default DetailsBox
