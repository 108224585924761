import React from 'react'

function FAB() {
    return (
        <>
        {/* // <button className="fixed bottom-8 right-8 md:bottom-8 md:right-20">
        //     <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        //         <circle cx="28" cy="28" r="28" fill="#2626BC" />
        //         <g clipPath="url(#clip0_29_12710)">
        //             <path d="M22.997 14.5601C22.4575 14.5601 21.9402 14.7743 21.5588 15.1558C21.1773 15.5372 20.963 16.0545 20.963 16.594V19.7658H33.4824C34.6506 19.7658 35.597 20.7234 35.597 21.905V31.0935H38.8517C39.119 31.0936 39.3837 31.0411 39.6306 30.9389C39.8776 30.8367 40.102 30.6868 40.291 30.4978C40.48 30.3088 40.6299 30.0844 40.7321 29.8374C40.8343 29.5904 40.8869 29.3257 40.8867 29.0585V16.594C40.8867 16.3268 40.8341 16.0622 40.7318 15.8154C40.6295 15.5685 40.4796 15.3443 40.2906 15.1554C40.1016 14.9665 39.8773 14.8167 39.6304 14.7146C39.3835 14.6124 39.1189 14.5599 38.8517 14.5601H22.997ZM17.1483 20.3258C16.881 20.3257 16.6163 20.3782 16.3694 20.4804C16.1224 20.5826 15.898 20.7325 15.709 20.9215C15.52 21.1105 15.3701 21.3349 15.2679 21.5819C15.1657 21.8289 15.1131 22.0936 15.1133 22.3609V40.4209C15.1133 41.3281 16.2098 41.7817 16.8493 41.1399L21.0482 36.9421H33.003C33.5425 36.9421 34.0598 36.7279 34.4412 36.3464C34.8227 35.965 35.037 35.4476 35.037 34.9082V22.3609C35.0371 22.0937 34.9846 21.8291 34.8825 21.5822C34.7803 21.3353 34.6305 21.1109 34.4416 20.9219C34.2528 20.733 34.0285 20.583 33.7816 20.4807C33.5348 20.3785 33.2702 20.3258 33.003 20.3258H17.1472H17.1483Z" fill="white" />
        //             <ellipse cx="20.7201" cy="28.5599" rx="1.12" ry="1.12" fill="#2626BC" />
        //             <ellipse cx="29.6801" cy="28.5599" rx="1.12" ry="1.12" fill="#2626BC" />
        //             <ellipse cx="25.2001" cy="28.5599" rx="1.12" ry="1.12" fill="#2626BC" />
        //         </g>
        //         <defs>
        //             <clipPath id="clip0_29_12710">
        //                 <rect width="26.88" height="26.88" fill="white" transform="translate(14.5601 14.5601)" />
        //             </clipPath>
        //         </defs>
        //     </svg>
        // </button> */}
        </>
    )
}

export default FAB
