import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { EmptyFolderIcon } from '../assets';

function Table({ pageName, checkboxSelection=true, tableColumnStructure, tableRowData, page, size, height }) {
    const columns = tableColumnStructure;

    // const handleChangePage = (pageIndex) => {
    //     setPage(pageIndex)
    // }

    const rows = tableRowData;

    return (
        <div className="px-6 pt-6">
            <div style={{ height: height? height : 350, width: '100%' }} className="border border-gray-300 rounded-lg">
                {
                    tableRowData
                        ?
                        <DataGrid
                            // onPageChange={handleChangePage}
                            rows={rows}
                            columns={columns}
                            pageSize={size ? size : 10}
                            rowsPerPageOptions={[5]}
                            checkboxSelection={checkboxSelection}
                        />
                        :
                        <>
                            <div className="px-6 mt-3 w-full h-80 flex flex-col items-center justify-center">
                                <EmptyFolderIcon />
                                <h4 className="text-center text-2xl font-semibold mt-5">No {pageName} Found</h4>
                                <p className="text-gray-400 text-sm text-center">No {pageName} were found for the selected duration and criteria!</p>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default Table
