

import {httpGet} from "../https";
import {paymentGateway} from "../index";

export const getAllTransactions = (merchantId) => {
    return httpGet(`${paymentGateway}/transactions/search?merchantId=${merchantId}?size=1000000&order=DESC`);
}
export const filterTransactions = (url) => {
    return httpGet(`${paymentGateway}/${url}`);
}
export const getRevenueStats = (merchantId) => {
    return httpGet(`${paymentGateway}/transactions/report/revenue-stats?merchantId=${merchantId}`);
}

export const getRevenueSummary = (merchantId) => {
    return httpGet(`${paymentGateway}/revenue/query/${merchantId}`)
}

export const getCustomerTransactions = (customerId) => {
    return httpGet(`${paymentGateway}/transactions/${customerId}`)
}