import { httpGet } from '../https';
import { referralService } from '../index';

export const getAllReferredUsers = (referralCode) => {
	return httpGet(`${referralService}/api/v1/admin/get-users-that-have-been-referred/${referralCode}`);
};

export const getReferrerEarning = (id) => {
	return httpGet(`${referralService}/api/v1/admin/get-users-total-referral-earnings/${id}?size=1000&order=DESC`);
};

