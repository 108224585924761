import axios from 'axios';
import config from '../config.js';
import { ToastContainer, toast } from 'react-toastify';

const FetchApi = async ({ endpoint, payload, authorization, method }) => {
    try {
        if (method === "PATCH") {
            const API_PATH = `${config.BASE_URL}${endpoint}`;
            const header = {
                headers: {
                    // "Content-Type": 'application/json',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            //  console.log(API_PATH, payload, header)
            const response = await axios.patch(API_PATH, payload, header);
            return response.data.data;
        } else if (method === "GET") {
            const API_PATH = `${config.BASE_URL}${endpoint}`;
            const header = {
                headers: {
                    "Content-Type": 'application/json',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            //  console.log(header, API_PATH)
            const response = await axios.get(API_PATH, header);
            return response.data.data;
        }else if (method === "POST") {
            const API_PATH = `${config.BASE_URL}${endpoint}`;
            const header = {
                headers: {
                    "Content-Type": 'application/json',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                     'CLIENT-TYPE': 'CORPORATE',
                }
            }
            //  console.log(header, API_PATH)
            const response = await axios.post(API_PATH, payload, header);
            return response.data.data;
        }else if (method === "PUT") {
            const API_PATH = `${config.BASE_URL}${endpoint}`;
            const header = {
                headers: {
                    // "Content-Type": 'application/json',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            const response = axios.put(API_PATH, payload, header)
            // const response = axios.put(API_PATH, payload, header).then(resp=>{
            //     toast("SUCCESS");
            //     setTimeout(() => {
            //         window.location.reload();
            //     }, 2000);
            // }).catch(err=> {
            //     toast.error(err.response.data.details[0]);
            // });
            return response;
        }
    } catch (error) {
        return error;
    }
}

export default FetchApi
