

import {httpDelete, httpGet, httpPatch, httpPost} from "../https";
import {identityService} from "../index";

export const getAllPlan = () => {
    return httpGet(`${identityService}/webpos/plan/filter-search?size=1000&order=DESC`);
}

export const createPlan = (payload) => {
    return httpPost(`${identityService}/webpos/plan`, payload);
}

export const deletePlan = (planId) => {
    return httpDelete(`${identityService}/webpos/plan/${planId}`);
}

export const updatePlan = (payload) => {
    return httpPatch(`${identityService}/webpos/plan`, payload);
}

export const getSinglePlan = (planId) => {
    return httpGet(`${identityService}/webpos/plan/fetch/${planId}`);
}
