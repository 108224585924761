import React, {useEffect, useState, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FAB from '../../components/FAB.jsx';
import { WayaPayLogo1, Icon } from '../../assets/index.js';
import Input from '../../components/Input';
import Button from '../../components/Button';
import OtpInput from '../../components/OtpInput.jsx';
import axios from "axios";
import config from '../../config.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ForgetPassword() {
    const otpTab = useRef(null);
    const emailTab = useRef(null);
    const [otp1, setOTP1] = useState(null);
    const [otp2, setOTP2] = useState(null);
    const [otp3, setOTP3] = useState(null);
    const [otp4, setOTP4] = useState(null);
    const [otp5, setOTP5] = useState(null);
    const [otp6, setOTP6] = useState(null);
    const [password, setPassword] = useState(null);
    const [tab, setTab] = useState(1);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const navigate = useNavigate();

    function nextHandlerTo(id) {
        setLoading(true);
        if (email) {
            RetrievePassword(id);
            setLoading(false);
        } else {
            setErrMsg("Email address field is required");
            toast("Email address field is required");
            setLoading(false);
        }
    }


    const SetEmail = (e) => {
        setEmail(e.target.value);
    }
    const SetNewPassword = (e) => {
        setPassword(e.target.value);
    }

    const changeForgottenPwd = () => {
        setLoading(true);
        let API_ENDPOINT="https://services.wayapay.ng/auth-service/api/v1/password/forgot-password";
        const changePwdData = {
            newPassword: password,
            otp: otp1+otp2+otp3+otp4+otp5+otp6,
            phoneOrEmail: email
        }
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        axios.post(API_ENDPOINT, changePwdData, configuration).then(response=>{
            setErrMsg(null);
            setLoading(false);
            toast("Password changed!");
            setTimeout(() => {
                navigate("/login");
            }, 2500);
        }).catch(err=>{
            if(err.toJSON().message === 'Network Error'){
                toast("Check your network connection");
                setLoading(false);
                setErrMsg("Check your network and retry.");
            }
            if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                setErrMsg("Service timeout. Please retry");
                toast("Error: Service timeout. Please retry");
                setLoading(false)
            }else{
                setErrMsg(err.response.data.message);
                toast(err.response.data.message);
                setLoading(false);
            }
        });
    }

    const ResendHandler = () => {
        setLoading(true);
        let API_ENDPOINT= "https://services.wayapay.ng/auth-service/api/v1/password/forgot-password/byEmail?email="+email+"&redirectUrl=undefined";
        
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }

        axios.get(API_ENDPOINT, configuration).then(response=>{
            setErrMsg(null);
            toast("OTP resent!");
            setLoading(false);
            // navigate("/dashboard");
        }).catch(err=>{
            if(err.toJSON().message === 'Network Error'){
                toast("Check your network connection");
                setLoading(false);
                setErrMsg("Check your network and retry.");
            }
            if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                setErrMsg("Service timeout. Please retry");
                toast("Error: Service timeout. Please retry");
                setLoading(false)
            }else{
                setErrMsg(err.response.data.message);
                toast(err.response.data.message);
                setLoading(false);
            }
        })
    }

    const RetrievePassword = (id) => {
        setLoading(true);
        let API_ENDPOINT = "https://services.wayapay.ng/auth-service/api/v1/password/forgot-password/byEmail?email="+email+"&redirectUrl=undefined";
        
        // console.log("https://services.wayapay.ng/auth-service/api/v1/password/forgot-password/byEmail?email=blessingladejobi@gmail.com&redirectUrl=undefined")
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        axios.get(API_ENDPOINT, configuration).then(response => {
            toast("OTP sent!");
            setTab(2);
            setLoading(false);
            setErrMsg(null);
        }).catch(err => {
            if(err.toJSON().message === 'Network Error'){
                toast("Check your network connection");
                setLoading(false);
                setErrMsg("Check your network and retry.");
            }
            if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                setErrMsg("Service timeout. Please retry");
                toast("Error: Service timeout. Please retry");
                setLoading(false)
            }else{
                setErrMsg(err.response.data.message);
                toast(err.response.data.message);
                setLoading(false);
            }
        });
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="onboarding-background w-full h-auto flex items-start justify-center">
                <div className="onboarding-form-container bg-white border-t-4 border-primary-theme pb-12">
                    <div className="flex flex-col items-center pt-10 px-6">
                        <Link to="/"><WayaPayLogo1 /></Link>

                        <h4 className="title font-semibold text-xl text-gray-500 mt-10 mb-6">Forgot Password?</h4>

                    </div>
                    <div className="input-wrapper px-10">
                        {
                            tab === 1 && (
                                <div ref={emailTab} className="form-step form-step-1">
                                    <p className="text-sm mb-10 text-center text-gray-500">Please enter your email to reset your password associated with your wayapay account</p>
                                    <Input label="Email address" name="email" value={email} onChange={SetEmail} placeholder="Enter Email Address" type="email" />

                                    <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                                    ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

                                    <Button text="Reset Password" id="1" nextHandlerTo={nextHandlerTo} />

                                    <p className="text-sm text-center text-gray-500"><Link to="/login" className="">Back to Sign in</Link></p>

                                    <p className="text-sm text-center text-gray-400 mt-8">Don't have an account? <Link to="/login" className="text-gray-900 font-medium">Sign up instead</Link></p>
                                </div>
                            )
                        }

                        {
                            tab === 2 && (
                                <div ref={otpTab} className="form-step form-step-2">
                                <p className="text-gray-500 text-md px-12 mb-8 text-center">Please Input the OTP sent to your email address.</p>
                                
                                <OtpInput otp1={otp1} otp2={otp2} otp3={otp3} otp4={otp4} otp5={otp5} otp6={otp6} setOTP1={setOTP1} setOTP2={setOTP2} setOTP3={setOTP3} setOTP4={setOTP4} setOTP5={setOTP5} setOTP6={setOTP6} />
                                
                                <Input name="password" label="Password" value={password} onChange={SetNewPassword} placeholder="Enter New Password" type="password" icon={<Icon />}/>
                                
                                <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                                ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

                                <Button text="Log in" id="2" nextHandlerTo={changeForgottenPwd}/>
                                <p className="text-primary-theme mb-5 text-sm px-12 text-center cursor-pointer" onClick={ResendHandler}>Did not get OTP?</p>
                            </div>
                            )
                        }
                    </div>
                </div>

                <FAB />
            </div>
        </React.Fragment>
    )
}


export default ForgetPassword;
