import React, { useState, useRef, useEffect } from 'react';
import { CopyIcon } from '../../assets';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllReferredUsers, getReferrerEarning} from '../../services/requests/referral';
import {Link} from 'react-router-dom';

function ReferalTab() {
    const [refferalCode, setRefferalCode] = useState("");
    const [totalEarning, setTotalEarning] = useState("0");
    const [totalRefer, setTotalRefer] = useState("0");
    const snackbar = useRef();

    useEffect(()=>{
        let referenceCode;
        if(JSON.parse(sessionStorage.getItem("otherInfo"))){
            // referenceCode = JSON.parse(sessionStorage.getItem("otherInfo")).referral;
            referenceCode = JSON.parse(sessionStorage.getItem("otherInfo")).referenceCode;
        }else{
            referenceCode=JSON.parse(sessionStorage.getItem("userData")).referenceCode;
        }
        const userId = JSON.parse(sessionStorage.getItem("userData")).id;
        setRefferalCode(referenceCode);

        getAllReferredUsers(referenceCode).then(response => {
            setTotalRefer(response.data.totalItems);
        }).catch(error => {
            console.log("Error occured")
        });

        getReferrerEarning(userId).then(response => {
            setTotalEarning(response.data);
        }).catch(error => {
            console.log("Error occured")
        });
    },[]);

    const animateSnackbar = () => {
        snackbar.current.classList.add("show-snackbar");
        setTimeout(() => {
            snackbar.current.classList.remove("show-snackbar");
        }, 3000)
    }
    const copyRefferalCode = () => {
        navigator.clipboard.writeText(refferalCode);
        toast("Referral Code copied.");
    }
    return (
        <><ToastContainer />
            <h4 className="mt-5 mb-3">Referal</h4>
            <div className="w-full h-auto shadow rounded border border-gray-200 mt-3 bg-white grid grid-cols-2">
                <div className="w-full px-5 py-14">
                    <h5 className="font-semibold text-lg">Invite your friends and Earn</h5>
                    <p className="text-gray-400 text-xs">Invite your friends and earn for yourself when they complete registration and start performing transactions</p>
                </div>
                <div className="w-full px-5 py-10">
                    <div className="w-full bg-gray-50 h-20 mb-3 py-3 relative border border-gray-200 rounded px-2">
                        <label className="text-sm flex justify-between">
                            <span>Total Earning</span>
                            <span>Total Signups</span>
                        </label>
                        <h5 className="font-semibold text-xl flex justify-between">
                            <span>₦{totalEarning}</span>
                            <span>{totalRefer}</span>
                        </h5>
                    </div>

                    <div className="w-full bg-white h-20 py-3 relative border border-gray-200 rounded px-2">
                        <label className="text-sm">Refferal Code</label>
                        <h5 className="font-semibold text-xl">{refferalCode}</h5>

                        <span className="absolute right-4 top-6" onClick={copyRefferalCode}>
                            <CopyIcon />
                        </span>
                    </div>

                    <Link to="/setting/view-referral" className="text-sm flex pt-3 text-primary-theme underline">View Details</Link>
                </div>
            </div>
        </>
    )
}

export default ReferalTab
