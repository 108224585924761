


import {httpGet, httpPut} from "../https";
import {authService} from "../index";
import config from '../../config.js';

// export const getAllPlan = () => {
//     return httpGet(`${identityService}/webpos/plan/filter-search?size=1000&order=DESC`);
// }

export const updateProfile = (userId, payload) => {
    return httpPut(`${config.BASE_URL}/auth-service/api/v1/profile/update-personal-profile/${userId}`, payload);
}

