import React from 'react'

function DetailGrid({title, value}) {
  return (
    <div className="detailGrid w-full pt-5">
        <p className="text-sm text-gray-400">{title}</p>
        <h5 className="text-sm">{value}</h5>
    </div>
  )
}

export default DetailGrid
