

import React, {useEffect, useState} from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import OverviewInner from '../components/Overview';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FetchApi from '../utils/FetchApi';
import axios from 'axios';
import config from '../config.js';


function Overview({ user, merchantId }) {
    const [sidebarState, setSidebarState] = useState("hidden");
    const [revenueStats, setRevenueStats] = useState(null);
    const [statusStats, setStatusStats] = useState(null);
    const [settlement, setSettlement] = useState(null);
    const [yearMonthStats, setYearMonthStats] = useState(null);
    
    const [revenueSummary, setRevenueSummary] = useState(null);
    const [overviewStats, setOverviewStats] = useState(null);

    const [successError, setSuccessError] = useState(null);
    const [paymentChannels, setPaymentChannels] = useState(null);
    

    const toggleSidebar = () => {
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }

    useEffect(() => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
        const ENDPOINT = `${config.BASE_URL}/payment-gateway/api/v1/transactions/report/revenue-stats?merchantId=${merchantId}`;
        const header = {
            headers: {
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        axios.get(ENDPOINT, header).then(response=> {
            setRevenueStats(response?.data?.data);
            setStatusStats(response?.data?.data?.statusStats);
            setSettlement(response?.data?.data?.settlementStats);
            setYearMonthStats(response?.data?.data?.yearMonthStats);
            setSuccessError(response?.data?.data?.successErrorStats);
            setPaymentChannels(response?.data?.data?.paymentChannelStats);
            // alert(JSON.stringify(response?.data?.data?.successErrorStats))
        //    const completed =  response?.data?.data?.statusStats.filter(element => element.status === "TRANSACTION_COMPLETED");
            toast("Success")
        }).catch(err=> {
            console.log(err);
            toast("Error fetching transaction summary.")
        });

        
        // const authorization = JSON.parse(sessionStorage.getItem("token"));
        // const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));

        const response3 = FetchApi({ endpoint: `/payment-gateway/api/v1/revenue/query/${merchantId}`, authorization: authorization, method: "GET" });
        response3.then(resp => {
            //  console.log(resp);
            setOverviewStats(resp);
        }).catch(err => {
            console.log(err)
        });
        
        
    }, []);

    return (
        <>
        <ToastContainer />
        <div className="body-container">
            <Sidebar  sidebarState={sidebarState}/>
            <div className="">
                <Navbar toggleSidebar={toggleSidebar} pageName={`Total transaction: ${overviewStats? overviewStats?.totalTransaction : 0}`}/>
                <OverviewInner successError={successError} paymentChannels={paymentChannels} revenueStats={revenueStats} overviewStats={overviewStats} statusStats={statusStats} yearMonthStats={yearMonthStats} settlement={settlement}/>
            </div>
        </div>
        </>
    )
}

export default Overview;
