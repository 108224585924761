import React, { useState, useContext } from 'react'
import { ActivateAccountIcon, ExploreDashboardIcon, Contact4HelpIcon, CloseIcon, AndroidIcon, IOSIcon } from '../assets';
import {Link} from "react-router-dom";
import {ApikeysContext} from "../context/ApikeysContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../config.js";

function Main() {
    // const [apiKey, setapiKey] = useContext(ApikeysContext);
    const { apiKey, setApiKey, modeStatus, approvalStatus} = useContext(ApikeysContext);
    return (
        <React.Fragment>
         <ToastContainer />
            <div className="px-8 flex flex-col items-center">
                <h4 className="text-xl text-center font-medium text-gray-600 mt-8">Welcome to WayaQuick web merchant dashboard</h4>
                {
                    modeStatus=== "TEST" && (
                        <p className="mt-1 text-center text-sm inline-block bg-yellow-50 text-yellow-900 rounded-lg px-2 py-3 ...">You can explore and experience our products before you activate your account. <span className="text-yellow-700 underline cursor-pointer" title="check the toggle button at the top navigation bar">To start real-time transaction. Switch to Live Mode</span></p>
                    )
                }
            </div>

            <div className="grid sm:grid-cols-2 md:grid-cols-3 mt-10 px-10 md:px-12 lg:px-10 xl:px-28 gap-5">
                {
                    (!approvalStatus || approvalStatus === "DRAFT" || approvalStatus === "REJECTED") && (
                        <div className="w-full h-auto flex-col items-center flex py-6 rounded-xl shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                            <div className="h-16 flex justify-center">
                                <ActivateAccountIcon />
                            </div>
                            <h5 className="text-gray-500 h-20 mt-4 text-sm px-3 text-center">Submit a few KYC details to start accepting payments and receive settlement in your account</h5>
                            <div className="w-full px-5 h-12 mt-5">
                                <Link to="/setting/kyc" className="py-3 rounded-full w-full flex justify-center items-center bg-primary-theme text-white font-medium text-sm">Activate Account</Link>
                            </div>
                        </div>
                    )
                }
                <div className="w-full h-auto flex-col items-center flex py-6 rounded-xl shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                    <div className="h-16 flex justify-center">
                        <ExploreDashboardIcon />
                    </div>
                    <h5 className="text-gray-500 h-20 mt-4 text-sm px-3 text-center">You can do a quick walk-through of WayaQuick and how it can help your business by exploring the dashboard.</h5>
                    <div className="w-full px-5 h-12 mt-5">
                        <Link to="/overview" className="py-3 rounded-full w-full flex justify-center items-center bg-primary-theme text-white font-medium text-sm">Explore the Dashboard</Link>
                    </div>                    
                </div>
                <div className="w-full h-auto flex-col items-center flex py-6 rounded-xl shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                    <div className="h-16 flex justify-center">
                        <Contact4HelpIcon />
                    </div>
                    <h5 className="text-gray-500 h-20 mt-4 text-sm px-3 text-center">Find answers to questions you might have or get in touch</h5>
                    <div className="w-full px-5 h-12 mt-7">
                        <a href= "https://wayaquick.com/contact" className="py-3 rounded-full w-full flex justify-center items-center bg-primary-theme text-white font-medium text-sm">Contact for help</a>
                    </div>
                </div>
            </div>

            <div className="px-8 md:px-12 lg:px-12 xl:px-28 mt-8 block md:flex justify-between">
                <div className="w-full md:w-2/5">
                    <h4 className="text-md font-semibold">API Documentation</h4>
                    <p className="text-gray-600 mt-3 text-sm">Our documentation contains the Libraries, APIs, and SDKs you need to integrate Wayapaychat payment gateway in your website or app.</p>
                    <a href={`${config?.WEBSITE_URL2}/get-started`} target="_blank">
                        <button className="py-3 mt-3 rounded-lg inline-block px-5 bg-gray-700 text-white font-medium text-sm">View Docs</button>
                    </a>
                </div>
                <div className="w-full mt-8 md:mt-0 md:w-2/5">
                    <h4 className="text-xl font-semibold">Your Test Keys</h4>
                    <p className="text-gray-500 text-sm">Also available in<span className="text-primary-theme"> Settings > API Keys & Webhooks</span></p>

                    <div className="w-full grid grid-cols-4 mt-5">
                        <label className="bg-gray-700 text-center py-3 rounded text-xs text-white font-medium">Test Secret</label>
                        <label className="col-span-2 border-t border-b border-gray-200 flex items-center px-1 text-sm text-gray-600 truncate">{apiKey && apiKey.activeSecretKey}</label>
                        <label className="bg-gray-300 text-black hover:text-white hover:bg-gray-700 text-center py-3 rounded text-xs font-medium cursor-pointer" onClick={() => {
                            navigator.clipboard.writeText(`${apiKey.activeSecretKey}`);
                            toast("Secret key copied!");
                        }}>Copy</label>
                    </div>
                    <div className="w-full grid grid-cols-4 mt-2">
                        <label className="bg-gray-700 text-center py-3 rounded text-xs text-white font-medium">Test Public</label>
                        <label className="col-span-2 border-t border-b border-gray-200 flex items-center px-1 text-sm text-gray-600 truncate ">{apiKey && apiKey.activePublicKey}</label>
                        <label className="bg-gray-300 text-black hover:text-white hover:bg-gray-700 text-center py-3 rounded text-xs font-medium cursor-pointer" onClick={() => {
                            navigator.clipboard.writeText(`${apiKey.activePublicKey}`);
                            toast("Public key copied!");
                        }}>Copy</label>
                    </div>
                </div>
            </div>

            <div className="grid mb-20 sm:grid-cols-2 md:grid-cols-3 mt-16 px-10 md:px-12 lg:px-10 xl:px-28 gap-5">
                <div className="w-full relative h-auto flex-col items-center border border-gray-100 flex py-6 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                    {/* <button className="absolute -right-3 -top-3">
                        <CloseIcon />
                    </button> */}
                    <h5 className="text-2xl font-semibold">Explore Wayabank</h5>
                    <p className="text-gray-500 h-24 mt-4 text-md px-3 text-center">Discover the best way to to carry your financial transactions. Free internet banking and bills payment</p>
                    <div className="w-full px-5 h-12 mt-5">
                        <a href="https://wayabank.ng" target="_blank" className="py-3 rounded-lg w-full flex justify-center items-center bg-primary-theme text-white font-medium text-sm">Explore wayabank</a>
                    </div>
                </div>
                <div className="w-full relative h-auto flex-col items-center border border-gray-100 flex py-6 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                    {/* <button className="absolute -right-3 -top-3">
                        <CloseIcon />
                    </button> */}
                    <h5 className="text-2xl font-semibold">Explore Wayagram</h5>
                    <p className="text-gray-500 h-24 mt-4 text-md px-3 text-center">Discover beautiful places, people, conversarions, vendors and clients. Chat and call clients, friends and family</p>
                    <div className="w-full px-5 h-12 mt-5">
                    <a href="https://wayagram.ng" target="_blank" className="py-3 rounded-lg w-full flex justify-center items-center bg-primary-theme text-white font-medium text-sm">Explore Wayagram</a>
                    </div>
                </div>
                <div className="w-full relative h-auto flex-col items-center border border-gray-100 flex py-6 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                    <h5 className="text-2xl font-semibold">Explore WayaPos</h5>
                    <p className="text-gray-500 h-24 mt-4 text-md px-3 text-center">Start accepting payments from your customers and banking services on the go!</p>
                    <div className="w-full px-5 h-12 mt-5">
                    <a href="https://wayapos.ng" target="_blank" className="py-3 rounded-lg w-full flex justify-center items-center bg-primary-theme text-white font-medium text-sm">Explore WayaPos</a>
                    </div>
                </div>
                <div className="w-full relative h-auto flex-col items-center border border-gray-100 flex py-6 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                    {/* <button className="absolute -right-3 -top-3">
                        <CloseIcon />
                    </button> */}
                    <h5 className="text-2xl font-semibold">Download our App</h5>
                    <p className="text-gray-500 h-24 mt-4 text-md px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                    <div className="w-full px-5 h-12 mt-5">
                        <div className="grid grid-cols-2">
                            <button className=""><AndroidIcon /></button>
                            <button className=""><IOSIcon /></button>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Main
