import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import PaymentLinkInner from '../components/PaymentLink';

function PaymentLink({ user, merchantId }) {
    const [sidebarState, setSidebarState] = useState("hidden");
    const toggleSidebar = () => {
        // alert(0)
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }
    return (
        <div className="body-container">
            <Sidebar toggleSidebar={toggleSidebar} sidebarState={sidebarState}/>
            <div className="relative">
                <Navbar pageName="Payment Link" toggleSidebar={toggleSidebar}/>
                <PaymentLinkInner />
            </div>
        </div>
    )
}

export default PaymentLink;
