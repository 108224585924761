import React, {useState} from 'react';
import Select from 'react-select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PermissionCheckbox from "./PermissionCheckbox";

function CreateCustomRole({
    handleNewRoleInputChange,
    createCustomRole,
    newRolePayload,
    setNewRolePayload,
    permissions,
    setPermissions
}) {
    return (
        <>
        <h5 className="font-semibold text-center text-xl">Create Custom Role</h5>
        {/* <p className="text-sm text-center text-gray-400 w-3/5 mx-auto">Please enter  email to invite team members to have access to your dashboard</p> */}

        <div className="px-12">
            <input value={newRolePayload?.roleName} name="roleName" onChange={handleNewRoleInputChange} type="text" placeholder="Role name" className="w-full h-12 my-3 border border-gray-300 rounded px-3 text-md"/>

            <input value={newRolePayload?.roleDescription} name="roleDescription" onChange={handleNewRoleInputChange} type="text" placeholder="Role description" className="w-full h-12 my-3 border border-gray-300 rounded px-3 text-md"/>

            <PermissionCheckbox permissions={permissions} setPermissions={setPermissions}/>

            <button onClick={createCustomRole} className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded">Create Role</button>
        </div>
    </>
  )
}

export default CreateCustomRole
