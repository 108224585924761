import React from 'react';
import { Link } from 'react-router-dom';
import { NairaIcon } from '../../assets';

function BigCard2({ title, value, date }) {
	return (
		<div className="flex justify-center hover:shadow-md transition duration-500 transition-shadow items-start pl-5 flex-col w-full h-28 border border-gray-200 rounded-lg">
			<h5 className="text-2xl flex items-center justify-between w-full pr-6 gap-3 font-semibold text-gray-800 text-left">
				<span className="flex items-center gap-3">
					<NairaIcon />
					{value}
				</span>
				<span className="text-primary-theme">{title}</span>
			</h5>
			<h5 className="text-lg pt-1 flex items-center justify-between w-full pr-6 gap-3 font-medium text-primary-theme text-left">
				<Link to="/settlements" className="flex items-center gap-3 underline">
					See all settlements
				</Link>
				<span className="text-gray-300 font-normal">{date ? date : 'No settlements yet'}</span>
			</h5>
		</div>
	);
}

export default BigCard2;
