import React, { useRef } from 'react'


function OtpInput({otp1, otp2, otp3, otp4, otp5, otp6, setOTP1, setOTP2, setOTP3, setOTP4, setOTP5, setOTP6}) {
    const field1 = useRef(null);
    const field2 = useRef(null);
    const field3 = useRef(null);
    const field4 = useRef(null);
    const field5 = useRef(null);
    const field6 = useRef(null);

    return (
        <div className="grid grid-cols-6 gap-4">
            <input type="number" ref={field1} placeholder="*" onFocus={(e) => e.target.select()} value={otp1} onInput={(e) => {
                setOTP1(e.target.value[e.target.value.length - 1]);
                field2.current.focus();
            }} className="w-full text-center border-b-2 text-2xl" />

            <input type="number" ref={field2} placeholder="*" onFocus={(e) => e.target.select()} value={otp2} onInput={(e) => {
                setOTP2(e.target.value[e.target.value.length - 1]);
                field3.current.focus();
            }} className="w-full text-center border-b-2 text-2xl" />

            <input type="number" ref={field3} placeholder="*" onFocus={(e) => e.target.select()} value={otp3} onInput={(e) => {
                setOTP3(e.target.value[e.target.value.length - 1]);
                field4.current.focus()
            }} className="w-full text-center border-b-2 text-2xl" />

            <input type="number" ref={field4} placeholder="*" onFocus={(e) => e.target.select()} value={otp4} onInput={(e) => {
                setOTP4(e.target.value[e.target.value.length - 1]);
                field5.current.focus()
            }} className="w-full text-center border-b-2 text-2xl" />

            <input type="number" ref={field5} placeholder="*" onFocus={(e) => e.target.select()} value={otp5} onInput={(e) => {
                setOTP5(e.target.value[e.target.value.length - 1]);
                field6.current.focus()
            }} className="w-full text-center border-b-2 text-2xl" />

            <input type="number" ref={field6} placeholder="*" onFocus={(e) => e.target.select()} value={otp6} onInput={(e) => {
                setOTP6(e.target.value[e.target.value.length - 1]);
                // field2.current.click()
            }} className="w-full text-center border-b-2 text-2xl" />
        </div>
    )
}

export default OtpInput
