

import {httpGet, httpPost} from "../https";
import {authService} from "../index";

export const createPin = (payload) => {
    return httpPost(`${authService}/api/v1/pin/create-pin`, payload);
}

export const sendOTPToEmail = (email, businessId) => {
    return httpGet(`${authService}/api/v1/pin/create-pin/byEmail?businessId=${businessId}&email=${email}&redirectUrl=undefined`);
}

export const sendOTPToPhone = (phoneNumber, businessId) => {
    return httpGet(`${authService}/api/v1/pin/create-pin/byPhone?businessId=${businessId}&phoneNumber=${phoneNumber}&redirectUrl=undefined`);
}

export const authOTP = (email) => {
    return httpPost(`${authService}/api/v1/auth/generate-login-otp/${email}`);
}

export const toggle2FA = (userId) => {
    return httpPost(`${authService}/api/v1/auth/toggle-2fa?userId=${userId}`);
}

export const check2FA = (userId) => {
    return httpGet(`${authService}/api/v1/auth/status-check-2fa/${userId}`);
}
