import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../assets/profile.jpg';
// import {WayaPayLogo} from '../assets/index';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {ApikeysContext} from "../context/ApikeysContext";
import { FailedIcon } from '../assets/index.js';
import {getNotifications, updateNotificationStatus} from "../services/requests/notification";
import { toast } from 'react-toastify';
import newLogo from '../assets/newLogo.png'
import AddBranch from './modals/branch.jsx';
import { getBranches } from '../services/requests/banks.js';

function Navbar({ toggleSidebar, pageName, isUnreadMsgExist,setIsUnreadMsgExist  }) {
    // const [isApproved] = useState(false);
    
    const [profilePic, setProfilePic] = useState(null);
    const [dropdownStatus, setDropdownStatus] = useState("hidden");
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [status, setStatus] = useState(false);
    const {modeStatus, toggleModeStatus, getAccountKycStatus, approvalStatus, tab, setTab} = useContext(ApikeysContext);
    const [profileImage, setprofileImage] = useState("");
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [showAddBranch, setShowAddBranch] = useState(false);
    const [branches, setBranches] = useState([]);
    const [isUndreadNotificationExistOnNarbar, setIsUndreadNotificationExistOnNarbar] = useState(false);

    useEffect(() => {
        setFirstName(
            JSON.parse(sessionStorage.getItem("userData")) && JSON.parse(sessionStorage.getItem("userData")).firstName
        );
        setLastName(
            JSON.parse(sessionStorage.getItem("userData")) && JSON.parse(sessionStorage.getItem("userData")).surname
        );

        setprofileImage(JSON.parse(sessionStorage.getItem("userData")).profileImage);

        if(modeStatus === "TEST"){
            setStatus(()=>false);
        }else if(modeStatus === "PRODUCTION"){
            setStatus(()=> true);
        }else{
            setStatus(()=>false);
        }  
    }, [modeStatus]);

    const getBranch = async () => {
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
        const response =  await getBranches(merchantId);
        setBranches(response.content);
    }

    useEffect(()=>{
        fetchNotification();
        getBranch();
    },[]);

    const fetchNotification = async () => {
        const userId = JSON.parse(window.sessionStorage.getItem("userData")).id;
        const response =  await getNotifications(userId);
        if(response.status){
            response.data.notifications.forEach((element, index) => {
                if(!element.read){
                    // If we have unread messages
                    setIsUndreadNotificationExistOnNarbar(true); 
                    // ************
                }
                // setPageRendered(true);
            });
        }
    }

    const toggleHandler = (state) => {
        if (approvalStatus !== 'APPROVED') return toast.error('KYC not approved');
        const outcome = toggleModeStatus();
        if(outcome){
            setStatus(!state);
        }else{
            setStatus(state);
        }
    }


    const toggleDropdown = () => {
        setDropdownStatus(dropdownStatus === "hidden" ? "flex" : "hidden");
    }

    return (
        <>
        {
            tab === 2 && (
            <div className="overlay w-full h-screen flex justify-center fixed top-0 left-0 z-50">
                <div className="w-full md:w-1/2 lg:w-2/5 px-5 py-12 bg-white flex flex-col items-center h-80 rounded mt-32">
                    <FailedIcon />
                    <h5 className="text-xl text-center my-5">Sorry you need to complete KYC,<br/> for your account to use live credentials. </h5>
                    <Link to="/submit-kyc" className="flex text-lg font-medium justify-center text-primary-theme" onClick={()=> setTab(1)}>
                        Complete KYC
                    </Link>
                </div>
            </div>
            )
        }
        <nav className="navbar sticky bg-white z-20 top-0 w-full h-24 md:h-16 shadow flex justify-between items-center px-8">

            <div className="navbar-logo gap-5">
                <button className="" onClick={toggleSidebar}>
                    <span className="menu-btn"></span>
                </button>
                <Link to="" className="hidden sm:flex">
                    {/* <img src={WayaPayLogo} alt="" /> */}
                    <img src={newLogo} width='150px'/>
                    {/*<WayaPayLogo />*/}
                </Link>
            </div>

            <h5 className="navbar-page-name hidden sm:flex items-center gap-4 text-md font-medium text-gray-700">
            {/* {pageName}  */}
            KYC Status: {
                    approvalStatus==="DRAFT" && <span className="bg-gray-50 border border-gray-400 text-gray-600 py-2 px-4 rounded-full">Incomplete</span>
                }
                {

                    approvalStatus==="PENDING" && <span className="bg-yellow-50 border border-yellow-400 text-yellow-600 py-2 px-4 rounded-full">Pending</span>
                }
                {

                    approvalStatus==="APPROVED" && <span className="bg-green-50 border border-green-400 text-green-800 py-2 px-4 rounded-full">Approved</span>
                }
                {
                    approvalStatus==="REJECTED" && <span className="bg-red-50 border border-red-400 text-red-800 py-2 px-4 rounded-full">Rejected</span>
                }
                {
                    !approvalStatus && <span className="bg-gray-50 border border-gray-400 text-gray-600 py-2 px-4 rounded-full">Incomplete</span>
                }
            </h5>

            <div className="nav-wrapper flex gap-5 items-center">
            
                <FormControlLabel control={
                    modeStatus==="PRODUCTION"  || status === true ? 
                    <Switch checked={true} onChange={()=> toggleHandler(true) }/> : 
                    <Switch checked={false} onChange={()=> toggleHandler(false) }/>} 
                    label={status ? "Live Mode": "Test Mode"} />

                <Link to="/notification" className="hidden sm:flex">
                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.1105 13.9806L15.6397 8.96431C15.2086 7.49475 14.2717 6.20395 12.9779 5.29711C11.6841 4.39026 10.1076 3.9194 8.49909 3.95938C6.89058 3.99936 5.34233 4.54788 4.10042 5.51775C2.85852 6.48762 1.99424 7.82318 1.64497 9.31213L0.506289 14.1662C0.380512 14.7026 0.38342 15.2589 0.514798 15.7941C0.646177 16.3292 0.902671 16.8296 1.26534 17.2582C1.62802 17.6868 2.08761 18.0327 2.61019 18.2704C3.13277 18.5081 3.70499 18.6315 4.28461 18.6314H5.14694C5.3246 19.4608 5.79929 20.2065 6.49055 20.7421C7.18182 21.2777 8.04717 21.5703 8.93997 21.5703C9.83276 21.5703 10.6981 21.2777 11.3894 20.7421C12.0806 20.2065 12.5553 19.4608 12.733 18.6314H13.3817C13.9783 18.6315 14.567 18.5007 15.1015 18.2494C15.6361 17.9981 16.1021 17.6331 16.4632 17.1828C16.8243 16.7325 17.0706 16.2092 17.1831 15.6537C17.2955 15.0982 17.2709 14.5256 17.1112 13.9806H17.1105ZM8.93997 20.099C8.46138 20.0972 7.99509 19.9552 7.6049 19.6925C7.2147 19.4297 6.91966 19.0592 6.76014 18.6314H11.1198C10.9603 19.0592 10.6652 19.4297 10.275 19.6925C9.88484 19.9552 9.41855 20.0972 8.93997 20.099ZM15.2302 16.2943C15.0145 16.5655 14.735 16.7854 14.414 16.9363C14.093 17.0873 13.7392 17.1652 13.3809 17.1638H4.28461C3.93687 17.1638 3.5936 17.0897 3.28011 16.947C2.96662 16.8044 2.69092 16.5968 2.47337 16.3397C2.25582 16.0825 2.10196 15.7824 2.02316 15.4613C1.94436 15.1402 1.94262 14.8065 2.01808 14.4847L3.15599 9.62987C3.43029 8.46034 4.10913 7.41129 5.08459 6.64949C6.06006 5.88769 7.27616 5.45686 8.53959 5.42548C9.80301 5.39411 11.0412 5.76399 12.0574 6.47633C13.0737 7.18867 13.8095 8.20258 14.148 9.3569L15.6188 14.3732C15.716 14.7 15.7314 15.0438 15.664 15.3773C15.5965 15.7107 15.448 16.0247 15.2302 16.2943Z"
                            fill="#1C1C1C" />
                        <path
                            d="M19.893 5.80729C19.893 8.71701 17.4164 11.1146 14.3124 11.1146C11.2085 11.1146 8.73193 8.71701 8.73193 5.80729C8.73193 2.89757 11.2085 0.5 14.3124 0.5C17.4164 0.5 19.893 2.89757 19.893 5.80729Z"
                            fill={isUnreadMsgExist || isUndreadNotificationExistOnNarbar ? "#FF4B01" : ""} stroke="white" />
                    </svg>
                </Link>

                <div onClick={toggleDropdown} className="flex items-center gap-2 relative">
                    {
                        profileImage ? <img src={profileImage} className="w-10 h-10 rounded-full" alt="" /> : <img src={Avatar} className="w-10 h-10 rounded-full" alt="" />
                    }
                    <span className="font-medium text-sm hidden sm:flex uppercase">{`${firstName} ${lastName}`}</span>
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.3916 1.67768L6.34295 7.15607C5.93181 7.6022 5.22133 7.58242 4.83565 7.1141L0.324086 1.6357C-0.213157 0.983329 0.250904 0 1.09602 0H10.6562C11.5276 0 11.9821 1.03691 11.3916 1.67768Z"
                            fill="#000000" />
                    </svg>

                    <div className={`${dropdownStatus} flex p-4 justify-between flex-col w-52 shadow absolute top-14 bg-white right-0`}>
                        {/* <a href="/#">Branch 1</a>
                        <a href="/#">Branch 2</a> */}
                        {branches?.map(e => 
                            <div>
                                <a href="/#">{e?.name}</a>
                            </div>
                        )}
                        <button onClick={() => setShowAddBranch(true)}>Add Branch/Category</button>
                    </div>
                </div>
            </div>
        </nav>
        {showAddBranch ? (
            <AddBranch
                showModal={showAddBranch}
                hideModal={setShowAddBranch}
                getBranches={getBranch}
            />
        ) : (
         ''
        )}
        </>
    )
}

export default Navbar
