import React, { useState, useEffect } from 'react'
import { PaymentLinkImage, ViewIcon, QuestiontagIcon, FilterIcon, PlusIcon,CopyIcon, UserAvatar, ForwardArrow,ReplayIcon, ChevronDownIcon, BackIcon, ExportIcon, OneTimePaymentIcon, RecurringPaymentIcon } from '../assets';
import { Button, Grid, Pagination } from '@mui/material';
import { Table } from './index.js';
import FetchApi from '../utils/FetchApi';
import { createPaymentLink, getAllPaymentLink, getPaymentLinkAnalysis, getSubscribersDetails } from "../services/requests/payment_link";
import { ToastContainer, toast } from 'react-toastify';
import { CSVLink } from "react-csv";
import {Link} from 'react-router-dom';
import {FormatDatetime} from "../utils/FormatDatetime";


function PaymentLink() {
    const [userStatus, setUserStatus] = useState("RETURNING");
    const [overflowStatus, setOverflowStatus] = useState(false);
    const [allCustomer, setAllCustomer] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [linkDetails, setLinkDetails] = useState("");
    const [linkAnalysis, setAnalysis] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [tab, setTab] = useState(1);
    const [allPaymentLink, setAllPaymentLink] = useState("");
    const [startDate, setStartDate] = useState('');
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [allFilteredData, setFilteredData] = useState(null);
    const [value, setValue] = useState('');
    const [page, setPage] = useState(1);
    const [tableCount, setTableCount] = useState(0);
    const [linkname, setLinkname] = useState("");
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState("");
    const [showExtraInfo, setshowExtraInfo] = useState(false);
    const [showExtraInfo2, setshowExtraInfo2] = useState(false);
    const [url, setUrl] = useState("");
    const [redirectLink, setRedirectLink] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otherDetailsJSON, setOtherDetailsJSON] = useState("");
    const [interval, setInterval] = useState("");
    const [count, setCount] = useState("");
    const [status, setStatus] = useState("");
    const [overlay, setOverlay] = useState(false);
    const [overlay2, setOverlay2] = useState(false);

    const [additionalDetails, setAdditionalDetails] = useState(null);
    const [linkCustomerDetailsIndex, setLinkCustomerDetailsIndex] = useState(null);

    useEffect(() => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
        getAllPaymentLink().then(response=>{
            const _response = response?.data?.content?.filter(element=> element.isSubscriptionPaymentLink === false);
            const tableRowData = _response?.map(link=> {
                // if(!link.isSubscriptionPaymentLink){
                    //  console.log(link.isSubscriptionPaymentLink)
                    return ({
                        id: link.paymentLinkId, 
                        linkName: link.paymentLinkName, 
                        type: link.paymentLinkType === "SUBSCRIPTION_PAYMENT_LINK" ? "Recurrent Payment Link" : "One-time Payment Link", 
                        amount: link.amountText, 
                        paymentLink: link.customerPaymentLink,
                        createdAt: link.createdAt,
                        status: link.status
                    })
                // }
            });
            // setLinkDetails(tableRowData);
            setFilteredData(tableRowData);
            setAllPaymentLink(response?.data?.content);

        }).catch(err => {
            //  console.log(err);
            toast("Error occured")
        });
    }, []);

    const hideOverlay2 = () => {
        setOverflowStatus(false);
        setTab(1);
    }

    const showOverlay2 = () => {
        setOverflowStatus(true);
    }

    const showSingleLinkOverlay = () => {
        setTab(2);
    }

    const showMultipleLinkOverlay = () => {
        setTab(3);
    }

    const SetLinkname = (e) => {
        setLinkname(e.target.value);
    }

    const SetDescription = (e) => {
        setDescription(e.target.value);
    }

    const SetAmount = (e) => {
        setAmount(e.target.value);
    }

    const SetInterval = (e) => {
        setInterval(e.target.value);
    }

    const SetCount = (e) => {
        setCount(e.target.value);
    }

    const CreateLink = (e) => {
        const payload = {
            currency: "NGN",
            customerPaymentLink: url,
            description: description,
            otherDetailsJSON: JSON.stringify({otherDetailsJSON}),
            payableAmount: amount,
            paymentLinkName: linkname,
            paymentLinkType: "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK",
            phoneNumber: phoneNumber,
            redirectLink: redirectLink,
            successMessage: successMessage,
            totalCount: 0
        }
        
        if(linkname && amount && description){
            if(amount >= 100){
                if(description.length > 3 && description.length < 50){
                    createPaymentLink(payload).then(response => {
                        toast("Payment link created");
                        hideOverlay2();
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }).catch(error => {
                        toast("Error");
                    });
                }else{
                    toast.error("Description should between 3 to 50 characters");
                }
            }else{
                toast.error("Amount cannot be less than 100");
            }
        }else{
            toast.error("Link name and amounts are required")
        }
    }


    const CreateRecurrentLink = (e) => {
        const payload = {
            currency: "NGN",
            customerPaymentLink: url,
            chargeInterval: interval,
            description: description,
            otherDetailsJSON: JSON.stringify({otherDetailsJSON}),
            payableAmount: amount,
            paymentLinkName: linkname,
            paymentLinkType: "SUBSCRIPTION_PAYMENT_LINK",
            phoneNumber: phoneNumber,
            redirectLink: redirectLink,
            successMessage: successMessage,
            totalCount: count ? count : 0
        }
        
        
        if(linkname && amount && interval ){
            if(amount >= 100){
                if(description.length > 3 && description.length <50){
                    createPaymentLink(payload).then(response => {
                        toast.success("Payment link created");
                        // toast(response.message);
                        hideOverlay2();
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }).catch(error => {
                        toast.error("Error");
                    });
                }else{
                    toast.error("Subscription description should be 3 to 50 characters");
                }
            }else{
                toast.error("Amount cannot be less than 100")
            }
        }else{
            toast("Link name, interval and amounts are required");
        }
    }

    const tableColumnStructure = [
        { field: 'id', headerName: 'Reference ID', width: 260 },
        { field: 'linkName', headerName: 'Payment Link Name', flex: 1, minWidth: 210 },
        { field: 'type', headerName: 'Type', flex: 1, minWidth: 230 },
        { field: 'amount', headerName: 'Amount', flex: 1, minWidth: 150 },
        {
            field: 'paymentLink', headerName: 'Payment Link', width: 350, renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    navigator.clipboard.writeText(params.row.paymentLink);
                    toast("Link copied.");
                };
                return (
                    <div className="relative">
                        <span className="relative z-10">{params.row.paymentLink}</span>
                        <button className="bg-white absolute left-80" style={{zIndex: 100}} onClick={onClick}>
                            <CopyIcon />
                        </button>
                    </div>
                )
            }
        },
        { field: 'createdAt', headerName: 'Created At', width: 210 },
        { field: 'status', headerName: 'Status', width: 130 },
        {
            field: 'action', headerName: 'Action', width: 130, renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const data = allPaymentLink.filter(elem => elem.paymentLinkId === params.row.id);
                    if (data.length === 1) {
                        if(data[0].paymentLinkType === "SUBSCRIPTION_PAYMENT_LINK"){
                            setOverlay2(true);
                            setAdditionalDetails(data[0]);
                            setLinkCustomerDetailsIndex(null);
                            // Fetch details about payment link
                            const payload = {
                                merchantId: JSON.parse(window.sessionStorage.getItem("merchantId")),
                                paymentLink: params.row.id                            }
                            getSubscribersDetails(payload).then(response=>{
                                const data = response.data;
                                setLinkDetails(data?.content);
                                setTableCount(data?.totalPages)
                            }).catch(error=>{
                                toast.error("Error occured while fetching subscription subscribers")
                            })

                            getPaymentLinkAnalysis(payload).then(res=>{
                                setAnalysis(res?.data)
                                setTableCount(data?.totalPages)
                            }).catch(error=>{
                                toast.error("error occured while fetching analysis");
                            })
                        }else if(data[0].paymentLinkType === "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK"){
                            setOverlay(true);
                            setAdditionalDetails(data[0]);
                            // Fetch details about payment link
                            const payload = {
                                merchantId: JSON.parse(window.sessionStorage.getItem("merchantId")),
                                paymentLink: params.row.id                            }
                            getSubscribersDetails(payload).then(response=>{
                                const data = response.data;
                                setLinkDetails(data?.content);
                            }).catch(error=>{
                                toast.error("Error occured while fetching subscription subscribers")
                            })
                            getPaymentLinkAnalysis(payload).then(res=>{
                                setAnalysis(res?.data)
                            }).catch(error=>{
                                toast.error("error occured while fetching analysis");
                            })
                        }
                        
                    }
                    // console.log(data);
                };
                return <button className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white" onClick={onClick}>View More</button>
            }
        }
    ]

    const FilterHandler = (id) => {
        setValue(id);
        if(startDate){
            const data = linkDetails;
            const outcome = data.filter(element=> element.createdAt.split("T")[0] == startDate);
            setFilteredData(outcome);
            hideFilterDropdown();
        }
        else if(id){
            const data = linkDetails;
            // const outcome = data.filter(element=> element.id === id);
            const outcome = data.filter(element=> element.id.toLowerCase().indexOf(id.toLowerCase()) > -1);
            setFilteredData(outcome);
            hideFilterDropdown();
        }else if(status){
            const data = linkDetails;
            const outcome = data.filter(element=> element.status === status.toUpperCase());
            setFilteredData(outcome);
            hideFilterDropdown();
        }else{
            setFilteredData(linkDetails);
            hideFilterDropdown();
        }
    }

    const ClearFilter = () => {
        setValue(()=> '');
        setStartDate(()=> '');
        setStatus(()=>'');
        setFilteredData(linkDetails);
        hideFilterDropdown();
    }

    const showFilterDropdown = () => setFilterDropdown(true);
    const hideFilterDropdown = () => setFilterDropdown(false);
    const handleChange = (event, value) => {
        setPage(value);
      };
    return (
        <React.Fragment>
            <ToastContainer />
            {
                overflowStatus && (
                    <div onClick={(e) => e.target.classList.contains("overlay") ? hideOverlay2() : null} className="overlay overflow-y-scroll w-full h-screen fixed px-5 md:px-0 flex items-start justify-center py-28 top-0 left-0 z-30">
                        {
                            tab === 1 && (
                                <div className="w-full md:w-1/2 h-auto rounded bg-white pb-4 ...">
                                    <div className="popup-header flex items-center px-4 h-14 shadow">
                                        Select Link Type
                                </div>
                                    <div className="px-4">
                                        <div onClick={showSingleLinkOverlay} className="w-full cursor-pointer pt-6 pb-6 flex items-center gap-2">
                                            <OneTimePaymentIcon />
                                            <div className="w-4/5">
                                                <h4 className="font-bold text-lg">One-Time Payment Link</h4>
                                                <p className="text-sm">With One-time payment link, your customer will pay you on a single charge </p>
                                            </div>
                                            <button className="bg-primary-theme text-white text-sm px-6 py-3 rounded">Select</button>
                                        </div>
                                        <hr />
                                        <div onClick={showMultipleLinkOverlay} className="w-full cursor-pointer pt-6 flex items-center gap-2">
                                            <RecurringPaymentIcon />
                                            <div className="w-4/5">
                                                <h4 className="font-bold text-lg">Subscription Payment Link</h4>
                                                <p className="text-sm">Customers can subscribe to your service with this link and be charge on recurring payment.</p>
                                            </div>
                                            <button className="bg-primary-theme text-white text-sm px-6 py-3 rounded">Select</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            tab === 2 && (
                                <div className="w-full md:w-1/2 h-auto rounded bg-white pb-4 ...">
                                    <div className="popup-header flex items-center px-4 h-14 shadow">
                                        Create New Payment Link
                                    </div>
                                    <div className="px-4">
                                        <div className="w-full pt-6">
                                            <label className="text-sm font-medium">Payment Link Name <span className="text-red-800">*</span></label>
                                            <input onChange={SetLinkname} value={linkname} type="text" placeholder="Name your payment link" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>
                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Description <span className="text-red-800">*</span></label>
                                            <input onChange={SetDescription} value={description} type="text" placeholder="Description" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>

                                        <p className="text-gray-400 text-sm">The payment link name and description will appear on the invoice as entered above</p>

                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Amount <span className="text-red-800">*</span></label>
                                            <input onChange={SetAmount} value={amount} type="text" placeholder="Enter amount customer will pay (NGN)" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>

                                        <p className="text-gray-400 text-sm">Minimum Plan amount is NGN 100.</p>

                                        <button onClick={() => setshowExtraInfo(showExtraInfo => !showExtraInfo)} className="text-sm mt-3 text-primary-theme flex mx-auto">{showExtraInfo ? "Show less options" : "Show more options"}</button>

                                        {
                                            showExtraInfo && (
                                                <div className="extra-fields w-full">
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Use custom URL</label>
                                                        <div className="w-full grid grid-cols-5 gap-3">
                                                            <input type="text" value="https://pay.wayapay.ng/" className="w-full col-span-2 px-3 text-sm h-12 border border-gray-300 text-gray-600 rounded" />
                                                            <input onChange={(e)=>setUrl(e.target.value)} type="text" value={url} placeholder="Your url" className="w-full col-span-3 px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Redirect after payment</label>
                                                        <div className="w-full grid grid-cols-1 gap-3">
                                                            <input onChange={(e)=>setRedirectLink(e.target.value)} value={redirectLink} type="text" placeholder="https://redirect-link" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Success Message</label>
                                                        <div className="w-full grid grid-cols-1 gap-3">
                                                            <input onChange={(e)=>setSuccessMessage(e.target.value)} value={successMessage} type="text" placeholder="Mesage to show after payment" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Phone Number</label>
                                                        <div className="w-full grid grid-cols-1 gap-3">
                                                            <input onChange={(e)=>setPhoneNumber(e.target.value)} value={phoneNumber} type="text" placeholder="Add your phone number" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Add other information</label>
                                                        <div className="w-full grid grid-cols-1 gap-3">
                                                            <input onChange={(e)=>setOtherDetailsJSON(e.target.value)} value={otherDetailsJSON} type="text" placeholder="Add extra information here" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="popup-header flex justify-between items-center px-4 h-14 pt-5">
                                        <button onClick={() => setTab(1)} className="bg-white border border-gray-300 text-gray-900 py-3 rounded text-sm px-5 flex items-center gap-2">
                                            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 0.893939L13.09 0L8 5L13.09 10L14 9.10606L9.82005 5L14 0.893939Z" fill="black" fill-opacity="0.7" />
                                                <path d="M6 0.893939L5.08997 0L0 5L5.08997 10L6 9.10606L1.82005 5L6 0.893939Z" fill="black" fill-opacity="0.7" />
                                            </svg>
                                            Go back
                                        </button>

                                        <div className="flex gap-3">
                                            <button className="bg-gray-200 border border-gray-300 text-gray-900 py-3 rounded text-sm px-5" onClick={hideOverlay2}>Cancel</button>
                                            <button onClick={CreateLink} className="bg-primary-theme text-white py-3 rounded text-sm px-5">Create Link</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }{
                            tab === 3 && (
                                <div className="w-full md:w-1/2 h-auto rounded bg-white pb-4 ...">
                                    <div className="popup-header flex items-center px-4 h-14 shadow">
                                        Create subscription link
                                    </div>
                                    <div className="px-4">
                                        <div className="w-full pt-6">
                                            <label className="text-sm font-medium">Payment Link Name <span className="text-red-800">*</span></label>
                                            <input onChange={SetLinkname} value={linkname} type="text" placeholder="Name your payment link" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>
                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Description <span className="text-red-800">*</span></label>
                                            <input onChange={SetDescription} value={description} type="text" placeholder="Description" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>

                                        <p className="text-gray-400 text-sm">The payment link name and description will appear on the invoice as entered above</p>

                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Amount <span className="text-red-800">*</span></label>
                                            <input onChange={SetAmount} value={amount} type="text" placeholder="Enter amount customer will pay (NGN)" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>

                                        <p className="text-gray-400 text-sm">Minimum Plan amount is NGN 100, Leave empty to allow customers enter desired amount</p>

                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Interval <span className="text-red-800">*</span></label>
                                            <select onChange={SetInterval} value={interval} type="text" placeholder="Enter amount customer will pay (NGN)" className="w-full px-3 text-sm h-12 border border-gray-300 rounded">
                                                <option>Choose Payment link interval</option>
                                                <option value="DAILY">Daily</option>
                                                <option value="WEEKLY">Weekly</option>
                                                <option value="MONTHLY">Monthly</option>
                                                <option value="QUARTERLY">Quarterly</option>
                                                <option value="SEMI_ANNUAL">Semi Annually</option>
                                                <option value="ANNUALLY">Annually</option>
                                                <option value="BI_ANNUAL">Bi Annually</option>
                                            </select>
                                        </div>

                                        <div className="w-full pt-3">
                                            <label className="text-sm font-medium">Total Count <span className="text-red-800"></span></label>
                                            <input onChange={SetCount} value={count} type="number" placeholder="Set Limit [Optional]" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                        </div>

                                        <p className="text-gray-400 text-sm">The fixed number of time you want this transaction to run for. Leave empty to charge subscriber indefinitely</p>

                                        <button onClick={()=> setshowExtraInfo2(showExtraInfo2=> !showExtraInfo2)} className="text-primary-theme my-4 text-sm flex mx-auto">
                                            {showExtraInfo2 ? "Toggle less options" : "Toggle more options"}
                                        </button>

                                        {
                                            showExtraInfo2 && (
                                                <div className="extra-fields w-full">
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Use custom URL</label>
                                                        <div className="w-full grid grid-cols-5 gap-3">
                                                            <input type="text" value="https://pay.wayapay.ng/" className="w-full col-span-2 px-3 text-sm h-12 border border-gray-300 text-gray-600 rounded" />
                                                            <input onChange={(e)=>setUrl(e.target.value)} type="text" value={url} placeholder="Your url" className="w-full col-span-3 px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Redirect after payment</label>
                                                        <div className="w-full grid grid-cols-1 gap-3">
                                                            <input onChange={(e)=>setRedirectLink(e.target.value)} value={redirectLink} type="text" placeholder="https://redirect-link" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Success Message</label>
                                                        <div className="w-full grid grid-cols-1 gap-3">
                                                            <input onChange={(e)=>setSuccessMessage(e.target.value)} value={successMessage} type="text" placeholder="Mesage to show after payment" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Phone Number</label>
                                                        <div className="w-full grid grid-cols-1 gap-3">
                                                            <input onChange={(e)=>setPhoneNumber(e.target.value)} value={phoneNumber} type="text" placeholder="Add your phone number" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="w-full pt-3">
                                                        <label className="text-sm font-medium">Add other information</label>
                                                        <div className="w-full grid grid-cols-1 gap-3">
                                                            <input onChange={(e)=>setOtherDetailsJSON(e.target.value)} value={otherDetailsJSON} type="text" placeholder="Add extra information here" className="w-full px-3 text-sm h-12 border border-gray-300 rounded" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className="popup-header flex justify-between items-center px-4 h-14 pt-5">
                                        <button onClick={() => setTab(1)} className="bg-white border border-gray-300 text-gray-900 py-3 rounded text-sm px-5 flex items-center gap-2">
                                            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 0.893939L13.09 0L8 5L13.09 10L14 9.10606L9.82005 5L14 0.893939Z" fill="black" fill-opacity="0.7" />
                                                <path d="M6 0.893939L5.08997 0L0 5L5.08997 10L6 9.10606L1.82005 5L6 0.893939Z" fill="black" fill-opacity="0.7" />
                                            </svg>
                                            Go back
                                        </button>

                                        <div className="flex gap-3">
                                            <button className="bg-gray-200 border border-gray-300 text-gray-900 py-3 rounded text-sm px-5" onClick={hideOverlay2}>Cancel</button>
                                            <button onClick={CreateRecurrentLink} className="bg-primary-theme text-white py-3 rounded text-sm px-5">Ceate Link</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }{
                            tab === 4 && (
                                <div className="w-full md:w-1/2 h-auto flex py-5 flex-col items-center rounded bg-white pb-4 ...">
                                    <svg width="132" height="132" viewBox="0 0 173 173" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="86.5987" cy="86.7262" r="86.0406" fill="#F1F7FA" />
                                        <path d="M56.8117 84.0237L79.286 109.213L119.182 68.6789" stroke="#27AE60" stroke-width="6" />
                                    </svg>

                                    <h5 className="text-xl mt-3 font-semibold text-center">User Successfully Added</h5>
                                    <button className="bg-gray-200 mt-4 border border-gray-300 text-gray-900 py-3 rounded text-sm px-5" onClick={hideOverlay2}>Cancel</button>
                                </div>
                            )
                        }{
                            tab === 5 && (
                                <div className="w-full md:w-1/2 h-auto flex py-5 flex-col items-center rounded bg-white pb-4 ...">
                                    <svg width="90" height="72" viewBox="0 0 90 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M88.3544 59.6455L52.6167 4.82016C51.0373 2.39715 48.2084 0.950195 45.0492 0.950195C41.8901 0.950195 39.0611 2.39683 37.4817 4.82016L1.74439 59.6455C0.164631 62.0685 0.164631 64.9617 1.74439 67.3851C3.32415 69.8084 6.15274 71.255 9.31189 71.255H80.7876C83.9464 71.255 86.7754 69.8084 88.3548 67.3854C89.9342 64.9621 89.9338 62.0688 88.3544 59.6455ZM45.0496 62.3356C41.9132 62.3356 39.3616 60.0757 39.3616 57.2977C39.3616 54.5201 41.9132 52.2601 45.0496 52.2601C48.1859 52.2601 50.7375 54.5201 50.7375 57.2977C50.7371 60.076 48.1856 62.3356 45.0496 62.3356ZM50.7758 40.8008C50.7758 43.5973 48.2069 45.8727 45.0496 45.8727C41.8922 45.8727 39.3233 43.5973 39.3233 40.8008V17.523C39.3233 16.1962 40.5378 15.1205 42.0358 15.1205H48.0637C49.5621 15.1205 50.7762 16.1962 50.7762 17.523V40.8008H50.7758Z" fill="#FF6700"></path>
                                    </svg>

                                    <h5 className="text-xl mt-3 font-semibold text-center">User Failed to be Added</h5>
                                    <button className="bg-gray-200 mt-4 border border-gray-300 text-gray-900 py-3 rounded text-sm px-5" onClick={hideOverlay2}>Cancel</button>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                userStatus === "NEW" && (
                    <div className="p-6 grid grid-cols-1 md:grid-cols-2">
                        <div className="w-full overflow-hidden">
                            <PaymentLinkImage />
                        </div>
                        <div className="pt-8 md:pt-32 md:pl-5">
                            <h4 className="flex items-center gap-4 font-medium text-lg">
                                <span className="h-1 rounded-full w-16 bg-primary-theme block"></span>
                                Wayaquick Web
                            </h4>
                            <h1 className="text-4xl font-semibold mt-8 mb-6">Payment Links</h1>
                            <p className="text-lg pr-8 md:pr-32 mb-5">Create and share a Wayaquick web  Payment Link in under a minute with your customers via email, SMS, messenger, chatbot etc.</p>
                            <Button variant="contained" color="primary">Create Link Now</Button>
                        </div>
                    </div>
                )
            }{
                userStatus === "RETURNING" && (
                    <React.Fragment>
                        {overlay && (
                            <div className="w-full">
                                <button className="mx-8 my-4 flex items-center text-sm gap-2" onClick={()=>setOverlay(false)}>
                                    <BackIcon /> Back
                                </button>
                                <div className="px-8">
                                    <h4 className="text-xl font-semibold">{additionalDetails?.amountText} </h4>
                                    <p className="text-md mb-3 text-gray-600">{additionalDetails?.description ? additionalDetails?.description : "Some information about this payment link"} </p>
                                </div>
                                <div className="grid grid-cols-1 gap-4 h-auto px-8">
                                    <div className="bg-white w-full h-auto border px-6 border-gray-200 rounded-xl py-5">
                                        
                                        <table className="w-full">
                                            <thead>
                                                <th className="text-sm text-left">Payment ID</th>
                                                <th className="text-sm text-left">Page Name</th>
                                                <th className="text-sm text-left">Page Type</th>
                                                <th className="text-sm text-left">Date Created</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><span className="text-xs truncate text-gray-500">{additionalDetails?.paymentLinkId}</span></td>
                                                    <td><span className="text-xs text-gray-500">{additionalDetails?.paymentLinkName}</span></td>
                                                    <td><span className="text-xs text-gray-500">{additionalDetails?.paymentLinkType === "SUBSCRIPTION_PAYMENT_LINK" ? "Recurrent Payment Link" : "One-time Payment Link"}</span></td>
                                                    <td><span className="text-xs text-gray-500">{additionalDetails?.createdAt}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="flex justify-start items-center gap-2 py-5">
                                            <span className="text-xs text-gray-500">Status</span>
                                            <button className="text-xs flex items-center rounded text-white py-2 gap-1 px-3 bg-green-500"><ViewIcon color="white"/> Active</button>
                                        </div>
                                        
                                    </div>
                                    <div className="bg-white w-full h-auto border px-6 border-gray-200 rounded-xl py-5">
                                        <h4 className="text-primary-theme text-sm font-semibold">Recent Payment</h4>
                                        <p className="text-sm mt-4 mb-2 flex items-center">
                                            <QuestiontagIcon />
                                            This is a summary of this page's recent payments. To view and filter all.<button className="text-primary-theme underline">click here</button>
                                        </p>

                                        <table className="w-full">
                                            <thead>
                                                <th className="text-sm text-left">Payment Information</th>
                                                <th className="text-sm text-left">Date</th>
                                                <th className="text-sm text-left">Action</th>
                                            </thead>
                                            <tbody>
                                            
                                            {
                                                linkDetails?.map(({amountText, firstName, startDateAfterFirstPayment})=> (
                                                    <tr>
                                                        <td><span className="text-xs truncate text-gray-500">{additionalDetails?.amountText} from {firstName}</span></td>
                                                        <td><span className="text-xs text-gray-500">{startDateAfterFirstPayment}</span></td>
                                                        <td>
                                                            <button className="bg-primary-theme text-white text-sm flex items-center gap-2 px-4 py-2 rounded">
                                                                <ViewIcon color="white" />
                                                                view details
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Overlay for recurrent link */}
                        {overlay2 && (
                            <div className="w-full">
                                <button className="mx-8 my-4 flex items-center text-sm gap-2" onClick={()=>setOverlay2(false)}>
                                    <BackIcon /> Back
                                </button>
                                
                                <div className="grid grid-cols-2 gap-4 h-auto px-8">
                                    <div className="bg-white w-full h-screen overflow-scroll border px-6 border-gray-200 rounded-xl py-5">
                                        <h5 className="mb-4"><span className="border-b-2 font-medium border-primary-theme">Status</span>: <span className="text-green-500 font-medium">Active</span></h5>
                                        <table className="w-full">
                                            <thead>
                                                <th className="text-sm text-left">Payment Linkname</th>
                                                <th className="text-sm text-left">Subscribers</th>
                                                <th className="text-sm text-left">Amount</th>
                                                {/* <th className="text-sm text-left">Date Created</th> */}
                                            </thead>
                                            <tbody>
                                            {
                                                linkDetails && linkDetails?.map((link, index)=> (
                                                    <tr className="cursor-pointer" onClick={()=> setLinkCustomerDetailsIndex(index)}>
                                                        <td className="w-80"><span className="text-xs truncate text-gray-500">{additionalDetails?.paymentLinkName}</span></td>
                                                        <td className="w-80"><span className="text-xs text-gray-500">{`${link?.customerName}`}</span></td>
                                                        <td className="w-80"><span className="text-xs text-gray-500">{link?.recurrentAmount}</span></td>
                                                        {/* <td><span className="text-xs text-gray-500">{linkDetails?.createdAt}</span></td> */}
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                        {//<Pagination count={tableCount} page={page} onChange={handleChange} />
                                        }
                                        {/* <div className="flex justify-start items-center gap-2 py-5">
                                            <span className="text-xs text-gray-500">Status</span>
                                            <button className="text-xs flex items-center rounded text-white py-2 gap-1 px-3 bg-green-500"><ViewIcon color="white"/> Active</button>
                                        </div> */}
                                        
                                    </div>
                                    <div className="bg-white w-full h-auto">
                                        {
                                            linkCustomerDetailsIndex === null && (
                                                <div className="px-4">
                                                    <p className="text-xs text-gray-400 mt-4">Number of subscribers</p>
                                                    <h5 className="text-md font-semibold">{linkAnalysis?.totalSubscribers ?? 0}</h5>

                                                    <p className="text-xs text-gray-400 mt-4">Total subscriptions revenue</p>
                                                    <h5 className="text-md font-semibold">{linkAnalysis?.totalSubscriberAmount ?? 0}</h5>
                                                </div>
                                            )
                                        }

                                        {
                                            linkCustomerDetailsIndex !== null && (
                                                <>
                                                <div className="border border-gray-200 px-6 py-5 rounded-xl">
                                                    <h4 className="text-gray-800 text-sm font-semibold">{additionalDetails ?.paymentLinkName} - {linkDetails[linkCustomerDetailsIndex] ?.paymentLinkId}</h4>

                                                    <div className="mt-4">
                                                        <div className="grid grid-cols-2">
                                                            <p className="text-sm">Customer Name</p>
                                                            <p className="text-sm">{`${linkDetails[linkCustomerDetailsIndex] ?.customerName}`}</p>
                                                        </div>
                                                        <div className="grid grid-cols-2 mt-4">
                                                            <p className="text-sm">Customer Email</p>
                                                            <p className="text-sm">{linkDetails[linkCustomerDetailsIndex] ?.customerEmail}</p>
                                                        </div>
                                                        {/* <div className="grid grid-cols-2 mt-4">
                                                                <p>Customer Card</p>
                                                                <p>*******4414</p>
                                                            </div> */}
                                                        <div className="mt-4">
                                                            <Link to={`/customers?customerId=${linkDetails[linkCustomerDetailsIndex] ?.customerId}`}>
                                                                <button className="font-semibold shadow text-sm py-2 px-5 rounded">View Customer</button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="border mt-4 border-gray-200 px-6 py-5 rounded-xl">
                                                    <h4 className="text-gray-400 text-sm font-semibold">Subscription</h4>

                                                    <div className="mt-4">
                                                        <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                            <p className="text-sm">Payment Link Name</p>
                                                            <p className="text-sm font-semibold">{additionalDetails ?.paymentLinkName}</p>
                                                        </div>

                                                        <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                            <p className="text-sm">Status</p>
                                                            {
                                                                linkDetails[linkCustomerDetailsIndex] ?.status === "ACTIVE" ?
                                                                    <p className="text-sm font-semibold text-green-500">Active</p> :
                                                                    <p className="text-sm font-semibold text-red-500">Inactive</p>
                                                            }
                                                        </div>
                                                        <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                            <p className="text-sm">Payment Link ID</p>
                                                            <p className="text-sm font-semibold"> {linkDetails[linkCustomerDetailsIndex] ?.paymentLinkId}</p>
                                                        </div>
                                                        <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                            <p className="text-sm">Amount</p>
                                                            <p className="text-sm font-semibold">{linkDetails[linkCustomerDetailsIndex] ?.recurrentAmount}</p>
                                                        </div>
                                                        <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                            <p className="text-sm">Interval</p>
                                                            <p className="text-sm font-semibold">{additionalDetails ?.intervalType}</p>
                                                        </div>
                                                        <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                            <p className="text-sm">Payments</p>
                                                            <p className="text-sm font-semibold">{linkDetails[linkCustomerDetailsIndex] ?.totalChargeCount} of {linkDetails[linkCustomerDetailsIndex] ?.maxChargeCount}</p>
                                                        </div>
                                                        {/* <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                    <p className="text-sm">Payments</p>
                                                    <p className="text-sm font-semibold">1 of 3s</p>
                                                </div> */}
                                                        <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                            <p className="text-sm">Subscription Date</p>
                                                            <p className="text-sm font-semibold">{linkDetails[linkCustomerDetailsIndex] ?.firstPaymentDate}</p>
                                                        </div>
                                                        <div className="flex border-b border-gray-300 py-2 flex-items justify-between">
                                                            <p className="text-sm">Next Charge Date</p>
                                                            <p className="text-sm font-semibold">{linkDetails[linkCustomerDetailsIndex] ?.nextChargeDate}</p>
                                                        </div>

                                                        <div className="mt-4">
                                                            <button className="font-semibold bg-primary-theme text-white shadow text-sm py-2 px-5 rounded">Cancel this subscription</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )
            }{
                userStatus == "RETURNING" && (
                    !overlay && !overlay2 && (
                        <>
                            <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
                                <div className="flex items-center gap-6">
                                    <div className="flex items-center relative">
                                        <button className="flex items-center" onClick={showFilterDropdown}>
                                        <FilterIcon />
                                        <span className="text-sm">Filter</span>
                                        </button>
                                        {
                                            filterDropdown && (
                                            <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                                                <span onClick={hideFilterDropdown} className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm">close</span>
                                                {/* <h5 className="font-semibold">Status</h5>
                                                <input value={value} placeholder="Search by reference ID" onChange={(e)=>setValue(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" /> */}

                                                <h5 className="font-semibold mt-2">Date Range</h5>
                                                <div className="flex items-center gap-4">
                                                    <input onChange={(e)=>setStartDate(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" type="date"/>
                                                    {/* <input placeholder="Search by reference ID" onChange={(e)=>FilterHandler(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" type="date"/> */}
                                                </div>

                                                <h5 className="font-semibold mt-2">Payment Status</h5>
                                                <div className="flex items-center gap-4">
                                                    <select type="" onChange={(e)=>setStatus(e.target.value)} className="w-full h-10 rounded border border-gray-200">
                                                    <option value="">Choose status</option>
                                                        <option value="ACTIVE">Active</option>
                                                        <option value="INACTIVE">In-Active</option>
                                                    </select>    
                                                </div>

                                                <div className="flex items-center gap-4 mt-5">
                                                    <button onClick={ClearFilter} className="py-3 w-full text-xs rounded shadow">Clear filter</button>
                                                    <button onClick={()=>FilterHandler(value)}  className="py-3 w-full rounded bg-primary-theme text-white text-xs">Apply filter</button>
                                                </div>
                                            </div>
                                        )
                                        }
                                    </div>
                                    <div className="1/5 hidden md:flex gap-1 ...">
                                        <input placeholder="Search by reference ID" onChange={(e)=>FilterHandler(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" />
                                        <button onClick={()=>FilterHandler(value)} className="bg-primary-theme py-2 px-5 text-white text-sm">Search</button>
                                    </div>
                                </div>
                                <div className="flex items-center gap-6">
                                    <button onClick={showOverlay2} className="flex bg-primary-theme text-white shadow gap-1 h-10 items-center text-sm px-3">
                                        <PlusIcon />
                                        Create Payment Link
                                    </button>

                                    {
                                        linkDetails?.length > 0
                                        ?
                                        <CSVLink data={linkDetails}>
                                            <button className="flex shadow ml-auto gap-2 h-10 items-center text-sm px-5">
                                                <ExportIcon />
                                                Export Link
                                                <ChevronDownIcon />
                                            </button>
                                        </CSVLink>
                                        :
                                        <button onClick={()=> toast("You can't export empty data.")} className="flex shadow ml-auto gap-2 h-10 items-center text-sm px-5">
                                            <ExportIcon />
                                            Export Link
                                            <ChevronDownIcon />
                                        </button>
                                    }
                                </div>
                            </div>
                            <Table height="480px" tableColumnStructure={tableColumnStructure} tableRowData={allFilteredData} pageName="Payment Link" />
                        </>
                    )
                )
            }
        </React.Fragment>
    )
}

export default PaymentLink
