import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2';
import axios from "axios";
import config from '../../config.js';

function BarChart({monthStats, total}) {
    const [datum, setDatum] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [max, setMax] = useState("");
    
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [{
            label: "Revenue/Month",
            data: datum,
            backgroundColor: 'white',
            borderColor: '#FF6700',
            borderRadius: 10,
            borderWidth: 1,
            hoverBackgroundColor: '#FF6700'
        }]
    }

    useEffect(()=> {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        } 
        setMax(yyyy + '-' + mm + '-' + dd);
    }, []);

    useEffect(() => {
        setDatum(monthStats)
        // console.log(Math.max(...monthStats));
    }, [monthStats]);

    useEffect(()=>{
        setTotalRevenue(total);
    }, [total])

    const FilterTransactionByDate = () => {
        const merchantId = JSON.parse(window.sessionStorage.getItem("merchantId"));
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                "Content-Type": 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        if(startDate && endDate){
            setLoading(true);
            axios.get(`${config.BASE_URL_AUTH}/payment-gateway/api/v1/transactions/report/year-month-stats?endDate=${endDate}&merchantId=${merchantId}&startDate=${startDate}`, header).then((result) => {
                let total = 0;
                result.data.data.dateRangeResult.forEach(element => {
                    total = total + element.totalRevenue; 
                    setLoading(false);
                });
                setTotalRevenue(total);
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            });
        }
    }

    const StartDateHandler = (e) => {
        setStartDate(()=>e.target.value);
    }

    const EndDateHandler = (e) => {
        setEndDate(()=>e.target.value);
    }

    return (
        <div className="hover:shadow rounded-lg border border-gray-200 p-5">
            <div className="flex flex-col md:flex-row justify-start gap-2 items-center mb-20">
                <div className="flex items-end gap-2">
                    <div className="flex flex-col">
                        <label className="font-medium text-primary-theme">From</label>
                        <input type="date" max={max} className="border border-gray-600 rounded p-2" onChange={StartDateHandler} />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-medium text-primary-theme">To</label>
                        <input type="date" max={max} className="border border-gray-600 rounded p-2" onChange={EndDateHandler} />
                    </div>
                    <button onClick={FilterTransactionByDate} className="bg-primary-theme text-white py-2 px-5 rounded">Search</button>
                </div>
                <div className="flex mx-auto mt-5 md:mt-0 w-56 h-24 shadow-sm flex-col justify-center rounded border border-gray-200">
                    <p className="text-sm text-center">Total revenue</p>
                    <p className="text-2xl text-center font-semibold flex items-center justify-center">₦{loading ? <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span> : totalRevenue ? totalRevenue : "0.00"}</p> 
                </div>
            </div>
            <Bar data={data}
                options={{
                    scales: {
                        x: {
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            grid: {
                                borderDash: [4, 2],
                                color: "#FF6700",
                            },
                            min: Math.min(...monthStats),
                            max: Math.max(...monthStats) > 100 ? Math.max(...monthStats) : 100,
                            stepSize: Math.floor(Math.max(...monthStats) / 5),
                        }
                    }
                }}
                height={130}
            />
        </div>
    )
}

export default BarChart
