import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import FAB from '../../components/FAB.jsx';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { WayaPayLogo1, Icon, FailedIcon, CloseIcon } from '../../assets/index.js';
import axios from 'axios';
import config from '../../config.js';
import OtpInput from '../../components/OtpInput.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {AuthContext} from "../../context/AuthContext";
import {completeRegisteration} from "../../services/requests/roles.js";


function InviteeRegister() {
    const [errMsg, setErrMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [qp, setQp] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [registerPayload, setRegisterPayload] = useState({
        password: ""
    });
    useEffect(()=>{
        setQp({
            email: searchParams.get("email"),
            username: searchParams.get("username"),
            id: searchParams.get("id")
        });
    },[]);
    const updateContent = (e) => {
        setRegisterPayload({
            ...registerPayload,
            [e.target.name]: e.target.value
        });
    }
    const joinTeamHandler = () => {
        setLoading(true);
        const payload = {
            userId: qp.id,
            password: registerPayload.password
        }
        completeRegisteration(payload).then(response => {
            toast.success(response.message);
            // setTimeout(() => {
            //     // window.location.href = "/login";
            //     window.location.reload();
            // }, 2000);
        }).catch(error => {
            toast.error("Error occured");
        });
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }
    return (
        <React.Fragment>
        <ToastContainer />
            <div className="onboarding-background w-full h-auto flex items-start justify-center">
                <div className="onboarding-form-container bg-white border-t-4 border-primary-theme pb-12">
                    <div className="flex flex-col items-center pt-10 px-6">
                        <Link to="/"><WayaPayLogo1 /></Link>

                        <h4 className="title font-semibold text-xl text-gray-500 mt-3 mb-2">JOIN TEAM</h4>
                        <p className="text-sm px-8 text-center">Hi {qp?.email}, please complete this form to create your WayaQuick account and join this team</p>
                    </div>
                    
                    <div className="input-wrapper px-10">
                        <div className="px-3 py-1">
                            <label className="text-sm text-gray-500">Fullname<span className="text-primary-theme"></span></label>
                            <input type="text" disabled={true} value={qp?.username} name="firstName" onChange={updateContent} className="w-full h-10 border border-gray-300 px-3 rounded" />
                        </div>
                        {/* <div className="px-3 py-1">
                            <label className="text-sm text-gray-500">Last name<span className="text-primary-theme">*</span></label>
                            <input type="text" value={registerPayload.lastName} name="lastName" onChange={updateContent} className="w-full h-10 border border-gray-300 px-3 rounded" />
                        </div>
                        <div className="px-3 py-1">
                            <label className="text-sm text-gray-500">Phone number<span className="text-primary-theme">*</span></label>
                            <input type="number" value={registerPayload.phoneNumber} name="phoneNumber" onChange={updateContent} className="w-full h-10 border border-gray-300 px-3 rounded" />
                        </div> */}
                        <div className="px-3 py-1">
                            <label className="text-sm text-gray-500">Password<span className="text-primary-theme">*</span></label>
                            <input type="password" value={registerPayload.password} name="password" onChange={updateContent} className="w-full h-10 border border-gray-300 px-3 rounded" />
                        </div>
                        
                        <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                        ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

                        <Button text="Join your team" actionHandler={joinTeamHandler} loading={loading} />

                        <p className="text-sm text-center text-gray-500">Do you need a corporate account <Link to="/register" className="text-primary-theme">Sign up instead</Link></p>
                    </div>
                </div>

                <FAB />
            </div>
        </React.Fragment>
    )
}

export default InviteeRegister
