
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackIcon } from '../../assets';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config.js';

function ChangePassword() {
    const location = useNavigate();
    const [password2, setPassword2] = useState("");
    const [data, setData] = useState({
        newPassword: "",
        oldPassword: "",
        otp: "",
        phoneOrEmail: JSON.parse(sessionStorage.getItem("userData")).email
    });

    const generateOTP = () => {
        const email = JSON.parse(sessionStorage.getItem("userData")).email;
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        
        const ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/password/change-password/byEmail?email=${email}&redirectUrl`;
        axios.get(ENDPOINT, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }).then(response=>{
            toast.success(response.data.data);
        }).catch(error=>{
            toast.error(error?.response?.data)
        })
    }

    const changePasswordHandler = () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        if (data.newPassword === password2) {
            const ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/password/change-password`;
            axios.post(ENDPOINT, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }).then(response=>{
                toast.success("Password changed successfully");
            }).catch(error=>{
                toast(error?.response?.data?.message)
            });
        }else{
            toast.error("Password must match")
        }
    }
    return (
       <>
        <ToastContainer />
        <div className="flex justify-center flex-col items-start w-full px-10">
            <div className="flex w-full items-center justify-between pt-6">
                <button className="text-sm flex items-center gap-2" onClick={() => location("/setting/profile")}>
                    <BackIcon />
                    Back
                </button>
            </div>
            <div className="w-3/5 py-4 ...">
                <h4 className="font-semibold mb-1 mt-5">Change Password</h4>
                <p className="text-sm leading-4 mb-5 text-gray-500">Click on send otp button to generate OTP before you can change password.</p>
                <div className="relative">
                    <input value={data.otp} onChange={(e)=> setData({
                    ...data,
                    otp: e.target.value
                })} className="w-full mb-4 text-sm h-10 border border-gray-200 rounded px-3" placeholder="Enter OTP"/>
                    <button onClick={generateOTP} className="bg-primary-theme text-xs text-white rounded absolute right-1 top-1 px-3 py-2">send otp</button>
                </div>

                <input value={data.oldPassword} onChange={(e)=> setData({
                    ...data,
                    oldPassword: e.target.value
                })} className="w-full mb-4 text-sm h-10 border border-gray-200 rounded px-3" placeholder="Enter old password"/>

                <input value={data.newPassword} onChange={(e)=> setData({
                    ...data,
                    newPassword: e.target.value
                })} className="w-full mb-4 text-sm h-10 border border-gray-200 rounded px-3" placeholder="Enter new password"/>

                <input value={password2} onChange={(e)=> setPassword2(e.target.value)} className="w-full mb-4 text-sm h-10 border border-gray-200 rounded px-3" placeholder="Confirm new password"/>

                <button onClick={changePasswordHandler} className="w-full h-10 rounded bg-primary-theme text-white text-sm">Change Password</button>
            </div>
        </div>
        </>
    )
}

export default ChangePassword
