import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FilterIcon, ExportIcon, ChevronDownIcon, BackIcon, CopyIcon, UserAvatar, ForwardArrow, ReplayIcon } from '../assets';
import { Card, Table } from './index.js';
import CardTwo from './CardTwo';
import FetchApi from '../utils/FetchApi';
import { Link, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import {getAllTransactions, getRevenueStats, getRevenueSummary, getCustomerTransactions} from "../services/requests/transactions";
import {FormatDatetime} from "../utils/FormatDatetime";
import { httpGet } from '../services/https';
import { bankReferral } from '../services';

function AgCommission() {
    const [searchParams] = useSearchParams();

    const [transaction, setTransaction] = useState(null);
    const [allFilteredData, setFilteredData] = useState(null);
    const [channel, setChannel] = useState('');
    const [value, setValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [stats, setStats]= useState([]);

    const tableColumnStructure = [
        { field: 'referralName', headerName: 'Referral Name', width: 250 },
        { field: 'customerName', headerName: 'Customer Username', width: 250 },
        { field: 'customerEmail', headerName: 'Customer Email', width: 270 },
        { field: 'customerPhone', headerName: 'Customer Phone', width: 200 },
        { field: 'referralCommissionAmount', headerName: 'Commission Amount', width: 200},
        { field: 'transactionCount', headerName: 'Transaction Count', width: 160 },
        { field: 'customerOrganizationName', headerName: 'Organization Name', width: 250, renderCell: ({row}) => row?.businessType ?? 'N/A' },
        // {
        //     field: 'action', headerName: 'Action', width: 130, renderCell: (params) => {
        //         const onClick = (e) => {
        //             e.stopPropagation(); // don't select this row after clicking

        //             const data = allTrxn.filter(elem => elem.refNo === params.row.id);
        //             if (data.length === 1) {
        //                 setShowPopup(true);
        //                 setTransactionDetails(data[0]);
        //                 console.log(data);
        //             }
        //         };
        //         return <button className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white" onClick={onClick}>View More</button>
        //     }
        // }
    ];
    
    
    useEffect( async() => {
        const res = await httpGet(`${bankReferral}/fetchReferralTransactionCommissions?pageNo=1&pageSize=1000`)
        if (res.status) {
            setFilteredData(res?.data?.content);
            setTransaction(res?.data?.content);
        }
        const statRes = await httpGet(`${bankReferral}/fetchReferralTransactionCommission/stats`)
        if (res.status) {
            setStats(statRes?.data);
        }
    }, []);

    let sortedDataDESC = transaction ? [...transaction] : null;
    const tableRowData = sortedDataDESC;

    const FilterHandler = (id) => {
        // console.log('table Data', id, tableRowData);
        const outcome = tableRowData.filter(element=> element?.email?.toLowerCase().includes(id.toLowerCase()));
        setFilteredData(outcome);
    }

    const ClearFilter = () => {
        setValue(()=> '');
        setStartDate(()=> '');
        setChannel(()=> '');
        setFilteredData(tableRowData);
        hideFilterDropdown();
    }

    const showFilterDropdown = () => setFilterDropdown(true);
    const hideFilterDropdown = () => setFilterDropdown(false);

    

    return (
        <>
        <ToastContainer />
                    <>
                        <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
                        <div className="flex items-center gap-6">
                            <div className="flex items-center relative">
                                <button className="flex items-center" onClick={showFilterDropdown}>
                                <FilterIcon />
                                <span className="text-sm">Filter</span>
                                </button>
                                {
                                    filterDropdown && (
                                    <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                                        <span onClick={hideFilterDropdown} className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm">close</span>
                                        {/* <h5 className="font-semibold">Status</h5>
                                        <input value={value} placeholder="Search by reference ID" onChange={(e)=>setValue(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" /> */}

                                        {/* <h5 className="font-semibold mt-2">Date Range</h5>
                                        <div className="flex items-center gap-4">
                                            <input placeholder="Search by reference ID" onChange={(e)=>setStartDate(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" type="date"/>
                                            <input placeholder="Search by reference ID" onChange={(e)=>FilterHandler(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" type="date"/>
                                        </div>
                                        <h5 className="font-semibold mt-2">Payment Channel</h5> */}
                                        <div className="flex items-center gap-4">
                                            <select type="" onChange={(e)=>setChannel(e.target.value)} className="w-full h-10 rounded border border-gray-200">
                                            <option value="email" >Email</option>
                                                {/* <option value="card">Card</option>
                                                <option value="wallet">Wallet</option>
                                                <option value="QR">QR</option>
                                                <option value="payattitude">Pay Attitude</option> */}
                                            </select>    
                                        </div>

                                        {/* <h5 className="font-semibold mt-2">Payment Status</h5>
                                        <div className="flex items-center gap-4">
                                            <select type="" onChange={(e)=>setStatus(e.target.value)} className="w-full h-10 rounded border border-gray-200">
                                            <option value="">Choose status</option>
                                                <option value="abandoned">Abandoned</option>
                                                <option value="pending">Pending</option>
                                                <option value="failed">Failed</option>
                                                <option value="successful">Successful</option>
                                            </select>    
                                        </div> */}

                                        {/* <div className="flex items-center gap-4 mt-5">
                                            <button onClick={ClearFilter} className="py-3 w-full text-xs rounded shadow">Clear filter</button>
                                            <button onClick={()=>FilterHandler(value)}  className="py-3 w-full rounded bg-primary-theme text-white text-xs">Apply filter</button>
                                        </div> */}
                                    </div>
                                )
                                }
                            </div>
                            <div className="1/5 flex gap-1 ...">
                                <input placeholder="Search" onChange={(e)=>FilterHandler(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" />
                                <button onClick={()=>FilterHandler(value)} className="bg-primary-theme py-2 px-5 text-white text-sm">Search</button>
                            </div>
                        </div>
                        {
                            allFilteredData?.length > 0
                            ?
                            <CSVLink data={allFilteredData}>
                                <button className="flex shadow gap-2 h-10 items-center text-sm px-5">
                                    <ExportIcon />
                                    Export
                                    <ChevronDownIcon />
                                </button>
                            </CSVLink>
                            :
                            <button onClick={()=> toast("You can't export empty data.")} className="flex shadow gap-2 h-10 items-center text-sm px-5">
                                <ExportIcon />
                                Export
                                <ChevronDownIcon />
                            </button>
                        }
                        </div>
                        <Grid spacing={2} container className="px-6 pt-5 d-flex justify-content-around">
                            <Grid item xs={12} sm={12} md={2.5}>
                                <Card name="Total Onboarded Agents" amount={stats?.totalReferred ?? 0} notCurrency />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2.5}>
                                <Card name="Total Transaction Count" amount={stats?.countFail + stats?.countSuccess ?? 0} notCurrency />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2.5}>
                                <Card name="Total Commission Generated" amount={stats?.totalRevenueGenerated ?? 0} />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={2.5}>
                                <Card name="Total Pending Commission" amount={stats?.totalFail ?? 0} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <Card name="Total Settled Commission" amount={stats?.totalSuccess ?? 0} />
                            </Grid>
                        </Grid>
                        <Table tableColumnStructure={tableColumnStructure} tableRowData={allFilteredData && allFilteredData.length > 0 ? allFilteredData : null} pageName="Aggregators" />
                    </>
        </>
    )
}

export default AgCommission