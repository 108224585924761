import React from 'react'

function SuccessInvite() {
  return (
    <div className="flex flex-col items-center px-14">
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="#219653" fill-opacity="0.1"/>
            <path d="M32.6903 48.4295L45.7506 63.0677L68.9348 39.5124" stroke="#219653" stroke-width="6"/>
        </svg>
        <h5 className="font-semibold my-5 text-center text-xl">Successful</h5>
        <p className="text-sm text-center mb-3 text-gray-400 w-full mx-auto">Your invite has been sucessfully sent. Your team member will join you soon.</p>
            <a href="" className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded">Okay, Go back home</a>
    </div>
  )
}

export default SuccessInvite;
