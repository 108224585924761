import React from 'react'

function CardTwo({ failed, sucessful, abandoned, settled, refunded, pending }) {
    return (
        <div className="w-full relative h-28 col-span-2 flex flex-col items-start px-4 justify-center rounded hover:bg-gray-50 border border-gray-100 shadow hover:shadow-lg">
            <p className="text-black font-semibold text-md text-center w-full">TRANSACTION COUNT</p>
            <div className="grid grid-cols-6 w-full mt-4">
                <div className="">
                    <p className="text-green-500 text-center font-semibold">{sucessful || sucessful === 0 ? sucessful :  <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</p>
                    <p className="text-center text-gray-400 text-sm">Successful</p>
                </div>
                <div className="">
                    <p className="text-red-600 text-center font-semibold">{pending || pending === 0 ? pending :  <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</p>
                    <p className="text-center text-gray-400 text-sm">Pending</p>
                </div>
                <div className="">
                    <p className="text-red-600 text-center font-semibold">{failed || failed === 0 ? failed :  <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</p>
                    <p className="text-center text-gray-400 text-sm">Failed</p>
                </div>
                <div className="">
                    <p className="text-gray-400 text-center font-semibold">{abandoned || abandoned === 0 ? abandoned :  <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</p>
                    <p className="text-center text-gray-400 text-sm">Abandoned</p>
                </div>
                <div className="">
                    <p className="text-green-600 text-center font-semibold">{settled || settled === 0 ? settled :  <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</p>
                    <p className="text-center text-gray-400 text-sm">Settled</p>
                </div>
                <div className="">
                    <p className="text-red-600 text-center font-semibold">{refunded || refunded === 0 ? refunded :  <span className="w-4 h-4 rounded-full border-l-2 border-primary-theme animate-spin flex"></span>}</p>
                    <p className="text-center text-gray-400 text-sm">Refunded</p>
                </div>
            </div>
        </div>
    )
}

export default CardTwo
