import { Modal } from "@mui/material";
import React, { useState } from "react";
import Input from "../Input";
import Button from "../Button";
import { httpPost } from "../../services/https";
import { paymentGateway } from "../../services";

const AddBranch = ({hideModal, showModal, getBranches}) => {
  const [branch, setBranch] = useState('');
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    setLoading(true);
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    const data = {
      merchantId,
      name: branch,
    };
    const res = await httpPost(`${paymentGateway}/branchcategory/add`, data);
    //  console.log({res});
    if (res.id) {
      setLoading(false);
      hideModal(false);
      getBranches();
    } else {
      setLoading(false);
      console.log({ error: res });
    }
  }
  return(
    <Modal
        open={showModal} 
        onClose={() => hideModal(false)}
        aria-labelledby="Add Branch/Category"
        aria-describedby="Add Branch/Category"
        style={{ display: 'flex', justifyContent: 'center', height: '50%', margin: 10 }}
    >
      <div className="input-wrapper px-10 max-w-2xl bg-white">
        <h2 className="font-bold text-2xl">Add Branch/Category</h2>
        <Input
          label="branch"
          name="branch"
          placeholder="Branch/category name"
          value={branch}
          onChange={(e)=>setBranch(e.target.value)}
        />
        <Button text="Add Branch" actionHandler={submit} loading={loading} />
        </div>
    </Modal>
  )
}

export default AddBranch;