import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  FilterIcon,
  ExportIcon,
  ChevronDownIcon,
  BackIcon,
  CopyIcon,
  SettlementIcon,
  TransactionIcon,
  NairaIcon,
} from "../assets";
import { Card, Table } from "./index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import FetchApi from "../utils/FetchApi";
import { findArrayData } from "../utils/helpers";
import axios from "axios";
import { FormatDatetime } from "../utils/FormatDatetime";
import config from "../config.js";
import { Link, useNavigate } from "react-router-dom";
import {
  withdraw,
  getAllBankAccount,
  getAccountName,
} from "../../src/services/requests/banks";
import Select from "react-select";
import HistoryIcon from "@mui/icons-material/History";
import OtpInput from 'react-otp-input';

function SettlementInner() {
  const [tableRowData, setTableRowData] = useState(null);
  const [allFilteredData, setFilteredData] = useState(null);
  const [allSettlement, setAllSettlement] = useState(null);
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [settlementStats, setSettlementStats] = useState(null);
  const [settlementCounts, setSettlementCounts] = useState();
  const [currentStats, setCurrentStats] = useState(null);
  const [incomeStats, setIncomeStats] = useState(null);
  const [withdrawStats, setWithdrawalStats] = useState(null);
  const [status, setStatus] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [settlementDetails, setSettlementDetails] = useState(null);
  const [bankName, setBankName] = useState({});
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [amount, setAmount] = useState("");
  const [supportedBank, setSupportedBank] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unfilteredBanks, setUnfilteredBanks] = useState("");
  const [bankCode, setBankCode] = useState([]);
  const [allBankDetails, setAllBankDetails] = useState([]);
  const [netRevenue, setnetRevenue] = useState("");
  const [pin, setPin] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    setMerchantId(merchantId)
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        "Content-Type": "application/json",
        authorization: authorization,
        'CLIENT-ID': 'WAYAQUICK',
        'CLIENT-TYPE': 'CORPORATE',
      },
    };

    // Fetch settlement stats
    const ENDPOINT2 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/report/stats?merchantId=${merchantId}`;
    axios.get(ENDPOINT2, header).then((response) => {
      setSettlementStats(response.data.data.stats);
      setSettlementCounts(response.data.data.counts);
    });

    // Fetch settlement stats
    // const ENDPOINT0 = `${config.BASE_URL_AUTH}/payment-gateway/api/v1/transactions/settlements/report/stats?merchantId=${merchantId}&order=DESC`;
    // axios.get(ENDPOINT0, header).then((response) => {
    //   setSettlementCounts(response.data.data.counts);
    // });

    // Fetch current balance stats
    const ENDPOINT3 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
    axios.get(ENDPOINT3, header).then((response) => {
      setCurrentStats(response.data);
    });
    
    // Fetch income balance stats
    const ENDPOINT4 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
    axios.get(ENDPOINT4, header).then((response) => {
      setIncomeStats(response.data);
    });
   
    // Fetch withdrawal balance stats
    const ENDPOINT5 = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/audit/balance/${merchantId}`;
    axios.get(ENDPOINT5, header).then((response) => {
      setWithdrawalStats(response.data);
    });


    // Fetch list of settlements
    const ENDPOINT = `${config.BASE_URL_AUTH}/wayapay-settlement-service/api/v1/transactions/settlements/all/byMerchant?field=${"all"}&merchantId=${merchantId}&value=${"all"}`;
    axios.get(ENDPOINT, header).then((response) => {
      const settlementResponse = response?.data?.data?.content.map(
        (elem, index) => ({
          id: elem?.paymentRefNo,
          beneficiary: elem?.settlementAccountName,
          actualAmount: `NGN ${elem?.settlementGrossAmount}`,
          // totalFee: `NGN ${elem?.fee}`,
          settledAmount: `NGN ${elem?.settlementNetAmount}`,
          settledAccountName: elem?.settlementAccountName,
          settlementDate: FormatDatetime(elem?.dateSettled),
          status: elem?.settlementStatus,
          configDate: elem?.merchantConfiguredSettlementDate,
          // dateModified: elem.dateModified,
          // settlementBeneficiaryAccount: elem?.settlementBeneficiaryAccount,
        })
      );
      setTableRowData(settlementResponse);
      setFilteredData(settlementResponse);
      setAllSettlement(response?.data?.data?.content);
    });
  }, []);

  const tableColumnStructure = [
    { field: "id", headerName: "Reference ID", width: 175 },
    { field: "beneficiary", headerName: "Beneficiary", flex: 1, minWidth: 150 },
    {
      field: "actualAmount",
      headerName: "Actual Amount",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "settledAccountName",
      headerName: "Settled Account Name",
      flex: 1,
      minWidth: 250,
    },
    // { field: 'totalFee', headerName: 'Wayapay Fees', flex: 1, minWidth: 150 },
    {
      field: "settledAmount",
      headerName: "Settled Amount",
      flex: 1,
      minWidth: 150,
    },
    { field: "configDate", headerName: " Settlement Due Date", width: 170 },
    { field: "settlementDate", headerName: " Settled Date", width: 170 },
    { field: "status", headerName: "Status", width: 120 },
    // { field: 'dateModified', headerName: 'Transaction Date', width: 170 },
    // { field: 'settlementBeneficiaryAccount', headerName: 'Settlement Account', width: 160 },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const data = allSettlement.filter(
            (elem) => elem.settlementReferenceId === params.row.id
          );
          if (data.length === 1) {
            setShowPopup(true);
            setSettlementDetails(data[0]);
            //  console.log('settlementD', data[0]);
          }
        };
        return (
          <button
            className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white"
            onClick={onClick}
          >
            View More
          </button>
        );
      },
    },
  ];

  const FilterDataById = (id) => {
    setValue(id);
    if (startDate) {
      const data = tableRowData;
      const outcome = data.filter(
        (element) => element.settlementDate.split(" ")[0] == startDate
      );
      setFilteredData(outcome);
      hideFilterDropdown();
    } else {
      if (id) {
        const data = tableRowData;
        // const outcome = data.filter(element=> element.id === id);
        const outcome = data.filter((element) => element.id.indexOf(id) > -1);
        setFilteredData(outcome);
        hideFilterDropdown();
      } else if (status) {
        // alert(status)
        const data = tableRowData;
        const outcome = data.filter(
          (element) => element.status === status.toUpperCase()
        );
        setFilteredData(outcome);
        hideFilterDropdown();
      } else {
        setFilteredData(tableRowData);
        hideFilterDropdown();
      }
    }
  };

  const ClearFilter = () => {
    setValue(() => "");
    setStartDate(() => "");
    setStatus(() => "");
    setFilteredData(tableRowData);
    hideFilterDropdown();
  };

  const showFilterDropdown = () => setFilterDropdown(true);
  const hideFilterDropdown = () => setFilterDropdown(false);

  const hideOverlay = () => {
    setShowPopup(false);
  };

  const CopyID = (referenceID) => {
    navigator.clipboard.writeText(referenceID);
    toast("Settlement ID copied");
  };

   useEffect(() => {
      getAllBankAccount().then(response=>{
        toast(response.message);

        let data = response.data.content.reverse().map(element=> {
            // if(element.deleted === true){
                return {
                    id: element.merchantId,
                    bankName: element.bankName,
                    accountNumber: element.accountNumber,
                    accountName: element.accountName,
                    default: element.defaultSettlementAccount,
                    bankCode: element.bankCode,
                }
            // }
        });
        setSupportedBank(
          data.map((item) => {
            return { value: item, label: `${item.bankName} - ${item.accountNumber} - ${item.accountName} ` };
          })
        );
        setAllBankDetails(response.data.content)
        //setBankAccounts(data);
    }).catch(err=>{
        console.log(err.response);
        toast("Error occured...")
    });
   }, [])
   

  const addWalletHandler = async () => {
    // console.log({
    //   bankName, pin, amount
    // });

    const payload = {
      accountName: bankName.accountName,
      accountNo: bankName.accountNumber,
      bankName: bankName.bankName,
      amount: amount,
      bankCode: bankName.bankCode,
      merchantId: merchantId,
      transactionPin: pin,
    };
    const res = await withdraw(merchantId, amount, payload);
    if (!res.status) {
      toast.error(res.message);
    } else {
      setShowPopup1(false);
      toast("Success");
    }
  };

  return (
    <>
      <ToastContainer />
      {showPopup1 && (
        <div className="w-full h-screen absolute flex items-center justify-center z-40 bg-black bg-opacity-50 ">
          <div className="w-3/5 py-4 h-auto bg-white rounded ...">
            <header className="flex rounded shadow-sm items-center justify-center">
              <h6 className="text-lg text-bold">
                Withdraw Payment to your bank
              </h6>
            </header>
            <p className=" flex items-center justify-center text-red-400 text-xs">
              Kindly note that N100 naira fixed charge will be debited
            </p>
            <div className="px-12 pt-12 ">
              <div className="w-full flex px-4 items-center pt-7">
                <label className="text-sm pr-12">Balance</label>
                <input
                  disabled
                  value={currentStats?.data?.accountBalance}
                  type=""
                  className="px-3 my-1 h-10 shadow w-full"
                  placeholder="₦5,000.00"
                />
              </div>
              <div className="w-full px-4 flex items-center pt-6">
                <label className="text-sm ">Amount Withdraw</label>
                <input 
                  value={amount}
                  type=""
                  className="px-3 my-1 h-10 shadow w-full"
                  placeholder="₦2,000.00"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              {/* <div className="w-full py-4 flex-col px-4 flex items-start">
                <label className="text-sm">
                  Select preffered account to withdraw to{" "}
                  <span className="text-red-700">*</span>
                </label>
                <Select
                  value={{
                    label: supportedBank?.filter(
                      (elem) => elem.value === bankName
                    )[0]?.label,
                    value: supportedBank?.filter(
                      (elem) => elem.value === bankName
                    )[0]?.value,
                  }}
                  onChange={(e) => {
                    setBankName(e.value);
                  }}
                  className="w-full h-10 rounded-lg border border-gray-200 text-sm"
                  options={supportedBank}
                  placeholder={<>Select your bank provider</>}
                />
              </div> */}
              {/* <div className="w-full flex-col px-4 flex items-start pt-7">
                  <label className="text-sm">
                      Enter Transaction Pin
                      <OtpInput 
                        value={pin}
                        onChange={setPin}
                        //numInputs={4}
                        placeholder='xxxx'
                        separator={<span className="mx-7"></span>}
                        inputStyle="border border-gray-500 rounded text-xl w-full"
                        focusStyle="border-blue-300"
                        containerStyle="mt-2 mb-5"
                      />
                      
                  </label>
                  <div className="w-full flex justify-between text-red-400 pb-8">
                        <Link to="/set-pin">Don't have a PIN? Create now</Link>
                        <Link to="/set-pin">Forget Pin</Link>
                      </div>
              </div> */}
              
              <div className="w-ful px-4 flex justify-between py-3 items-start">
                <button
                  onClick={() => setShowPopup1(false)}
                  className="bg-gray-100 text-sm  py-2 px-5 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={addWalletHandler}
                  className="bg-primary-theme text-white text-sm py-2 px-5 rounded"
                >
                  WITHDRAW
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="w-full">
          <button
            className="mx-8 my-4 flex items-center text-sm gap-2"
            onClick={hideOverlay}
          >
            <BackIcon /> Back
          </button>
          <div className="grid grid-cols-1 h-auto px-8">
            <div className="bg-white w-full h-auto border px-6 border-gray-200 rounded-xl pt-5 pb-3">
              <div className="grid grid-cols-3 gap-3">
                <div className="w-full h-24 px-3 flex flex-col justify-center rounded-lg border border-gray-200">
                  <h4 className="text-primary-theme">Settled Amount</h4>
                  <h2 className="font-bold text-md h-6">
                    NGN {settlementDetails?.settlementNetAmount}
                  </h2>
                </div>
                <div className="w-full h-24 px-3 flex flex-col justify-center rounded-lg border border-gray-200">
                  <h4 className="text-primary-theme">Settled Date</h4>
                  <h2 className="font-bold text-md h-6">
                    {FormatDatetime(settlementDetails?.dateSettled)}
                  </h2>
                </div>
                <div className="w-full row h-24 px-3 flex flex-row justify-center rounded-lg border border-gray-200">
                  <div className="w-full">
                    <h4 className="text-primary-theme">Settled Account</h4>
                    <p className="text-gray-700 text-xs">Account Name: {settlementDetails?.settlementAccountName}</p>
                    
                    <p className="text-gray-700 text-xs">
                      Account Number:{settlementDetails?.settlementBeneficiaryAccount}
                    </p>
                    <p className="text-gray-700 text-xs">
                      {settlementDetails?.settlementBankName}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full h-auto mt-5 border border-gray-300 rounded-lg">
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-gray-300">
                      <th className="text-left text-sm p-3">Reference ID</th>
                      <th className="text-left text-sm p-3">Actual Amount</th>
                      <th className="text-left text-sm p-3">Wayapay Fee</th>
                      <th className="text-left text-sm p-3">Settled Amount</th>
                      <th className="text-left text-sm p-3">
                        Settlement Due Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-sm p-3">
                        <div className="flex items-center gap-2">
                          <span>
                            {settlementDetails?.paymentRefNo}
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              CopyID(settlementDetails?.paymentRefNo)
                            }
                          >
                            <CopyIcon />
                          </span>
                        </div>
                      </td>
                      <td className="text-sm p-3">
                        NGN {settlementDetails?.settlementGrossAmount}
                      </td>
                      <td className="text-sm p-3">
                        NGN {settlementDetails?.totalFee}
                      </td>
                      <td className="text-sm p-3">
                        NGN {settlementDetails?.settlementNetAmount}
                      </td>
                      <td className="text-sm p-3">
                        {settlementDetails?.merchantConfiguredSettlementDate}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* /transactions?cid=CUS_ipDHb1658839560360PE1Bv */}
              <Link
                to=""
                className="text-primary-theme mt-3 text-sm block mx-auto"
              >
                click here to view details of this transactions
              </Link>
            </div>
          </div>
        </div>
      )}
      {!showPopup && (
        <>
          <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
            <div className="flex items-center gap-6">
              <div className="flex items-center relative">
                <button
                  className="flex items-center"
                  onClick={showFilterDropdown}
                >
                  <FilterIcon />
                  <span className="text-sm">Filter</span>
                </button>
                {filterDropdown && (
                  <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                    <span
                      onClick={hideFilterDropdown}
                      className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm"
                    >
                      close
                    </span>
                    {/* <h5 className="font-semibold">Status</h5>
                                        <input value={value} placeholder="Search by reference ID" onChange={(e)=>setValue(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" /> */}

                    <h5 className="font-semibold mt-2">Date Range</h5>
                    <div className="flex items-center gap-4">
                      <input
                        placeholder="Search by reference ID"
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                        type="date"
                      />
                    </div>

                    <h5 className="font-semibold mt-2">Settlement Status</h5>
                    <div className="flex items-center gap-4">
                      <select
                        type=""
                        onChange={(e) => setStatus(e.target.value)}
                        className="w-full h-10 rounded border border-gray-200"
                      >
                        <option value="">Choose status</option>
                        <option value="abandoned">Abandoned</option>
                        <option value="pending">Pending</option>
                        <option value="failed">Failed</option>
                        <option value="successful">Successful</option>
                      </select>
                    </div>

                    <div className="flex items-center gap-4 mt-5">
                      <button
                        onClick={ClearFilter}
                        className="py-3 w-full text-xs rounded shadow"
                      >
                        Clear filter
                      </button>
                      <button
                        onClick={() => FilterDataById(value)}
                        className="py-3 w-full rounded bg-primary-theme text-white text-xs"
                      >
                        Apply filter
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="1/5 flex gap-1 ...">
                <input
                  placeholder="Search by reference ID"
                  onChange={(e) => FilterDataById(e.target.value)}
                  className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                />
                <button
                  onClick={() => FilterDataById(value)}
                  className="bg-primary-theme py-2 px-5 text-white text-sm"
                >
                  Search
                </button>
              </div>
            </div>
            <button
              onClick={() => setShowPopup1(true)}
              className="flex shadow gap-2 h-10 items-center text-sm px-5 "
            >
              <TransactionIcon />
              Withdraw
            </button>
            <p className="text-xs text-red-400">
              Kindly note that N100 naira fixed <br></br>charge will be debited
            </p>
            {allFilteredData?.length > 0 ? (
              <CSVLink data={allFilteredData}>
                <button className="flex shadow gap-2 h-10 items-center text-sm px-5">
                  <ExportIcon />
                  Export
                  <ChevronDownIcon />
                </button>
              </CSVLink>
            ) : (
              <button
                onClick={() => toast("You can't export empty data.")}
                className="flex shadow gap-2 h-10 items-center text-sm px-5"
              >
                <ExportIcon />
                Export
                <ChevronDownIcon />
              </button>
            )}
          </div>
          <div className="px-6 grid grid-cols-4 gap-5 pt-5">
            {/* <Grid spacing={2} container className="px-6 pt-5"> */}
            <Grid item xs={12} sm={12} md={4}>
              <Card
                name="Last Settlement"
                amount={settlementStats?.latestSettlement}
                date=""
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card
                name="Current Balance"
                amount={currentStats?.data?.accountBalance}
                income={incomeStats?.data?.totalIncome}
                total={withdrawStats?.data?.totalPayout}
                actionButton={
                  <button
                    onClick={() => navigate("/balance-history")}
                    className="flex justify-between font-medium text-xs shadow gap-2 items-center rounded-full p-1"
                  >
                    <HistoryIcon />
                    View History
                  </button>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card
                name="Next Settlement"
                amount={settlementStats?.nextSettlement}
                date=""
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card
                name="Transaction Count"
                amount={true}
                notCurrency={true}
                settled={String(findArrayData(settlementCounts, 'status', 'SETTLED')?.count)}
                pending={String(findArrayData(settlementCounts, 'status', 'PENDING')?.count)}
                withdraw={withdrawStats && String(withdrawStats['Withdrawal Count'])}
              />
            </Grid>
            {/* </Grid> */}
          </div>
          <Table
            tableColumnStructure={tableColumnStructure}
            tableRowData={allFilteredData}
            pageName="Settlement"
          />
        </>
      )}
    </>
  );
}

export default SettlementInner;
